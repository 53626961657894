import React from 'react';
import { useTheme } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import SortSelect from './SortSelect';
import authService from '../../app/authService';


const DashboardNavBar = (props) => {
  const {children } = props;
  //const currentCreateWizardPage = useSelector(selectCurrentCreateWizardPage);
  // const { collectionCard } = currentCreateWizardPage;
  // const { title, draft_description, url, provider, learning_format } = collectionCard;


  const theme = useTheme();
  // console.log('DashboardNavBar theme: ', theme);

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          elevation={0}
          color="transparent"
          sx={{
            paddingLeft: '40px',
            paddingRight: '40px',
            borderBottom: `1px solid ${theme.palette.primary['800']}`
          }}
        >
          <Toolbar>

            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                fontSize: '1.2rem',
                color: 'gray.mediumDark',
                fontFamily: 'cooper-black-std',
                width: '400px'
              }}
            >
              Hi, {authService.getFirstName()}!
            </Typography>

            <Box sx={{ flexGrow: 1 }} />

            {/* <Box>
              {now.toFormat('EEEE, MMMM d, yyyy')}
            </Box> */}

            <Box sx={{ flexGrow: 1 }} />

            <Box
              sx={{
                display: 'none'
              }}
            >
              <SortSelect />
            </Box>

          </Toolbar>
        </AppBar>

      </Box>
      {children}
    </React.Fragment>
  );
};

export default DashboardNavBar;
