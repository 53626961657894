/**
 * Holds configService data
 */

import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import _ from 'lodash-es';

import callApi from '../apiService';
import configService from '../configService';

const initialState = {
  configCache: {
    frontend: {},
    user: {
      api_version: 'v1',
      ui: {
        high_contrast: false
      },
    },
  },
  status: 'idle',
};

const fetchFrontendConfig = async (options) => {
  console.log('configSlice.fetchFrontendConfig() options: ', options);
  try {
    const frontendConfigResponse = await callApi('getFrontendConfig', null, { params: {} });
    console.log('configSlice.fetchFrontendConfig() frontendConfigResponse: ', frontendConfigResponse);
    return frontendConfigResponse.data;   // axios attribute
  } catch (error) {
    console.error('configSlice.fetchFrontendConfig() error: ', error.message);
  }
};

export const getFrontendConfig = createAsyncThunk(
  'configSlice/getFrontendConfig',
  async () => {
    const response = await fetchFrontendConfig();
    // The value we return becomes the `fulfilled` action payload
    return response.payload;
  }
);


const fetchUserConfig = async (options) => {
  console.log('configSlice.fetchUserConfig() options: ', options);
  try {
    const userConfigResponse = await callApi('getUserConfig', null, { params: {} });
    console.log('configSlice.fetchUserConfig() userConfigResponse: ', userConfigResponse);
    return userConfigResponse.data;   // axios attribute
  } catch (error) {
    console.error('configSlice.fetchUserConfig() error: ', error.message);
  }
};

export const getUserConfig = createAsyncThunk(
  'configSlice/getUserConfig',
  async () => {
    const response = await fetchUserConfig();
    // The value we return becomes the `fulfilled` action payload
    return response.payload;
  }
);


const updateUserConfig = async (data) => {
  console.log('configSlice.updateUserConfig() data: ', data);
  try {
    const updateResponse = await callApi('setUserConfig', null, data);
    console.log('configSlice.updateUserConfig() updateResponse: ', updateResponse);
    return data;   // original data
  } catch (error) {
    console.error('configSlice.updateUserConfig() error: ', error.message);
  }
};

export const setUserConfig = createAsyncThunk(
  'configSlice/setUserConfig',
  async (data) => {
    const response = await updateUserConfig(data);
    return response;
  }
);



export const configSlice = createSlice({
  name: 'configData',
  initialState,
  reducers: {
    setFrontendConfig: (state, action) => {
      state.configCache = _.merge(state.configCache, action.payload);
      console.log('configSlice.setFrontendConfig() state: ', current(state));
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getFrontendConfig.pending, (state) => {
      // console.log('configSlice getFrontendConfig.pending state: ', state);
      state.status = 'loading';
    })
    .addCase(getFrontendConfig.fulfilled, (state, action) => {
      state.status = 'idle';
      console.log('configSlice getFrontendConfig.fulfilled action: ', action);
      state.configCache.frontend = action?.payload || {};
      // frontend object could be empty and needs to be not empty to let Rehydrator know this function completed
      state.configCache.frontend.isInited = true;
      console.log('configSlice getFrontendConfig.fulfilled state: ', current(state));
      // add to configService for cases where we can't easily use Redux Toolkit
      configService.setFrontendConfig(action.payload);
    })
    .addCase(getUserConfig.pending, (state) => {
      // console.log('configSlice getUserConfig.pending state: ', state);
      state.status = 'loading';
    })
    .addCase(getUserConfig.fulfilled, (state, action) => {
      state.status = 'idle';
      console.log('configSlice getUserConfig.fulfilled action: ', action);
      state.configCache.user = action?.payload || {};
      // user object could be empty and needs to be not empty to let Rehydrator know this function completed
      state.configCache.user.isInited = true;
      console.log('configSlice getUserConfig.fulfilled state: ', current(state));
      // add to configService for cases where we can't easily use Redux Toolkit
      configService.setUserConfig(action.payload);
    })
    .addCase(setUserConfig.pending, (state) => {
      // console.log('configSlice setUserConfig.pending state: ', state);
      state.status = 'updating';
    })
    .addCase(setUserConfig.fulfilled, (state, action) => {
      state.status = 'idle';
      console.log('configSlice setUserConfig.fulfilled action: ', action);
      state.configCache.user = _.merge(state.configCache.user, action.payload);
      console.log('configSlice setUserConfig.fulfilled state: ', current(state));
    });
  },
});

export const { 
  setFrontendConfig,
} = configSlice.actions;

// for useSelector; state is complete store, not slice
export const selectConfigCache = (state) => state.configData.configCache;
export const selectFrontendConfigCache = (state) => state.configData.configCache.frontend;
export const selectUserConfigCache = (state) => state.configData.configCache.user;

export default configSlice.reducer;
