/**
 * API Service: Client for Bendable Hub REST API
 * 
 * Notes:
 * Hub data documents typically have a "controlling_instance" field, which is a single
 * Bendable instance name indicating the source of a document. Documents also typically
 * have a "bendable_instances" field, which is an array of all Bendable instance names 
 * "subscribed" to (or simply using) the document.
 * 
 * This service will automatically append a "bendable_instances" filter to all GET requests
 * unless one is intentionally set.
 * 
 */
// const axios = require('axios').default;
// const _ = require('lodash');
// const stringify = require('fast-json-stable-stringify');
import axios from 'axios';
import _ from 'lodash-es';

import authService from './authService';

let apiBaseUrl = process.env.REACT_APP_BAS_API_ENDPOINT_PREFIX;   // was:  'http://localhost:3000/v1';
console.log('apiService apiBaseUrl: ', apiBaseUrl);
if (!apiBaseUrl) apiBaseUrl = 'misconfiguredApiService';

const DEFAULT_API_VERSION = 'v1';

export const getBaseUrl = () => {
  return apiBaseUrl;
};

export const urlEncode = (url) => {
  return encodeURIComponent(url);
};

let verboseLogging = true;

const callDefinitions = {
  getFrontendConfig: {
    pathTemplate: '/configs/frontend',
    method: 'get'
  },
  getUserConfig: {
    pathTemplate: '/configs/user',
    method: 'get'
  },
  setUserConfig: {
    pathTemplate: '/configs/user',
    method: 'put'
  },
  getAlgoliaPrefix: {
    pathTemplate: '/search/algoliaPrefix',
    method: 'get'
  },
  // getConfigurationForUser: {
  //   pathTemplate: '/users/configuration',
  //   method: 'get'
  // },
  upsertUser: {
    pathTemplate: '/users',
    method: 'post'
  },
  getUser: {
    pathTemplate: '/users/:userId',
    method: 'get'
  },
  getUsers: {
    pathTemplate: '/users',
    method: 'get'
  },
  deleteUser: {
    pathTemplate: '/users/:userId',
    method: 'delete'
  },
  getLoginChallengeType: {
    pathTemplate: '/users/loginChallengeType',
    method: 'get'
  },
  requestSmsAuth: {
    pathTemplate: '/users/requestSmsAuth',
    method: 'post'
  },
  requestEmailAuth: {
    pathTemplate: '/users/requestEmailAuth',
    method: 'post'
  },
  loginViaSmsAuth: {
    pathTemplate: '/users/loginViaSmsAuth',
    method: 'post'
  },
  loginViaJwt: {
    pathTemplate: '/users/loginViaJwt',
    method: 'post'
  },
  logout: {
    pathTemplate: '/users/logout',
    method: 'post'
  },
  userCanApprove: {
    pathTemplate: '/users/userCanApprove',
    method: 'get'
  },
  getAllRoles: {
    pathTemplate: '/users/roles',
    method: 'get'
  },
  getSystemEvents: {
    pathTemplate: '/admin/systemEvents',
    method: 'get'
  },
  getSystemStatuses: {
    pathTemplate: '/admin/systemStatuses',
    method: 'get'
  },
  getPostgresServerConfigs: {
    pathTemplate: '/admin/postgresServerConfigs',
    method: 'get'
  },
  adminIngestAction: {
    pathTemplate: '/admin/do/ingestActions',
    method: 'post'
  },
  adminTransferAction: {
    pathTemplate: '/admin/do/transferActions',
    method: 'post'
  },
  adminDeleteAction: {
    pathTemplate: '/admin/do/deleteActions',
    method: 'post'
  },
  identifyDuplicateLearningObjects: {
    pathTemplate: '/admin/duplicateLearningObjects',
    method: 'get'
  },
  buildAlgoliaIndex: {
    pathTemplate: '/admin/buildAlgoliaIndex',
    method: 'post'
  },
  buildCategories: {
    pathTemplate: '/admin/buildCategories',
    method: 'post'
  },
  getInstanceSettings: {
    pathTemplate: '/admin/instanceSettings',
    method: 'get'
  },
  upsertInstanceSettings: {
    pathTemplate: '/admin/instanceSettings',
    method: 'post'
  },
  refreshHubConfig: {
    pathTemplate: '/admin/refreshHubConfig',
    method: 'post'
  },
  ingestHistoricalGaV3Data: {
    pathTemplate: '/admin/v3/ingestHistoricalGaData',
    method: 'post'
  },
  ingestHistoricalGaV4Data: {
    pathTemplate: '/admin/v4/ingestHistoricalGaData',
    method: 'post'
  },
  getDbObjectChangesSummary: {
    pathTemplate: '/mongodb/dbObjectChangesSummary',
    method: 'get'
  },
  getPostgressTableDescription: {
    pathTemplate: '/mongodb/postgresTableDescription',
    method: 'get'
  },
  getPostgresTableRows: {
    pathTemplate: '/mongodb/postgresTableRows',
    method: 'get'
  },
  postgresTableRowsForInstance: {
    pathTemplate: '/mongodb/postgresTableRowsForInstance',
    method: 'get'
  },
  createDbObjectChangeSet: {
    pathTemplate: '/mongodb/dbObjectChangeSets',
    method: 'post'
  },
  getDbObjectChangeSets: {
    pathTemplate: '/mongodb/dbObjectChangeSets',
    method: 'get'
  },
  createDbObjectChanges: {                        // does 'upsert'
    pathTemplate: '/mongodb/dbObjectChanges',
    method: 'post'
  },
  upsertDbObjectChanges: {
    pathTemplate: '/mongodb/dbObjectChanges',
    method: 'post'
  },
  getDbObjectChanges: {
    pathTemplate: '/mongodb/dbObjectChanges',
    method: 'get'
  },
  updateDbObjectChanges: {
    pathTemplate: '/mongodb/dbObjectChanges',
    method: 'put'
  },
  moderateDbObjectChangeSet: {
    pathTemplate: '/mongodb/dbObjectChanges/moderate',
    method: 'put'    
  },
  createDbObjectShells: {
    pathTemplate: '/mongodb/dbObjectShells',
    method: 'post'
  },
  getDbObjectShell: {
    pathTemplate: '/mongodb/dbObjectShells/dbObjectShellId',
    method: 'get'
  },
  getDbObjectShells: {
    pathTemplate: '/mongodb/dbObjectShells',
    method: 'get'
  },
  updateDbObjectShell: {
    pathTemplate: '/mongodb/dbObjectShells/:id',
    method: 'put'
  },
  deleteDbObjectShell: {
    pathTemplate: '/mongodb/dbObjectShells/:id',
    method: 'delete'
  },
  publishApprovedDbObjectShells: {
    pathTemplate: '/mongodb/dbObjectShells/publish',
    method: 'put'
  },
  createDbObjectBin: {
    pathTemplate: '/mongodb/dbObjectBins',
    method: 'post'
  },
  getDbObjectBins: {
    pathTemplate: '/mongodb/dbObjectBins',
    method: 'get'
  },
  updateDbObjectBin: {
    pathTemplate: '/mongodb/dbObjectBins/:id',
    method: 'put'
  },
  deleteDbObjectBin: {
    pathTemplate: '/mongodb/dbObjectBins/:id',
    method: 'delete'
  },
  resetDbObjectBinStatus: {
    pathTemplate: '/mongodb/dbObjectBins/:id/status',
    method: 'put'
  },
  getSpotlightSlides: {
    pathTemplate: '/mongodb/spotlightSlides',
    method: 'get'
  },
  getSpotlightSlide: {
    pathTemplate: '/mongodb/spotlightSlides/:id',
    method: 'get'
  },
  getSpotlightCollections: {
    pathTemplate: '/mongodb/spotlightCollections',
    method: 'get'
  },
  getSpotlightCollection: {
    pathTemplate: '/mongodb/spotlightCollections/:id',
    method: 'get'
  },
  // createTag: {
  //   pathTemplate: '/tags',
  //   method: 'post'
  // },
  getTags: {
    pathTemplate: '/tags',
    method: 'get'
  },
  getTopics: {
    pathTemplate: '/topics',
    method: 'get'
  },
  getCategories: {
    pathTemplate: '/categories',
    method: 'get'
  },
  getTenants: {
    pathTemplate: '/tenants',
    method: 'get'
  },
  // createProvider: {
  //   pathTemplate: '/postgres/providers',
  //   method: 'post'
  // },
  getSelectOptions: {
    pathTemplate: '/mongodb/selectOptions',
    method: 'get'
  },
  getBendableBoards: {
    pathTemplate: '/bendableBoards/boards',
    method: 'get'
  },
  getBendableBoard: {
    pathTemplate: '/bendableBoards/boards/:boardId',
    method: 'get'
  },
  getBendableBoardPrompt: {
    pathTemplate: '/bendableBoards/prompts/:promptId',
    method: 'get'
  },
  getTemplates: {
    pathTemplate: '/templates',
    method: 'get'
  },
  getTemplate: {
    pathTemplate: '/templates/:templateId',
    method: 'get'
  },
  createTemplate: {
    pathTemplate: '/templates',
    method: 'post'
  },
  upsertTemplate: {
    pathTemplate: '/templates/:templateId',
    method: 'put'
  },
  deleteTemplate: {
    pathTemplate: '/templates/:templateId',
    method: 'delete'
  },
  renderTemplate: {
    pathTemplate: '/templates/render',
    method: 'get'
  },
  validateTemplate: {
    pathTemplate: '/templates/validate',
    method: 'post'
  },
  validateScss: {
    pathTemplate: '/templates/scss/validate',
    method: 'post'
  },
  uploadImage: {
    pathTemplate: '/uploads/image',
    method: 'post'
  },
  uploadResources: {
    pathTemplate: '/uploads/resources',
    method: 'post'
  },
  getBasicStats: {
    pathTemplate: '/analytics/basicStats',
    method: 'get'
  },
  getUserStats: {
    pathTemplate: '/analytics/userStats',
    method: 'get'
  },
  getMonthlyUserStats: {
    pathTemplate: '/analytics/monthlyUserStats',
    method: 'get'
  },
  currentSearchStats: {
    pathTemplate: '/analytics/currentSearchStats',
    method: 'get'
  },
  currentCategoryStats: {
    pathTemplate: '/analytics/currentCategoryStats',
    method: 'get'
  },
  currentCareerCollectionStats: {
    pathTemplate: '/analytics/currentCareerCollectionStats',
    method: 'get'
  },
  currentCommunityCollectionStats: {
    pathTemplate: '/analytics/currentCommunityCollectionStats',
    method: 'get'
  },
  getDailyResourceStartsStats: {
    pathTemplate: '/analytics/dailyResourceStartsStats',
    method: 'get'
  },
  getMonthlyResourceStartsStats: {
    pathTemplate: '/analytics/monthlyResourceStartsStats',
    method: 'get'
  },
  getPopularResourceStarts: {
    pathTemplate: '/analytics/popularResourceStarts',
    method: 'get'
  },
  getPopularProviderStarts: {
    pathTemplate: '/analytics/popularProviderStarts',
    method: 'get'
  },
  downloadUserInsights: {
    pathTemplate: '/analytics/downloadUserInsights',
    method: 'get',
    responseType: 'blob',
  },
  deployProvider: {
    pathTemplate: '/providers/deploy',
    method: 'post'
  },
  recallProvider: {
    pathTemplate: '/providers/recall',
    method: 'post'
  },
  getProviders: {
    pathTemplate: '/providers',
    method: 'get'
  },

};



/**
* Generalized API call
*
* @param  {[type]}   apiKey             [description]
* @param  {[type]}   substitutionParams Params for substituting path templates such as :id ; note that apiVersion is a special token
* @param  {[type]}   axiosOptions           [description]
* @param  {Function} callback           [description]
* @return {[type]}                      [description]
*
* Returned object is augmented with the original _apiKey, _substitutionParams, 
* and _jsonData
*/
const callApi = async (apiKey, substitutionParams, axiosOptions) => {
  substitutionParams = substitutionParams || {};
  console.log('apiService.callApi() substitutionParms: ', substitutionParams);
  if (!substitutionParams.apiVersion) substitutionParams.apiVersion = DEFAULT_API_VERSION;

  axiosOptions = axiosOptions || {};

  // get the call definition
  let callDefinition = callDefinitions[apiKey];
  if (!callDefinition) {
    console.log('ERROR: ApiService.callApi() no api call for apiKey: ', apiKey);
    throw new Error('No API call found for ' + apiKey);
  }
  let pathTemplate = apiBaseUrl + substitutionParams.apiVersion + callDefinition.pathTemplate;
  let regex = /(?:^|\W):(\w+)(?!\w)/g;
  let regexTokens = [];
  let tempArray;
  while ((tempArray = regex.exec(pathTemplate)) !== null) {
    regexTokens.push(tempArray[1]);
  }

  // sanitize substitutionParams
  _.forOwn(substitutionParams, function(value, key, obj) {
    // substitutionParams values must be strings
    if (_.isNumber(value)) {
      obj[key] = '' + value;
    }
    // values with strings with must be URLencoded or we won't match routes when /, etc is present
    if (_.isString(value)) {
      obj[key] = encodeURIComponent(value);
    }
  });

  console.log('apiService.callApi() substitutionParams: ', substitutionParams);

  let path = pathTemplate;
  for (const regexToken of regexTokens) {
    console.log('apiService.callApi() regexToken: ', regexToken);
    if (!substitutionParams[regexToken]) {
      console.log('apiService.callApi() error; missing required path regexToken for apiKey, regexToken: ', apiKey, regexToken);
      throw new Error('Missing required path regexToken for ' + apiKey + ' (' + regexToken + ')');
    }
    path = path.replace((':' + regexToken), substitutionParams[regexToken]);
  }
  console.log(`callApi() ${callDefinition?.method} path: ${path}`);

  // ensure that GET requests have a "bendableInstances" filter, which ensures documents returned are for all appropriate instances
  if (callDefinition.method === 'get') {
    if (!axiosOptions?.params?.bendableInstances) {       // axios calls the 'queryString' params just 'params'
      axiosOptions.params = axiosOptions.params || {};
      axiosOptions.params.bendableInstances = authService.getActiveAuthorizedBendableInstances();
    }
  }  

  // move axiosOptions to { data: axiosOptions } if POST or PUT and no 'data' field present
  if ((callDefinition.method === 'post') || (callDefinition.method === 'put')) {
    if (!axiosOptions.data) {
      axiosOptions = {
        data: axiosOptions
      };
    }
  }

  axiosOptions.url = path;



  axiosOptions.method = callDefinition.method;
  axiosOptions.withCredentials = true;              // allows sending and receiving cookies
  axiosOptions.responseType =  callDefinition.responseType || 'json';
  if (verboseLogging) console.log('apiService.callApi() axiosOptions: ', axiosOptions);

  try {
    const response = await axios(axiosOptions);
    if (verboseLogging) console.log('apiService.callApi() response: ', response);
    return response;
  } catch (err) {
    if (verboseLogging) console.log('apiService.callApi() ERROR; err: ', err);
    throw err;
  }

};

export default callApi;
