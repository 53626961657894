import { createTheme } from "@mui/material";

const themeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: '#000000', // '#e9ad21',
      contrastText: '#ffffff',
      800: '#aaaaaa', // '#e78c18'
    },
    secondary: {
      main: '#000000', // '#3182BC',
      contrastText: '#ffffff',
    },
    tertiary: {
      main: '#000000', // light red
      contrastText: '#ffffff',
    },
    gray: {
      white: '#ffffff',
      threshold: '#E8E8E8',
      dim: '#D3D3D3',
      light: '#A9A9A9',
      medium: '#808080',
      mediumDark: '#585858',
      dark: '#303030',
      black: '#000000',
    },
    error: {
      main: '#CB0300',
    },
    levels: {
      success: '#004E00',
      warn: '#D77D00',
      failure: '#CB0300'
    }
  },
};

const bendableHighContrastV2Theme = createTheme(themeOptions);

export default bendableHighContrastV2Theme;
