import React, { useState } from "react";
import { ThemeProvider } from '@material-ui/core/styles';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import TextField from "@material-ui/core/TextField";
import Button from '@material-ui/core/Button';
import grey from "@material-ui/core/colors/grey";

import { center } from './Login.module.css';
import {ReactComponent as BendableLogo} from '../../assets/images/Bendable_Logo.svg';

import simpleTheme from '../../themes/bendableSimple';

console.log('Login process.env.REACT_APP_FAKE_ONE_TIME_CODE: ', process.env.REACT_APP_FAKE_ONE_TIME_CODE);
console.log('Login process.env.REACT_APP_DEV_EMAIL: ', process.env.REACT_APP_DEV_EMAIL);
const devEmail = process.env.REACT_APP_DEV_EMAIL || '';

const grey300 = grey["300"];

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    backgroundColor: grey300
  },
  buttonText: {
    color: "black"
  },
  logo: {
    width: 100
  }
});

const Login = (props) => {
  const classes = useStyles();
  // const [redirectToReferrer, setRedirectToReferrer] = useState(false);

  console.log('Login devEmail: ', devEmail);
  const [email, setEmail] = useState(devEmail);

  const handleClick = () => {
    props.onNext(email);
  };

  return (
    <ThemeProvider theme={simpleTheme}>
      <div className={center}>
        <Card className={classes.root}>
          <CardContent>
            <Box display="flex" justifyContent="center" m={2} p={2}>
              <BendableLogo className={classes.logo} />
            </Box>

            <Box display="flex" justifyContent="center" m={2} p={2}>
              <TextField
                autoFocus={true}
                label="Email"
                value={email}
                color="primary"
                onChange={(e) => setEmail(e.target.value)}
                // InputLabelProps={{
                //   style : {
                //     color : 'green'
                //   }
                // }}
                // InputProps={{
                //   style: {
                //     '& .MuiInput-underline::after': {
                //       'border-bottom': '1px solid rgba(0, 0, 0, 0.42)'
                //     }
                //   }
                // }}
              />
            </Box>

            <Box display="flex" justifyContent="center" m={2} p={2}>
              <Button variant="contained" color="secondary" className={classes.buttonText} 
                onClick={handleClick}>Next</Button>
            </Box>
          </CardContent>
        </Card>
      </div>
    </ThemeProvider>
  )
};

export default Login;