import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../../components/counter/counterSlice';
import managePageReducer from '../managePageSlice';
import createPageReducer from '../createPageSlice';
import resourcesReducer from '../resourcesSlice';
import providersReducer from './providersSlice';
import tagsReducer from './tagsSlice';
import topicsReducer from './topicsSlice';
import categoriesReducer from './categoriesSlice';
import tenantsReducer from './tenantsSlice';
import bulkResourcesReducer from '../bulkResourcesSlice';
import dbObjectChangesReducer from '../dbObjectChangesSlice';
import selectOptionsReducer from '../selectOptionsSlice';
import instanceDataReducer from '../instanceDataSlice';
import dashboardReducer from '../dashboardSlice';
import adminReducer from './adminSlice';
import longTermCacheReducer from '../longTermCacheSlice';
import spotlightSlidesReducer from './spotlightSlidesSlice';
import spotlightCollectionsReducer from './spotlightCollectionsSlice';
import collectionsReducer from './collectionsSlice';
import authDataReducer from './authSlice';
import configDataReducer from './configSlice';
import usersReducer from './usersSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    managePage: managePageReducer,
    createPage: createPageReducer,
    dbObjectChanges: dbObjectChangesReducer,
    resources: resourcesReducer,
    providers: providersReducer,
    tags: tagsReducer,
    topics: topicsReducer,
    categories: categoriesReducer,
    tenants: tenantsReducer,
    bulkResources: bulkResourcesReducer,
    collections: collectionsReducer,
    selectOptions: selectOptionsReducer,
    instanceData: instanceDataReducer,
    dashboardData: dashboardReducer,
    admin: adminReducer,
    longTermCache: longTermCacheReducer,
    spotlightSlides: spotlightSlidesReducer,
    spotlightCollections: spotlightCollectionsReducer,
    authData: authDataReducer,
    configData: configDataReducer,
    users: usersReducer,
  },
});
