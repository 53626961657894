import { createSlice, current, createAsyncThunk } from '@reduxjs/toolkit';
import eachDeep from 'deepdash-es/eachDeep';

import callApi from './apiService';
// import { getHash } from './authService';

const initialState = {
  rowChangeCount: 0,                            // TODO: Depricate?
  cellChangeCount: 0,                           // TODO: Depricate?
  shouldShowChangesInGrid: true,
  shouldShowOnlyChangedRowsInGrid: true,
  allChangeSets: [],
  activeChangeSet: {},                                // active wrapper for changes
  changes: {}
};


const doCreateChangeSet = async (body) => {
  console.log('dbObjectChangesSlice.doCreateChangeSet() body: ', body);
  try {
    const apiResponse = await callApi('createDbObjectChangeSet', null, body);
    console.log('dbObjectChangesSlice.doCreateChangeSet() apiResponse: ', apiResponse);
    return apiResponse;
  } catch (err) {
    throw err;
  }
};

export const createChangeSet = createAsyncThunk(
  'dbObjectChanges/createChangeSet',
  async (body) => {
    try {
      const apiResponse = await doCreateChangeSet(body);
      // The value we return becomes the `fulfilled` action payload
      console.log('dbObjectChangesSlice.doCreateChangeSet() apiResponse.data.payload: ', apiResponse.data.payload);
      return apiResponse.data && apiResponse.data.payload;
    } catch (err) {
      throw err;
    }
  }
);



const doFetchChangeSets = async (params) => {
  console.log('dbObjectChangesSlice.doFetchChangeSets() params: ', params);
  try {
    const apiResponse = await callApi('getDbObjectChangeSets', null, { params: params });
    console.log('dbObjectChangesSlice.doFetchChangeSets() apiResponse: ', apiResponse);
    return apiResponse;
  } catch (err) {
    throw err;
  }
};

export const fetchChangeSets = createAsyncThunk(
  'dbObjectChanges/fetchChangeSets',
  async (options) => {
    console.log('dbObjectChangesSlice.fetchChangeSets() options.fetchOwn: ', options.fetchOwn);
    try {
      const apiResponse = await doFetchChangeSets(options);
      // The value we return becomes the `fulfilled` action payload
      console.log('dbObjectChangesSlice.fetchChangeSets() apiResponse.data.payload: ', apiResponse.data.payload);
      return apiResponse.data && apiResponse.data.payload;
    } catch (err) {
      throw err;
    }
  }
);



// const doFetchDbObjectChanges = async (params) => {
//   console.log('dbObjectChangesSlice.doFetchDbObjectChanges() params: ', params);
//   try {
//     const apiResponse = await callApi('getDbObjectChanges', null, { params: params });
//     console.log('dbObjectChangesSlice.doFetchDbObjectChanges() apiResponse: ', apiResponse);
//     return apiResponse;
//   } catch (err) {
//     throw err;
//   }
// };

// export const fetchDbObjectChanges = createAsyncThunk(
//   'dbObjectChanges/fetchDbObjectChanges',
//   async (options) => {
//     try {
//       const apiResponse = await doFetchDbObjectChanges(options);
//       // The value we return becomes the `fulfilled` action payload
//       console.log('dbObjectChangesSlice.fetchDbObjectChanges() apiResponse.data.payload: ', apiResponse.data.payload);
//       return apiResponse.data && apiResponse.data.payload;
//     } catch (err) {
//       throw err;
//     }
//   }
// );





export const dbObjectChangesSlice = createSlice({
  name: 'dbObjectChanges',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setActiveChangeSet: (state, action) => {
      console.log('dbObjectChangesSlice setActiveChangeSet reducer; action?.payload: ', action?.payload);
      const changeSetId = action?.payload?.changeSetId || null;       // in normal use, this ID is the same as a "bin" ID
      state.activeChangeSet = {
        mid: changeSetId
      }
      console.log('dbObjectChangesSlice setActiveChangeSet reducer; state: ', current(state));
    },

    // TODO: add an 'updated_at' timestamp to help with invalidating this cache
    createChangeRequest: (state, action) => {
      console.log('dbObjectChangesSlice createChangeRequest reducer; state: ', current(state));
      console.log('dbObjectChangesSlice createChangeRequest reducer; action: ', action);
      const data = (action?.payload?.data) || {};    // full grid row data; root has grid fields + sourceObj with extra detail
      const sourceObj = data?.sourceObj || {};

      // const columnMetadata = (action && action.payload && action.payload.columnMetadata) || {};

      // use binId as activeChangeSetId if possbile
      let activeChangeSetId = sourceObj.bin_id;
      // or fall back to activeChangeSet's ID if needed
      activeChangeSetId = activeChangeSetId || state?.activeChangeSet?._id;
      console.log('dbObjectChangesSlice createChangeRequest reducer; activeChangeSetId: ', activeChangeSetId);

      state.changes[activeChangeSetId] = state.changes[activeChangeSetId] || {
        change_set_id: activeChangeSetId,          // provide easy reference to activeChangeSetId/key for backend
        // tables: {}
        rows: {}
      };

      const rowId = sourceObj._id;
      console.log('dbObjectChangesSlice createChangeRequest reducer; state.changes: ', current(state.changes));
      state.changes[activeChangeSetId].rows[rowId] = state.changes[activeChangeSetId].rows[rowId] || {
        fields: {}
      };

      // preserve any existing mongodb _id (present when replacing an existing change)
      const existingDbChangeDoc = state.changes[activeChangeSetId].rows[rowId].fields[action.payload.field];
      const existingMongodbId = existingDbChangeDoc && existingDbChangeDoc._id;
      if (existingMongodbId) action.payload._id = existingMongodbId
      state.changes[activeChangeSetId].rows[rowId].fields[action.payload.field] = action.payload;

      let tempRowCount = 0;
      let tempCellCount = 0;
      eachDeep(state.changes, (val, key, parent, context) => {
        if (key === 'rows') {
          const rowKeys = Object.keys(val);
          tempRowCount += (rowKeys ? rowKeys.length : 0);
        }
        if (key === 'fields') {
          const fieldKeys = Object.keys(val);
          tempCellCount += (fieldKeys ? fieldKeys.length : 0);
        }
      });
      state.rowChangeCount = tempRowCount;
      state.cellChangeCount = tempCellCount;
      console.log('dbObjectChangesSlice createChangeRequest reducer complete; state: ', current(state));
    },

    setChangeRequests: (state, action) => {
      // set all change requests for a given changeSetId/binId
      console.log('dbObjectChangesSlice setChangeRequests reducer; state: ', current(state));
      console.log('dbObjectChangesSlice setChangeRequests reducer; action: ', action);
      const data = action?.payload;
      const targetChangeSetId = data?.change_set_id;
      state.changes[targetChangeSetId] = data;
      console.log('dbObjectChangesSlice setChangeRequests reducer complete; state: ', current(state));
    },

    updateDbObjectChangeStatusInStore: (state, action) => {      // NOTE: does not use a thunk -- only updates Redux store
      console.log('dbObjectChangesSlice.updateDbObjectChangeStatusInStore() action: ', action);
      const { changeSetId, rowId, field, status } = action.payload;
      const targetChanges = state.changes[changeSetId] && state.changes[changeSetId].rows;
      console.log('dbObjectChangesSlice.updateDbObjectChangeStatusInStore() current targetChanges: ', current(targetChanges));
      const rowObj = targetChanges[rowId];
      if (rowObj && rowObj.fields && rowObj.fields[field]) {
        // do all field-level updates
        rowObj.fields[field].status = status;
      }
    },

    deleteChangeRequest: (state, action) => {
      console.log('dbObjectChangesSlice.deleteChangeRequest() action: ', action);
      const { changeSetId, rowId, field } = action.payload;
      const targetChanges = state.changes[changeSetId] && state.changes[changeSetId].rows;
      console.log('dbObjectChangesSlice.deleteChangeRequest() current targetChanges: ', current(targetChanges));
      const rowObj = targetChanges[rowId];
      console.log('dbObjectChangesSlice.deleteChangeRequest() current rowObj: ', current(rowObj));
      if (rowObj && rowObj.fields) delete rowObj.fields[field];
      console.log('dbObjectChangesSlice.deleteChangeRequest() current state.changes[changeSetId]: ', current(state.changes[changeSetId]));
    },

    deleteActiveChangeSet: (state) => {
      state.rowChangeCount = 0;
      state.cellChangeCount = 0;
      state.activeChangeSet = {};
    },

    showChangesInGrid: (state, action) => {
      console.log('dbObjectChangesSlice.showChangesInGrid() action: ', action);
      state.shouldShowChangesInGrid = (action?.payload === true);
    },

    showOnlyChangedRowsInGrid: (state, action) => {
      console.log('dbObjectChangesSlice.showOnlyChangedRowsInGrid() action.payload: ', action && action.payload);
      state.shouldShowOnlyChangedRowsInGrid = action && action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createChangeSet.pending, (state) => {
        //console.log('dbObjectChangeSlice createChangeSet.pending state: ', state);
        state.status = 'loading';
      })
      .addCase(createChangeSet.fulfilled, (state, action) => {
        state.status = 'idle';
        console.log('dbObjectChangeSlice createChangeSet.fulfilled action: ', action);
        state.activeChangeSet = action.payload && action.payload.document;
      })
      .addCase(fetchChangeSets.pending, (state) => {
        //console.log('dbObjectChangeSlice fetchChangeSets.pending state: ', state);
        state.status = 'loading';
      })
      .addCase(fetchChangeSets.fulfilled, (state, action) => {
        state.status = 'idle';
        console.log('dbObjectChangeSlice fetchChangeSets.fulfilled action: ', action);
        state.allChangeSets = action.payload && action.payload.documents;
      });
      // .addCase(fetchDbObjectChanges.pending, (state) => {
      //   //console.log('dbObjectChangeSlice fetchDbObjectChanges.pending state: ', state);
      //   state.status = 'loading';
      // })
      // .addCase(fetchDbObjectChanges.fulfilled, (state, action) => {
      //   state.status = 'idle';
      //   console.log('dbObjectChangeSlice fetchDbObjectChanges.fulfilled action: ', action);
      //   const changeSetId = action.meta.arg.changeSetId;
      //   const dbObjectChanges = action.payload && action.payload.documents;

      //   state.changes[changeSetId] = {
      //     change_set_id: changeSetId,
      //     rows: {}
      //   };

      //   for (const dbObjectChange of dbObjectChanges) {
      //     // console.log('dbObjectChangeSlice fetchDbObjectChanges.fulfilled dbObjectChange: ', dbObjectChange);
      //     const rowId = dbObjectChange.row_id;
      //     state.changes[changeSetId].rows[rowId] = state.changes[changeSetId].rows[rowId] || {
      //       fields: {}
      //     };
      //     state.changes[changeSetId].rows[rowId].fields[dbObjectChange.field] = dbObjectChange;
      //   }
      //   // console.log('dbObjectChangeSlice fetchDbObjectChanges.fulfilled state.changes[changeSetId]: ', state.changes[changeSetId]);
      // })

  },
});

export const {
  setActiveChangeSet,
  createChangeRequest,
  setChangeRequests,
  updateDbObjectChangeStatusInStore,
  deleteChangeRequest,
  deleteActiveChangeSet,
  showChangesInGrid,
  showOnlyChangedRowsInGrid
} = dbObjectChangesSlice.actions;

// for useSelector; state is complete store, not slice
// export const selectAllDbObjectChanges = (state) => state.dbObjectChanges.changes;
export const selectRowChangeCount = (state) => state.dbObjectChanges.rowChangeCount;
export const selectCellChangeCount = (state) => state.dbObjectChanges.cellChangeCount;
export const selectShowChangesInGrid = (state) => state.dbObjectChanges.shouldShowChangesInGrid;
export const selectShowOnlyChangedRowsInGrid = (state) => state.dbObjectChanges.shouldShowOnlyChangedRowsInGrid;
export const selectActiveChangeSet = (state) => state.dbObjectChanges.activeChangeSet;
export const selectAllChangeSets = (state) => state.dbObjectChanges.allChangeSets;

export default dbObjectChangesSlice.reducer;
