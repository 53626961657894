import React, { useState, useEffect, useMemo } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

// import { useAuth } from '../../app/AuthProvider';
import authService from '../../app/authService';

const InstanceSelector = (props) => {
  const {
    question,
    fieldName,
    onNext,
    options = {}
  } = props;

  const isSuperUser = authService.isSuperUser();

  const initialValue = useMemo(() => {
    return props.initialValue || '';
  }, [props.initialValue]);
  console.log('InstanceSelector initialValue: ', initialValue);

  // eslint-disable-next-line no-unused-vars
  let authorizedInstances = authService.getAuthorizedBendableInstances();
  console.log('InstanceSelector authorizedInstances: ', authorizedInstances);

  const [optionsData, setOptionsData] = useState(authorizedInstances);
  console.log('InstanceSelector optionsData: ', optionsData);

  const [selectedValue, setSelectedValue] = useState(null);

  const handleAutocompleteOnChange = (event, value) => {
    console.log('InstanceSelector.handleAutocompleteOnChange() event, value: ', event, value);
    setSelectedValue(value);
    props.onChange({ fieldName: fieldName, fieldValue: value });
  };

  useEffect(() => {
    console.log('InstanceSelector useEffect question, initialValue; initialValue: ', initialValue);
    setSelectedValue((prevValue) => (initialValue || null));
  }, [question, initialValue]);

  const prompt = props.prompt || 'Select the Bendable instance where you want to create a new resource.';
  
  return (
    <React.Fragment>
      <Box display="flex" flexDirection="column" style={{ width: '500px' }}>
        <Box style={{ 
          color: 'black', 
          fontSize: '1.0rem',
          marginTop: '20px'
        }}>
          {prompt}
        </Box>

        <Box 
          sx={{ 
            paddingTop: options.selectPaddingTop !== undefined ? options?.selectPaddingTop : '40px',
          }}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={optionsData}
            // getOptionLabel={getOptionLabel}
            multiple={false}
            value={selectedValue}
            // isOptionEqualToValue={(option, value) => (option._id === value._id)}
            onChange={handleAutocompleteOnChange}
            sx={{ width: 500 }}
            renderInput={(params) => <TextField {...params} label="Bendable Instance" />}
          />
        </Box>

        <Box>
            <Button
              color="primary"
              disabled={!selectedValue}
              // id="new-menu-button"
              variant="contained"
              disableElevation
              onClick={onNext}
              sx={{
                display: onNext ? 'block' : 'none',
                width: '100px',
                borderRadius: '18px', 
                textTransform: 'none',
                marginTop: '40px',
                marginLeft: '400px'
              }}
            >Next
            </Button>
        </Box>

      </Box>
    </React.Fragment>
  );
};

export default InstanceSelector;