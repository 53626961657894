import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import _ from 'lodash-es';
import callApi from '../apiService';

const initialState = {
  spotlightSlides: [],
  wizardSpotlightSlide: {
    status: 'draft',
    raw_data: {},
  },
  status: 'idle',
};

const fetchSpotlightSlides = async (options) => {
  console.log('spotlightSlidesSlice.fetchSpotlightSlides() options: ', options);
  options.limit = options.limit || 'all';

  try {
    const response = await callApi('getSpotlightSlides', null, { params: options });
    console.log('spotlightSlidesSlice.fetchSpotlightSlides() response: ', response);
    return response.data;   // axios attribute
  } catch (error) {
    console.error('spotlightSlidesSlice.fetchSpotlightSlides() error: ', error.message);
  }
};

export const getSpotlightSlides = createAsyncThunk(
  'spotlightSlides/getSpotlightSlides',
  async (options) => {
    const response = await fetchSpotlightSlides(options);
    return { tableName: options.tableName, rows: response.payload };
  }
);

export const spotlightSlidesSlice = createSlice({
  name: 'spotlightSlides',
  initialState,
  reducers: {
    mergeSpotlightSlideWizardRootData: (state, action) => {
      console.log('spotlightSlidesSlice mergeSpotlightSlideWizardRootData before merge; state, action: ', current(state), action);
      // the .mergeWith() below can generate this error: Unhandled Rejection (Error): [Immer] Immer only supports setting array indices and the 'length' property
      // so let's avoid Immer with cloneDeep...
      // state.wizardSpotlightSlide = _.mergeWith(state.wizardSpotlightSlide, action.payload, (objValue, srcValue, key, object, source, stack) => {
      const stateCopy = _.cloneDeep(state.wizardSpotlightSlide);
      const payloadCopy = _.cloneDeep(action.payload);
      state.wizardSpotlightSlide = _.mergeWith(stateCopy, payloadCopy, (objValue, srcValue, key, object, source, stack) => {
        if (_.isArray(srcValue)) {
          return srcValue;              // allows empty arrays to overwrite existing arrays
        } else {
          return undefined;
        }
      });
      console.log('spotlightSlidesSlice mergeSpotlightSlideWizardRootData after merge; state: ', current(state));
    },
    mergeSpotlightSlideWizardRawData: (state, action) => {
      console.log('spotlightSlidesSlice mergeSpotlightSlideWizardRawData before merge; state, action: ', current(state), action);
      // the .mergeWith() below can generate this error: Unhandled Rejection (Error): [Immer] Immer only supports setting array indices and the 'length' property
      // so let's avoid Immer with cloneDeep...
      // state.wizardSpotlightSlide.raw_data = _.mergeWith(state.wizardSpotlightSlide.raw_data, action.payload, (objValue, srcValue, key, object, source, stack) => {
      const stateCopy = _.cloneDeep(state.wizardSpotlightSlide.raw_data);
      const payloadCopy = _.cloneDeep(action.payload);
      state.wizardSpotlightSlide.raw_data = _.mergeWith(stateCopy, payloadCopy, (objValue, srcValue, key, object, source, stack) => {
        if (_.isArray(srcValue)) {
          return srcValue;              // allows empty arrays to overwrite existing arrays
        } else {
          return undefined;
        }
      });

      console.log('spotlightSlidesSlice mergeSpotlightSlideWizardRawData after merge; state: ', current(state));
    },
    resetSpotlightSlideWizardData: (state, action) => {
      console.log('spotlightSlidesSlice resetWizardData; state, action: ', current(state), action);
      state.wizardSpotlightSlide = {
        status: 'draft',
        raw_data: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getSpotlightSlides.pending, (state) => {
      console.log('spotlightSlidesSlice getSpotlightSlides.pending state: ', state);
      state.status = 'loading';
    })
    .addCase(getSpotlightSlides.fulfilled, (state, action) => {
      state.status = 'idle';
      console.log('spotlightSlidesSlice getSpotlightSlides.fulfilled action: ', action);
      state.spotlightSlides = action?.payload?.rows || [];
      // console.log('getSpotlightSlides case; state: ', current(state));
    });
  },
});

export const { 
  mergeSpotlightSlideWizardRootData,
  mergeSpotlightSlideWizardRawData,
  resetSpotlightSlideWizardData,
} = spotlightSlidesSlice.actions;

// for useSelector; state is complete store, not slice
export const selectSpotlightSlides = (state) => state.spotlightSlides.spotlightSlides;
export const selectWizardSpotlightSlide = (state) => state.spotlightSlides.wizardSpotlightSlide;

export default spotlightSlidesSlice.reducer;
