import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import _ from 'lodash-es';
import callApi from '../apiService';

const initialState = {
  spotlightCollections: [],
  wizardSpotlightCollection: {
    status: 'draft',
    raw_data: {}
  },
  status: 'idle',
};

const fetchSpotlightCollections = async (options) => {
  console.log('spotlightCollectionsSlice.fetchSpotlightCollections() options: ', options);
  options.limit = options.limit || 'all';

  try {
    const response = await callApi('getSpotlightCollections', null, { params: options });
    console.log('spotlightCollectionsSlice.fetchSpotlightCollections() response: ', response);
    return response.data;   // axios attribute
  } catch (error) {
    console.error('spotlightCollectionsSlice.fetchSpotlightCollections() error: ', error.message);
  }
};

export const getSpotlightCollections = createAsyncThunk(
  'spotlightCollections/getSpotlightCollections',
  async (options) => {
    const response = await fetchSpotlightCollections(options);
    return { tableName: options.tableName, rows: response.payload };
  }
);

export const spotlightCollectionsSlice = createSlice({
  name: 'spotlightCollections',
  initialState,
  reducers: {
    mergeSpotlightCollectionWizardRootData: (state, action) => {
      console.log('spotlightCollectionsSlice mergeSpotlightCollectionWizardRootData before merge; state, action: ', current(state), action);
      // the .mergeWith() below can generate this error: Unhandled Rejection (Error): [Immer] Immer only supports setting array indices and the 'length' property
      // so let's avoid Immer with cloneDeep...
      // state.wizardSpotlightCollection = _.mergeWith(state.wizardSpotlightCollection, action.payload, (objValue, srcValue, key, object, source, stack) => {
      const stateCopy = _.cloneDeep(state.wizardSpotlightCollection);
      const payloadCopy = _.cloneDeep(action.payload);
      state.wizardSpotlightCollection = _.mergeWith(stateCopy, payloadCopy, (objValue, srcValue, key, object, source, stack) => {
        if (_.isArray(srcValue)) {
          return srcValue;              // allows empty arrays to overwrite existing arrays
        } else {
          return undefined;
        }
      });
      console.log('spotlightCollectionsSlice mergeSpotlightCollectionWizardRootData after merge; state: ', current(state));
    },
    mergeSpotlightCollectionWizardRawData: (state, action) => {
      console.log('spotlightCollectionsSlice mergeSpotlightCollectionWizardRawData before merge; state, action: ', current(state), action);
      // the .mergeWith() below can generate this error: Unhandled Rejection (Error): [Immer] Immer only supports setting array indices and the 'length' property
      // so let's avoid Immer with cloneDeep...
      // state.wizardSpotlightCollection.raw_data = _.mergeWith(state.wizardSpotlightCollection.raw_data, action.payload, (objValue, srcValue, key, object, source, stack) => {
      const stateCopy = _.cloneDeep(state.wizardSpotlightCollection.raw_data);
      const payloadCopy = _.cloneDeep(action.payload);
      state.wizardSpotlightCollection.raw_data = _.mergeWith(stateCopy, payloadCopy, (objValue, srcValue, key, object, source, stack) => {
        if (_.isArray(srcValue)) {
          return srcValue;              // allows empty arrays to overwrite existing arrays
        } else {
          return undefined;
        }
      });

      console.log('spotlightCollectionsSlice mergeSpotlightCollectionWizardRawData after merge; state: ', current(state));
    },
    resetSpotlightCollectionWizardData: (state, action) => {
      console.log('spotlightCollectionsSlice resetWizardData; state, action: ', current(state), action);
      state.wizardSpotlightCollection = {
        status: 'draft',
        raw_data: {}
      };
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getSpotlightCollections.pending, (state) => {
      console.log('spotlightCollectionsSlice getSpotlightCollections.pending state: ', state);
      state.status = 'loading';
    })
    .addCase(getSpotlightCollections.fulfilled, (state, action) => {
      state.status = 'idle';
      console.log('spotlightCollectionsSlice getSpotlightCollections.fulfilled action: ', action);
      state.spotlightCollections = action?.payload?.rows || [];
      // console.log('getSpotlightCollections case; state: ', current(state));
    });
  },
});

export const { 
  mergeSpotlightCollectionWizardRootData,
  mergeSpotlightCollectionWizardRawData,
  resetSpotlightCollectionWizardData,
} = spotlightCollectionsSlice.actions;

// for useSelector; state is complete store, not slice
export const selectSpotlightCollections = (state) => state.spotlightCollections.spotlightCollections;
export const selectWizardSpotlightCollection = (state) => state.spotlightCollections.wizardSpotlightCollection;

export default spotlightCollectionsSlice.reducer;
