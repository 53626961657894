import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import objectHash from 'object-hash';

import selectOptionsService from './selectOptionsService';

const initialState = {
  status: 'idle',
  cache: {}                // values of keys are always an array of objects like: {displayName: "AP Biology", value: "3607"}
};


/**
 * Update the cache with the latest selectOptions for a given set of options
 * 
 * Set 'typeFilter' to differentiate between objectTypes that have sub-types (like CareerCollections and CommunityCollections)
 * Set 'isPrewarm' true to execute cache update only if cache is empty
 * 
 */
export const updateSelectOptionsCache = createAsyncThunk(
  'selectOptions/updateSelectOptionsCache',
  async (args) => {
    // eslint-disable-next-line no-unused-vars
    const { objectType, bendableInstance, typeFilter = null, isPrewarm = false } = args;
    // console.log('selectOptionsSlice.updateSelectOptionsCache() objectType, typeof objectType: ', objectType, typeof objectType);
    // console.log('selectOptionsSlice.updateSelectOptionsCache() bendableInstance, typeof bendableInstance: ', bendableInstance, typeof bendableInstance);
    const selectOptions = await selectOptionsService.getSelectOptions({
      objectType,
      bendableInstance,
      typeFilter
    });
    // The value we return becomes the `fulfilled` action payload
    return {
      objectType,
      bendableInstance,
      typeFilter,
      selectOptions
    };
  },
  {
    condition: (args, { getState, extra }) => {                             // check to see if we should abort thunk
      const { objectType, bendableInstance, typeFilter = null, isPrewarm = false } = args;     // objectType, bendableInstance, typeFilter = null, isPrewarm = false
      if (!objectType || !bendableInstance) return false;
      if (!isPrewarm) return true;                                          // always execute if !isPrewarm
      // console.log('selectOptionsSlice.prewarmCache() condition() objectType, bendableInstance, typeFilter: ', objectType, bendableInstance, typeFilter);
      const hash = getKeyHash(args);
      // console.log('selectOptionsSlice.prewarmCache() condition() hash: ', hash);
      const state = getState();
      // console.log('selectOptionsSlice.prewarmCache() condition() state: ', state);
      const shouldPrewarm = _.isEmpty(state?.selectOptions?.cache[hash]);
      // console.log('selectOptionsSlice.prewarmCache() condition() shouldPrewarm: ', shouldPrewarm);
      return shouldPrewarm;
    },
  }
);




export const selectOptionsSlice = createSlice({
  name: 'selectOptions',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addOption: (state, action) => {
      // console.log('selectOptionsSlice addOption; state: ', state);
      // console.log('selectOptionsSlice addOption; action: ', action);
      // console.log('selectOptionsSlice.addOption() action.payload: ', action.payload);
      const payload = (action && action.payload) || {};
      const hash = getKeyHash(payload);
      //   objectHash({
      //   objectType: payload.objectType,
      //   bendableInstance: payload.bendableInstance
      // });
      state.cache[hash] = state.cache[hash] || [];
      // console.log('selectOptionsSlice addOption; state.cache[hash]: ', state.cache[hash]);
      state.cache[hash].push(payload.option);
      // console.log('selectOptionsSlice addOption; state.cache[hash] after add: ', state.cache[hash]);
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(updateSelectOptionsCache.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateSelectOptionsCache.fulfilled, (state, action) => {
        state.status = 'idle';
        if (action.payload) {
          if (action.payload.selectOptions) {
            // console.log('selectOptionsSlice.updateSelectOptionsCache addCase() updateSelectOptionsCache.fulfilled; action.payload: ', action.payload);
            const hash = getKeyHash(action.payload);
            //   objectHash({
            //   objectType: action.payload.objectType,
            //   bendableInstance: action.payload.bendableInstance,
            //   typeFilter: action.payload.typeFilter
            // });
            state.cache[hash] = action.payload.selectOptions;     // [{ displayName: "AC", value: "3836" }]
          }
        }
      });
  },
});

export const getKeyHash = (params) => {
  //// console.log('selectOptionsSlice.getKeyHash() params: ', params);
  const hash = objectHash({
    objectType: (params.objectType === undefined) ? null : params.objectType,
    bendableInstance: (params.bendableInstance === undefined) ? null : params.bendableInstance,
    typeFilter: (params.typeFilter === undefined) ? null : params.typeFilter,
  });
  //// console.log('selectOptionsSlice.getKeyHash() hash: ', hash);
  return hash;
}

export const { addOption } = selectOptionsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

// for useSelector; state is complete store, not slice
export const selectSelectOptions = (state) => state.selectOptions.cache;


export default selectOptionsSlice.reducer;
