import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import _ from 'lodash-es';

import callApi from '../apiService';
import objectFactory from '../objectFactory';

const initialState = {
  tags: [],
  wizardDbObjectShell: {
    controlling_instance: null,
    target_bendable_instance: null,
    table_name: 'tags',
    status: 'draft',
    raw_data: {}
  },
  status: 'idle',
};

const fetchDbObjectShells = async (options) => {
  console.log('tagsSlice.fetchDbObjectShells() options: ', options);
  options.limit = options.limit || 'all';

  try {
    const response = await callApi('getDbObjectShells', null, { params: options });
    console.log('tagsSlice.fetchDbObjectShells() response: ', response);
    return response.data;   // axios attribute
  } catch (error) {
    console.error('tagsSlice.fetchDbObjectShells() error: ', error.message);
  }
};

export const getDbObjectShells = createAsyncThunk(
  'tags/getDbObjectShells',
  async (options) => {
    const response = await fetchDbObjectShells(options);
    return { tableName: options.tableName, rows: response.payload };
  }
);

const fetchTags = async (options) => {
  console.log('tagsSlice.fetchTags() options: ', options);
  options.limit = options.limit || 'all';

  try {
    const response = await callApi('getTags', { apiVersion: options?.apiVersion }, { params: options });
    console.log('tagsSlice.fetchTags() response: ', response);
    return response.data;   // axios attribute
  } catch (error) {
    console.error('tagsSlice.fetchTags() error: ', error.message);
  }
};

export const getTags = createAsyncThunk(
  'tags/getTags',
  async (options) => {
    console.log('tagsSlice.getTags() options: ', options);
    const response = await fetchTags(options);
    console.log('tagsSlice.getTags() response: ', response);
    return { tags: response.payload.tags };
  }
);

export const buildWizardDbObjectShell = createAsyncThunk(
  'tags/buildWizardDbObjectShell',
  async (options) => {
    console.log('tagsSlice buildWizardDbObjectShell() options: ', options);
    const response = await fetchTags(options);
    console.log('tagsSlice.buildWizardDbObjectShell() response: ', response);
    return { tag: (response.payload?.tags && response.payload.tags[0]) || null };
  }
);



export const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    mergeTagWizardRootData: (state, action) => {
      console.log('tagsSlice mergeTagWizardRootData before merge; state, action: ', current(state), action);
      // the .mergeWith() below can generate this error: Unhandled Rejection (Error): [Immer] Immer only supports setting array indices and the 'length' property
      // so let's avoid Immer with cloneDeep...
      // state.wizardDbObjectShell = _.mergeWith(state.wizardDbObjectShell, action.payload, (objValue, srcValue, key, object, source, stack) => {
      const stateCopy = _.cloneDeep(state.wizardDbObjectShell);
      const payloadCopy = _.cloneDeep(action.payload);
      state.wizardDbObjectShell = _.mergeWith(stateCopy, payloadCopy, (objValue, srcValue, key, object, source, stack) => {
        if (_.isArray(srcValue)) {
          return srcValue;              // allows empty arrays to overwrite existing arrays
        } else {
          return undefined;
        }
      });
      console.log('tagsSlice mergeTagWizardRootData after merge; state: ', current(state));
    },
    mergeTagWizardRawData: (state, action) => {
      console.log('tagsSlice mergeTagWizardRawData before merge; state, action: ', current(state), action);
      // the .mergeWith() below can generate this error: Unhandled Rejection (Error): [Immer] Immer only supports setting array indices and the 'length' property
      // so let's avoid Immer with cloneDeep...
      // state.wizardDbObjectShell.raw_data = _.mergeWith(state.wizardDbObjectShell.raw_data, action.payload, (objValue, srcValue, key, object, source, stack) => {
      const stateCopy = _.cloneDeep(state.wizardDbObjectShell.raw_data);
      console.log('tagsSlice mergeTagWizardRootData stateCopy: ', stateCopy);
      const payloadCopy = _.cloneDeep(action.payload);
      console.log('tagsSlice mergeTagWizardRootData payloadCopy: ', payloadCopy);
      state.wizardDbObjectShell.raw_data = _.mergeWith(stateCopy, payloadCopy, (objValue, srcValue, key, object, source, stack) => {
        // console.log('tagsSlice mergeTagWizardRawData objValue: ', objValue);
        // console.log('tagsSlice mergeTagWizardRawData srcValue: ', srcValue);
        // console.log('tagsSlice mergeTagWizardRawData key: ', key);
        // console.log('tagsSlice mergeTagWizardRawData object: ', object);
        // console.log('tagsSlice mergeTagWizardRawData source: ', source);
        if (_.isArray(srcValue)) {
          return srcValue;              // allows empty arrays to overwrite existing arrays
        } else {
          return undefined;
        }
      });

      console.log('tagsSlice mergeTagWizardRawData after merge; state: ', current(state));
    },
    resetTagWizardData: (state, action) => {
      console.log('tagsSlice resetWizardData; state, action: ', current(state), action);
      state.wizardDbObjectShell = {
        controlling_instance: null,
        target_bendable_instance: null,
        table_name: 'tags',
        status: 'draft',
        raw_data: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
  //   .addCase(getDbObjectShells.pending, (state) => {
  //     console.log('tagsSlice getDbObjectShells.pending state: ', state);
  //     state.status = 'loading';
  //   })
  //   .addCase(getDbObjectShells.fulfilled, (state, action) => {
  //     state.status = 'idle';
  //     console.log('tagsSlice getDbObjectShells.fulfilled action: ', action);
  //     state.currentDbObjectShells[action.payload.tableName] = action.payload.rows;
  //     // console.log('getDbObjectShells case; state: ', current(state));
  //   });
    .addCase(getTags.pending, (state) => {
      console.log('tagsSlice getTags.pending state: ', state);
      state.status = 'loading';
    })
    .addCase(getTags.fulfilled, (state, action) => {
      state.status = 'idle';
      console.log('tagsSlice getTags.fulfilled action: ', action);
      state.tags = action?.payload?.tags || [];
      // console.log('getTags case; state: ', current(state));
    })
    .addCase(buildWizardDbObjectShell.pending, (state) => {
      console.log('tagsSlice buildWizardDbObjectShell.pending state: ', state);
      state.status = 'loading';
    })
    .addCase(buildWizardDbObjectShell.fulfilled, (state, action) => {
      state.status = 'idle';
      console.log('tagsSlice buildWizardDbObjectShell.fulfilled action: ', action);
      const tag = action?.payload?.tag;
      const targetBendableInstance = action?.meta?.arg?.target_bendable_instance || 'bendable-labs';
      const dbObjectShell = objectFactory.buildDbObjectShellFromTagDocument(tag, targetBendableInstance);
      console.log('tagsSlice buildWizardDbObjectShell.fulfilled dbObjectShell: ', dbObjectShell);
      state.wizardDbObjectShell = dbObjectShell;  
      // console.log('buildWizardDbObjectShell case; state: ', current(state));
    });
},
});

export const {
  resetTagWizardData,
  mergeTagWizardRootData,
  mergeTagWizardRawData,
} = tagsSlice.actions;

// for useSelector; state is complete store, not slice
export const selectCurrentWizardDbObjectShell = (state) => state.tags.wizardDbObjectShell;
export const selectTags = (state) => state.tags.tags;

export default tagsSlice.reducer;
