import React from 'react';

import Box from '@mui/material/Box';
import binBackground from '../../assets/images/bin.png';

const BinIcon = (props) => {
  const { sx, iconWidth, textColor, itemCount = '' } = props;

  return (
    <Box
      sx={{
        position: 'absolute',
        ...sx
      }}
    >
      <img 
        src={binBackground} 
        alt="bin-icon"
        style={{ width: iconWidth }}
      />

      <Box
        sx={{
          position: 'absolute',
          top: '3px',
          left: '0px',
          width: iconWidth,
        }}
      >

        <Box
          sx={{
            color: textColor,
            width: iconWidth,
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: itemCount > 999 ? '0.6rem' : '0.85rem'
          }}
        >
            {'' + itemCount}
        </Box>

      </Box>

    </Box>
  );
};

export default BinIcon;