import * as React from 'react';

import authService from '../../app/authService';

import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const NewMenuButton = (props) => {
  const {
    wrapperStyle,
    onSelect,
  } = props;

  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const isSuperUser = authService.isSuperUser();
  const isAdmin = authService.isAdmin();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);    // disable until we need a full menu
    // onSelect('newLearningObject');    // temp until we need a full menu
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    const innerText = event.target.innerText;
    console.log('NewMenuButton.handleClose; innerText: ', innerText);
    if (innerText === 'Spotlight Slide') onSelect('newSpotlightSlide');
    if (innerText === 'Spotlight Container') onSelect('newSpotlightCollection');
    if (innerText === 'Resource') onSelect('newLearningObject');
    if (innerText === 'Provider') onSelect('newProvider');
    if (innerText === 'Tag') onSelect('newTag');
    if (innerText === 'Topic') onSelect('newTopic');
    if (innerText === 'Category') onSelect('newCategory');
    if (innerText === 'Tenant') onSelect('newTenant');
    if (innerText === 'Bulk Resource Upload') onSelect('newBulkResourceUpload');
    if (innerText === 'Community Collection') onSelect('newCommunityCollection');
    if (innerText === 'Career Collection') onSelect('newCareerCollection');
    if (innerText === 'User') onSelect('newUser');
  };

  return (
    <div style={wrapperStyle}>
      <Button
        color="primary"
        id="new-menu-button"
        aria-controls="new-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        // endIcon={<AddIcon />}
        sx={{
          width: '120px',
          borderRadius: '18px', 
          '& .MuiSvgIcon-root': {
            paddingBottom: '2px'
          }, 
          textTransform: 'none'
        }}
      >
        New
      </Button>
      <Menu
        id="new-menu"
        MenuListProps={{
          'aria-labelledby': 'new-menu-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        // {...props}
        sx={{
          '& .MuiPaper-root': {
            border: '0.5px solid #d0d0d0',
            borderRadius: 2,
            marginTop: theme.spacing(1),
            minWidth: 180,
            color:
              theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
            boxShadow:
              'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
              padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
              '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
              },
              '&:hover': {
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.main
              },
              // '&:active': {
              //   backgroundColor: alpha(
              //     theme.palette.primary.main,
              //     theme.palette.action.selectedOpacity,
              //   ),
              // },
            },
          },
        }}
      >
        <MenuItem onClick={handleClose} disableRipple>
          Spotlight Slide
        </MenuItem>

        <MenuItem onClick={handleClose} disableRipple>
          Spotlight Container
        </MenuItem>

        { isSuperUser &&
        <Divider sx={{ my: 0.5 }} />
        }

        { isSuperUser &&
        <MenuItem onClick={handleClose} disableRipple>
          Resource
        </MenuItem>
        }

        { isSuperUser &&
        <MenuItem onClick={handleClose} disableRipple>
          Bulk Resource Upload
        </MenuItem>
        }

        { isSuperUser &&
        <MenuItem onClick={handleClose} disableRipple>
          Provider
        </MenuItem>
        }

        { isSuperUser &&
        <MenuItem onClick={handleClose} disableRipple>
          Tag
        </MenuItem>
        }

        { isSuperUser &&
        <MenuItem onClick={handleClose} disableRipple>
          Topic
        </MenuItem>
        }

        { isSuperUser &&
        <MenuItem onClick={handleClose} disableRipple>
          Category
        </MenuItem>
        }

        { isSuperUser &&
        <MenuItem onClick={handleClose} disableRipple>
          Tenant
        </MenuItem>
        }



        { isSuperUser &&
        <Divider sx={{ my: 0.5 }} />
        }

        { isSuperUser &&
        <MenuItem onClick={handleClose} disableRipple>
          Community Collection
        </MenuItem>
        }
        
        { isSuperUser &&
        <MenuItem onClick={handleClose} disableRipple>
          Career Collection
        </MenuItem>
        }

        { isAdmin &&
        <Divider sx={{ my: 0.5 }} />
        }

        { isAdmin &&
        <MenuItem onClick={handleClose} disableRipple>
          User
        </MenuItem>
        }


      </Menu>
    </div>
  );
}

export default NewMenuButton;