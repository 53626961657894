import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectVariants() {
  const [sort, setSort] = React.useState('updatedAt-desc');

  const handleChange = (event) => {
    setSort(event.target.value);
  };

  return (
    <div>
      <FormControl id="sort-form-control" variant="standard" sx={{ m: 1, minWidth: 120, }}>
        <InputLabel id="sort-label" sx={{ color: '#e9ad21' }}>Sort</InputLabel>
        <Select
          labelId="sort-label"
          id="sort-select"
          value={sort}
          onChange={handleChange}
          label="Sort"
          sx={{
            color: '#e9ad21',
            '&::before': {
              borderBottom: 'none',
            },
            '& .MuiSvgIcon-root': {
              display: 'none',
            }
          }}
        >
          {/* <MenuItem value=""><em>None</em></MenuItem> */}
          <MenuItem value={'updatedAt-desc'} sx={{ color: '#e9ad21' }}>Most recent</MenuItem>
          <MenuItem value={'editorUpdatedAt-desc'} sx={{ color: '#e9ad21' }}>Editor first</MenuItem>
          <MenuItem value={'operatorUpdatedAt-desc'} sx={{ color: '#e9ad21' }}>Operator first</MenuItem>
          <MenuItem value={'createdAt-asc'} sx={{ color: '#e9ad21' }}>Created</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
