import React, { useState, useEffect } from 'react';
import _ from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useTheme } from '@mui/styles';
import { useSnackbar } from 'notistack';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';

import { 
  mergeCreateWizardChanges,
  selectCurrentCreateWizardPage,
} from '../../app/createPageSlice';

import callApi from '../../app/apiService';

const ResourceShellsSummaryNavBar = (props) => {
  const {children, saveDisabled } = props;
  const currentCreateWizardPage = useSelector(selectCurrentCreateWizardPage);
  const { resourceCard } = currentCreateWizardPage;
  const { title, url, provider } = resourceCard;

  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  // eslint-disable-next-line no-unused-vars
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // console.log('ResourceShellsSummaryNavBar theme: ', theme);

  const [isRootRoute, setIsRootRoute] = useState(_.endsWith(location.pathname, '/resourceWizard'));
  const [shouldEnableSubmit, setShouldEnableSumbit] = React.useState(false);

  useEffect(() => {
    const shouldEnable = (title && url && provider);
    setShouldEnableSumbit(shouldEnable);
  }, [title, url, provider]);

  useEffect(() => {
    const isRoot = _.endsWith(location.pathname, '/resourceWizard');
    setIsRootRoute(isRoot);
  }, [location.pathname]);

  /**
   * Save draft dbObject; create if no _id is present, otherwise update
   * 
   * @param {*} event 
   */
  const handleSaveResourceClick = async (status) => {
    console.log('ResourceShellsSummaryNavBar.handleSaveResourceClick() status: ', status);
    status = status || 'draft';
    const controllingInstance = currentCreateWizardPage && currentCreateWizardPage.resourceCard && currentCreateWizardPage.resourceCard.controlling_instance;
    const id = currentCreateWizardPage && currentCreateWizardPage.resourceCard && currentCreateWizardPage.resourceCard._id;
    const setId = currentCreateWizardPage && currentCreateWizardPage.resourceCard && currentCreateWizardPage.resourceCard.set_id;
    
    const dbObjectData = {
      bendableInstance: controllingInstance,
      _id: id || null,
      setId: setId || null,
      postgresTableName: 'learning_objects',
      status: status,
      ...currentCreateWizardPage
    };

    if (!id) {
      // create new shell
      try {
        const apiResponse = await callApi('createDbObjectShells', null, dbObjectData);
        console.log('ResourceShellsSummaryNavBar createDbObjectShells apiResponse: ', apiResponse);
        // set _id for later saves...
        const id = (apiResponse && apiResponse.data && apiResponse.data.payload && apiResponse.data.payload.dbObjectShell && apiResponse.data.payload.dbObjectShell._id) || null;
        if (id) {
          dispatch(mergeCreateWizardChanges({
            resourceCard: { _id: id }
          }));
        }
        // set set_id for later saves...
        const setId = (apiResponse && apiResponse.data && apiResponse.data.payload && apiResponse.data.payload.dbObjectShell && apiResponse.data.payload.dbObjectShell.set_id) || null;
        if (setId) {
          dispatch(mergeCreateWizardChanges({
            resourceCard: { set_id: setId }
          }));
        }
        enqueueSnackbar('Resource shell created');
      } catch (err) {
        console.log('ERROR: ResourceShellsSummaryNavBar createDbObjectShells() err: ', err);
        enqueueSnackbar('Error saving changes');
      }

    } else {
      // update existing shell
      try {
        const apiResponse = await callApi('updateDbObjectShell', { id: id }, dbObjectData);
        console.log('ResourceShellsSummaryNavBar updateDbObjectShell apiResponse: ', apiResponse);
        if (status === 'draft') {
          enqueueSnackbar('Draft resource saved');
        } else {
          enqueueSnackbar('Resource submitted for review');
        }
      } catch (err) {
        console.log('ERROR: ResourceShellsSummaryNavBar createDbObjectShells() err: ', err);
        enqueueSnackbar('Error updating resource');
      }      
    }

  };


  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          elevation={0}
          color="transparent"
          sx={{ borderBottom: `1px solid ${theme.palette.primary['800']}`}}
        >
          <Toolbar>

            <Box sx={{ flexGrow: 1 }} />  

            <Box>
              {/* <ResourceWizardTabs disabled={isRootRoute} /> */}
            </Box>

            <Box sx={{ flexGrow: 1 }} />

            <Box>
              <Button
                color="primary"
                disabled={isRootRoute || saveDisabled}
                id="new-menu-button"
                disableElevation
                onClick={(event) => { handleSaveResourceClick('draft'); }}
                sx={{  
                  textTransform: 'none'
                }}
              >Save draft
              </Button>
            </Box>

            <Box>
              <Button
                color="primary"
                disabled={isRootRoute || !shouldEnableSubmit}
                id="new-menu-button"
                variant="contained"
                disableElevation
                onClick={(event) => { handleSaveResourceClick('submitted'); }}
                sx={{ 
                  borderRadius: '18px', 
                  textTransform: 'none',
                  marginLeft: '32px'
                }}
              >Submit for review
              </Button>
            </Box>

          </Toolbar>
        </AppBar>

      </Box>
      {children}
    </React.Fragment>
  );
};

export default ResourceShellsSummaryNavBar;
