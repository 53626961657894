import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';

import Box from '@mui/material/Box';

import messageBus from '../app/messageBus';

import DashboardNavBar from '../components/dashboard/DashboardNavBar';
import AssignDbObjectBinModal from '../components/modals/assignDbObjectBinModal';
import authService from '../app/authService';
import callApi from '../app/apiService';
import DashboardDbObjectContainersRow from '../components/dashboard/DashboardDbObjectContainersRow';
import DashboardDbObjectContainersTable from '../components/dashboard/DashboardDbObjectContainersTable';
import GenericModal from '../components/modals/genericModal';

import { 
  mergeResourceWizardRootData,
} from '../app/resourcesSlice';

import { 
  mergeCollectionWizardRootData,
} from '../app/redux/collectionsSlice';

import { 
  mergeProviderWizardRootData,
} from '../app/redux/providersSlice';

import { 
  mergeTagWizardRootData,
} from '../app/redux/tagsSlice';

import { 
  mergeTopicWizardRootData,
} from '../app/redux/topicsSlice';

import { 
  mergeCategoryWizardRootData,
} from '../app/redux/categoriesSlice';

import { 
  mergeTenantWizardRootData,
} from '../app/redux/tenantsSlice';

import {
  setActiveChangeSet,
} from '../app/dbObjectChangesSlice';

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const uiVersion = authService.getUiVersion();
  // const [messageBusInitialized, setMessageBusInitialized] = useState(0);                        // 0=undetermined, 1=subscribed, 2=unsubscribe
  const [assignDbObjectBinModalIsOpen, setAssignDbObjectBinModalIsOpen] = useState(false);
  const [currentDbObjectShell, setCurrentDbObjectShell] = useState(null);
  const [ownDraftsView, setOwnDraftsView] = useState('grid');
  const [ownAssignedView, setOwnAssignedView] = useState('grid');  
  const [canApproveSubmittedView, setCanApproveSubmittedView] = useState('table');
  const [confirmItemDeleteIsOpen, setConfirmItemDeleteIsOpen] = useState(false);
  const [currentCardAction, setCurrentCardAction] = useState(null);

  const showAssignDbObjectBinModal = useCallback((dbObjectShell) => {
    setCurrentDbObjectShell(dbObjectShell);
    setAssignDbObjectBinModalIsOpen(true);
  }, []);

  /**
   * Subscribe on mount; unsubscribe on unmount
   */
  // useEffect(() => {
  //   messageBus.subscribe(messageBus.ATTEMPT_DB_OBJECT_CONTAINER_ASSIGNMENT_EVENT, showAssignDbObjectBinModal);
  //   console.log('Dashboard useEffect subscribed to ATTEMPT_DB_OBJECT_CONTAINER_ASSIGNMENT_EVENT...');

  //   return () => {
  //     messageBus.unsubscribe(messageBus.ATTEMPT_DB_OBJECT_CONTAINER_ASSIGNMENT_EVENT, showAssignDbObjectBinModal);
  //     console.log('Dashboard useEffect unsubscribed from ATTEMPT_DB_OBJECT_CONTAINER_ASSIGNMENT_EVENT...');
  //   }
  // }, [showAssignDbObjectBinModal]);


  const handleOwnDraftsViewToggle = () => {
    setOwnDraftsView((prevValue) => {
      if (prevValue === 'table') return 'grid';
      if (prevValue === 'grid') return 'table';
      return prevValue;
    });
  };

  const handleCanApproveSubmittedViewToggle = () => {
    setCanApproveSubmittedView((prevValue) => {
      if (prevValue === 'table') return 'grid';
      if (prevValue === 'grid') return 'table';
      return prevValue;
    });
  };

  const handleOwnAssignedViewToggle = () => {
    setOwnAssignedView((prevValue) => {
      if (prevValue === 'table') return 'grid';
      if (prevValue === 'grid') return 'table';
      return prevValue;
    });
  };

  const confirmAssignDbObjectBinModalHandler = async (data) => {          // e.g.: {action: 'confirm', assignEditor: true, assignOperator: false}
    const { binId, action, assignEditor, assignOperator } = data;
    console.log('Dashboard.confirmAssignDbObjectBinModalHandler() data: ', data);
    setAssignDbObjectBinModalIsOpen(false);
    if ((action === 'confirm' && (assignEditor || assignOperator))) {
      const updateData = {
        status: 'submitted'
      };
      if (assignEditor) updateData.editorialApproverUserId = authService.getUserId();
      if (assignOperator) updateData.operationalApproverUserId = authService.getUserId();
      const apiResponse = await callApi('updateDbObjectBin', { id: binId }, updateData);
      console.log('Dashboard.confirmAssignDbObjectBinModalHandler() apiResponse: ', apiResponse);
      messageBus.publish(messageBus.DASHBOARD_DB_OBJECT_CONTAINERS_CHANGED_EVENT);
    }
  };

  const handleSingleDocumentBin = async (options) => {
    const { item, config } = options;
    const { canApproveSubmitted, ownAssigned } = config;
    // item is a dbObjectBin with only one doc; we need the first and only dbObjectShell...
    const apiResponse = await callApi('getDbObjectShells', null, { params: { binId: item?._id } });
    console.log('Dashboard.handleSingleDocumentBin() apiResponse: ', apiResponse);

    if (apiResponse?.data?.payload && apiResponse?.data?.payload[0]) {
      const dbObjectShell = apiResponse.data.payload[0];
      // handle edit of draft
      if (item?.status === 'draft') {
        if (item?.table_name === 'learning_objects') {
          dispatch(mergeResourceWizardRootData(dbObjectShell));
          navigate(`/${uiVersion}/resourceWizard/create/1`);
        }
        if (item?.table_name === 'community_collections') {
          dispatch(mergeCollectionWizardRootData(dbObjectShell));
          navigate(`/${uiVersion}/collections/create/1`);
        }
        if (item?.table_name === 'career_collections') {
          dispatch(mergeCollectionWizardRootData(dbObjectShell));
          navigate(`/${uiVersion}/careerCollections/create/1`);
        }
        if (item?.table_name === 'spotlights') {
          dispatch(mergeCollectionWizardRootData(dbObjectShell));
          navigate(`/${uiVersion}/spotlightSlides/create/1`);
        }
        if (item?.table_name === 'v_spotlight_collections') {
          dispatch(mergeCollectionWizardRootData(dbObjectShell));
          navigate(`/${uiVersion}/spotlightCollections/create/1`);
        }
        if (item?.table_name === 'providers') {
          dispatch(mergeProviderWizardRootData(dbObjectShell));
          navigate(`/${uiVersion}/providerWizard/create/1`);
        }
        if (item?.table_name === 'tags') {
          dispatch(mergeTagWizardRootData(dbObjectShell));
          navigate(`/${uiVersion}/tagWizard/create/1`);
        }
        if (item?.table_name === 'topics') {
          dispatch(mergeTopicWizardRootData(dbObjectShell));
          navigate(`/${uiVersion}/topicWizard/create/1`);
        }
        if (item?.table_name === 'categories') {
          dispatch(mergeCategoryWizardRootData(dbObjectShell));
          navigate(`/${uiVersion}/categoryWizard/create/1`);
        }
        if (item?.target_object_type === 'tenant') {
          dispatch(mergeTenantWizardRootData(dbObjectShell));
          navigate(`/${uiVersion}/tenantWizard/create/1`);
        }
        // if (item?.table_name === 'providers') {
        //   dispatch(mergeProviderWizardRootData(dbObjectShell));
        //   navigate(`/${uiVersion}/providerWizard/create/1`);
        // }
        // if (item?.table_name === 'providers') {
        //   dispatch(mergeProviderWizardRootData(dbObjectShell));
        //   navigate(`/${uiVersion}/providerWizard/create/1`);
        // }
      }

      // handle click to take ownership of submitted dbObjectShell
      if (canApproveSubmitted) {          // was: (dbObjectShell?.status === 'submitted')
        // messageBus.publish(messageBus.ATTEMPT_DB_OBJECT_CONTAINER_ASSIGNMENT_EVENT, dbObjectShell);
        showAssignDbObjectBinModal(dbObjectShell);
      }

      if (ownAssigned) {
        handleMultiDocumentBin(item);
      }
    }
  };


  const handleMultiDocumentBin = (item) => {
    console.log('Dashboard.handleMultiDocumentBin() start bulk edit; item._id: ', item?._id);
    if (item?.table_name === 'learning_objects') {
      dispatch(setActiveChangeSet({ changeSetId: item?._id }));          // change set ID is binID
      // dispatch(fetchDbObjectChanges({ changeSetId: item?._id }));        // change set ID is binID
      // dispatch(getDbObjectShells({ 
      //   binId: item?._id,
      // }));
      navigate(`/${uiVersion}/editResources/${item?._id}`);
    }
  };

  const handleCardAction = async (options) => {
    console.log('Dashboard.handleCardAction() options: ', options);
    const { action, item } = options;

    if (action === 'click') {
      try {
        if (item?.document_count === 1) {
          handleSingleDocumentBin(options);

        } else {
          // item is a dbObjectBin with multiple documents; do bulk edit of all items in bin
          handleMultiDocumentBin(item);
        }
      } catch (err) {
        console.error('Dashboard.handleCardAction() err: ', err);
      }      
    }

    if (options.action === 'delete') {
      setCurrentCardAction(options);
      setConfirmItemDeleteIsOpen(true);
    }
    
    if (options.action === 'resetToDraft') {
      console.log('Dashboard.handleCardAction() reset to draft...');
      // setCurrentCardAction(options);
      
      if (options.objectType === 'db_object_bin') {
        console.log('Dashboard.handleCardAction() reset bin...');
        try {
          const resetStatusResponse = await callApi('resetDbObjectBinStatus', { id: options.target }, { status: 'draft' });   // redundant (default is draft)
          console.log('Dashboard.handleCardAction() bin resetStatusResponse: ', resetStatusResponse);
          messageBus.publish(messageBus.DASHBOARD_DB_OBJECT_CONTAINERS_CHANGED_EVENT);    // cause refresh
        } catch (err) {
          console.log('Dashboard.handleCardAction() bin err: ', err);
        }
      }
    }
    
  };

  const confirmItemDeleteHandler = async (action) => {
    setConfirmItemDeleteIsOpen(false);
    console.log('Dashboard.confirmItemDeleteHandler() action: ', action);
    console.log('Dashboard.confirmItemDeleteHandler() currentCardAction: ', currentCardAction);
    if (action === 'confirm') {
      if (currentCardAction.objectType === 'db_object_bin') {
        try {
          const deleteResponse = await callApi('deleteDbObjectBin', { id: currentCardAction.target }, {});
          console.log('Dashboard.confirmItemDeleteHandler() bin deleteResponse: ', deleteResponse);
          messageBus.publish(messageBus.DASHBOARD_DB_OBJECT_CONTAINERS_CHANGED_EVENT);
        } catch (err) {
          console.error('Dashboard.confirmItemDeleteHandler() bin deleteResponse; err: ', err);
        }
      }
      if (currentCardAction.objectType === 'db_object_shell') {
        try {
          const deleteResponse = await callApi('deleteDbObjectShell', { id: currentCardAction.target }, {});
          console.log('Dashboard.confirmItemDeleteHandler() dbObjectShell deleteResponse: ', deleteResponse);
          // if ((deleteResponse?.data?.status === 'success')  && (deleteResponse?.data?.payload?.dbObjectShellId === currentCardAction.target)) {
          //   dispatch(deleteDbObjectShell({ 
          //     storeKey: currentCardAction.storeKey, 
          //     targetId: deleteResponse?.data?.payload?.dbObjectShellId }))
          // }
          messageBus.publish(messageBus.DASHBOARD_DB_OBJECT_CONTAINERS_CHANGED_EVENT);
        } catch (err) {
          console.error('Dashboard.confirmItemDeleteHandler() dbObjectShell deleteResponse; err: ', err);
        }
      }
    }
    setCurrentCardAction(null);
  };


  return (
    <div style={{
      width: '100%',
      height: 'calc(100% - 65px)'
    }}>
      <DashboardNavBar
        saveDisabled={false}
        submitDisabled={true}
      >
        <Box display="flex" flexDirection="column" sx={{ height: 'calc(100% - 65px)', }}>

          {(ownDraftsView === 'grid') &&
            <DashboardDbObjectContainersRow
              config={{ ownDrafts: true, cardType: 'large', sort: 'createdAt', sortDirection: 'desc' }}
              onToggle={handleOwnDraftsViewToggle}
              onCardAction={handleCardAction}
            />
          }
          {(ownDraftsView === 'table') &&
            <DashboardDbObjectContainersTable
              config={{ ownDrafts: true }}
              onToggle={handleOwnDraftsViewToggle}
              onCardAction={handleCardAction}
            />
          }


          {(ownAssignedView === 'grid') &&
            <DashboardDbObjectContainersRow
              config={{ ownAssigned: true, cardType: 'small' }}
              onToggle={handleOwnAssignedViewToggle}
              onCardAction={handleCardAction}
            />
          }
          {(ownAssignedView === 'table') &&
            <DashboardDbObjectContainersTable
              config={{ ownAssigned: true }}
              onToggle={handleOwnAssignedViewToggle}
              onCardAction={handleCardAction}
            />
          }


          {(canApproveSubmittedView === 'grid') &&
            <DashboardDbObjectContainersRow
              config={{ canApproveSubmitted: true, cardType: 'small' }}
              onToggle={handleCanApproveSubmittedViewToggle}
              onCardAction={handleCardAction}
            />
          }
          {(canApproveSubmittedView === 'table') &&
            <DashboardDbObjectContainersTable
              config={{ canApproveSubmitted: true }}
              onToggle={handleCanApproveSubmittedViewToggle}
              onCardAction={handleCardAction}
            />
          }

        </Box>
      </DashboardNavBar>

      <AssignDbObjectBinModal
        width="30%"
        height="30%"
        isOpen={assignDbObjectBinModalIsOpen}
        onClose={confirmAssignDbObjectBinModalHandler}
        data={currentDbObjectShell}
        cancelLabel="Cancel"
        confirmLabel="Assign"
      />

      <GenericModal
        width="30%"
        height="30%"
        isOpen={confirmItemDeleteIsOpen}
        onClose={confirmItemDeleteHandler}
        cancelLabel="Cancel"
        confirmLabel="Delete"
      // secondaryConfirmLabel={null}
      >
        <Box
          sx={{
            fontSize: '1.4rem',
            paddingBottom: '1rem'
          }}
        >
          {`Are you sure that you wish to permanently delete the item titled "${currentCardAction?.title}"?`}
        </Box>
      </GenericModal>

    </div>
  );
};

export default Dashboard;