import React from 'react';
import { useTheme } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

const BulkUploadResourcesNavBar = (props) => {
  const {children } = props;
  //const currentCreateWizardPage = useSelector(selectCurrentCreateWizardPage);
  // const { collectionCard } = currentCreateWizardPage;
  // const { title, draft_description, url, provider, learning_format } = collectionCard;


  const theme = useTheme();
  // console.log('BulkUploadResourcesNavBar theme: ', theme);

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          elevation={0}
          color="transparent"
          sx={{
            paddingLeft: '40px',
            paddingRight: '40px',
            borderBottom: `1px solid ${theme.palette.primary['800']}`
          }}
        >
          <Toolbar>

            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ fontSize: '1.0rem', color: 'gray.mediumDark' }}
            >
              Bulk Upload Resources
            </Typography>

            <Box sx={{ flexGrow: 1 }} />

            <Box sx={{ flexGrow: 1 }} />

            <Box
              sx={{
                display: 'none'
              }}
            >

            </Box>

          </Toolbar>
        </AppBar>

      </Box>
      {children}
    </React.Fragment>
  );
};

export default BulkUploadResourcesNavBar;
