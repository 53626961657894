import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash-es';

import useTheme from '@mui/styles/useTheme';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import AccountCircle from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import CheckIcon from '@mui/icons-material/Check';

import { useAuth } from '../../app/AuthProvider';
import authService from '../../app/authService';
import callApi from '../../app/apiService';

import { ROUTES } from '../../constants';

import NewMenuButton from './NewMenuButton';
// import EditMenuButton from './EditMenuButton';
import ViewMenuButton from './ViewMenuButton';
import InstanceMenuButton from './InstanceMenuButton';

import {
  setUserConfig,
  selectUserConfigCache,
} from '../../app/redux/configSlice';

import {
  setActiveAuthorizedBendableInstances,
  selectActiveAuthorizedBendableInstances,
} from '../../app/redux/authSlice';

import {
  resetBulkResourceUpload,
  resetResourceWizardData,
} from '../../app/resourcesSlice';

import {
  resetProviderWizardData,
} from '../../app/redux/providersSlice';

import {
  resetTagWizardData,
} from '../../app/redux/tagsSlice';

import {
  resetTopicWizardData,
} from '../../app/redux/topicsSlice';

import {
  resetCategoryWizardData,
} from '../../app/redux/categoriesSlice';

import {
  resetTenantWizardData,
} from '../../app/redux/tenantsSlice';

import {
  setDoingEdit,
  resetCollectionWizardData,
} from '../../app/redux/collectionsSlice';

import {
  resetActiveUser
} from '../../app/redux/usersSlice';

// import configService from '../../app/configService';

export default function AppNavBar({children}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const auth = useAuth();
  const uiVersion = auth.getUiVersion();

  const currentActiveAuthorizedBendableInstances = useSelector(selectActiveAuthorizedBendableInstances);
  const currentUserConfigCache = useSelector(selectUserConfigCache);

  //const [userId, getUserId] = React.useState(authService.getUserId() || 'none');
  const [activeBendableInstances, setActiveBendableInstances] = useState([]);
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);

  const isDevelopment = _.get(currentUserConfigCache, 'development.is_developer') || false;
  const shouldUseHighContrast = _.get(currentUserConfigCache, 'ui.high_contrast') || false;
  const shouldUseApiV2 = (_.get(currentUserConfigCache, 'api_version') === 'v2') || false;

  const isMenuOpen = Boolean(profileAnchorEl);

  useEffect(() => {
    setActiveBendableInstances(currentActiveAuthorizedBendableInstances);
  }, [currentActiveAuthorizedBendableInstances]);

  const handleProfileMenuOpen = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const signOutUser = async () => {
    const apiResponse = await callApi('logout', null, null);
    console.log('AppNavBar.signOutUser() apiResponse: ', apiResponse);
    if (apiResponse && apiResponse.data && (apiResponse.data.status === 'success')) {
      console.log('AppNavBar about to sign out user...');
      auth.signout(() => {
        navigate(`/${uiVersion}/login`);
      });
    }
  };

  const handleMenuClose = (event) => {
    console.log('AppNavBar.handleMenuClose() event: ', event);
    const { identifier } = event.currentTarget.dataset;
    console.log('AppNavBar.handleMenuClose() identifier: ', identifier);
    console.log('AppNavBar.handleMenuClose() shouldUseHighContrast: ', shouldUseHighContrast);
    setProfileAnchorEl(null);

    if (identifier === 'signOut') signOutUser();

    if (identifier === "high-contrast") {
      const newState = _.cloneDeep(currentUserConfigCache);
      delete newState.isInited;
      _.set(newState, 'ui.high_contrast', !shouldUseHighContrast);
      dispatch(setUserConfig(newState));
    }
  };

  const handleNewMenuButtonOnSelect = (action) => {
    console.log('AppNavBar.handleNewMenuButtonOnSelect: ', action);
    if (action === 'newSpotlightSlide') {
      dispatch(resetCollectionWizardData({ tableName: 'spotlights', collectionType: 'spotlight', doingEdit: true }));
      navigate(`/${uiVersion}/spotlightSlides`);
    }
    if (action === 'newSpotlightCollection') {
      dispatch(resetCollectionWizardData({ tableName: 'v_spotlight_collections', collectionType: 'spotlight_collection', doingEdit: true }));
      navigate(`/${uiVersion}/spotlightCollections`);
    }
    if (action === 'newLearningObject') {
      dispatch(resetResourceWizardData());
      navigate(`/${uiVersion}/resourceWizard`);
    }
    if (action === 'newBulkResourceUpload') {
      dispatch(resetBulkResourceUpload());
      navigate(`/${uiVersion}/bulkResourceUpload`);
    }
    if (action === 'newProvider') {
      dispatch(resetProviderWizardData());
      navigate(`/${uiVersion}/providerWizard`);
    }
    if (action === 'newTag') {
      dispatch(resetTagWizardData());
      navigate(`/${uiVersion}/tagWizard`);
    }
    if (action === 'newTopic') {
      dispatch(resetTopicWizardData());
      navigate(`/${uiVersion}/topicWizard`);
    }
    if (action === 'newCategory') {
      dispatch(resetCategoryWizardData());
      navigate(`/${uiVersion}/categoryWizard`);
    }
    if (action === 'newTenant') {
      dispatch(resetTenantWizardData());
      navigate(`/${uiVersion}/tenantWizard`);
    }
    if (action === 'newCommunityCollection') {
      dispatch(resetCollectionWizardData({ tableName: 'community_collections', collectionType: 'community_collection' }));
      navigate(`/${uiVersion}/collections`);
    }
    if (action === 'newCareerCollection') {
      dispatch(resetCollectionWizardData({ tableName: 'career_collections', collectionType: 'career_collection' }));
      navigate(`/${uiVersion}/careerCollections`);
    }
    if (action === 'newUser') {
      dispatch(resetActiveUser());
      navigate(`/${uiVersion}/admin/user`);
    }
  };

  const handleViewMenuButtonOnSelect = (action) => {
    console.log('AppNavBar.handleViewMenuButtonOnSelect() action:', action);
    if (action === 'viewResources') {
      navigate(`/${uiVersion}/viewResources`);
    }
    if (action === 'viewProviders') {
      navigate(`/${uiVersion}/providersSummary`);
    }
    if (action === 'viewTags') {
      navigate(`/${uiVersion}/tagsSummary`);
    }
    if (action === 'viewTopics') {
      navigate(`/${uiVersion}/topicsSummary`);
    }
    if (action === 'viewTenants') {
      navigate(`/${uiVersion}/tenantsSummary`);
    }
    if (action === 'viewCategories') {
      navigate(`/${uiVersion}/categoriesSummary`);
    }
    if (action === 'viewSpotlightSlides') {
      navigate(`/${uiVersion}/spotlightSlidesSummary`);
    }
    if (action === 'viewSpotlightCollections') {
      navigate(`/${uiVersion}/spotlightCollectionsSummary`);
    }
    if (action === ROUTES.USER_INSIGHTS_DASHBOARD) {
      navigate(`/${uiVersion}/${ROUTES.USER_INSIGHTS_DASHBOARD}/stats`);
    }    
  };

  const handleInstanceMenuButtonChange = (activeInstances) => {
    console.log('AppNavBar.handleInstanceMenuButtonChange() activeInstances: ', activeInstances);
    authService.setActiveAuthorizedBendableInstances(activeInstances);    // update in authService, which does not get pushed updated from Redux
    dispatch(setActiveAuthorizedBendableInstances(activeInstances));      // update in Redux to propagate to UI elements that need it
  };

  const handleApiVersionChange = (event) => {
    // dispatch(setUserConfig({
    //   api_version: event.target.checked ? 'v2' : 'v1',
    // }));
    const newState = _.cloneDeep(currentUserConfigCache);
    delete newState.isInited;
    _.set(newState, 'api_version', event.target.checked ? 'v2' : 'v1');
    dispatch(setUserConfig(newState));    
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={profileAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        data-identifier="profile"
        onClick={handleMenuClose}
        divider={false}
        sx={{ paddingLeft: '5px' }}
      >
        <div style={{ width: '29px' }} />
        {authService.getFirstName() + ' ' + authService.getLastName()}{isDevelopment ? ` - ${authService.getUserId()}` : ''}
      </MenuItem>

      <MenuItem
        data-identifier="high-contrast"
        onClick={handleMenuClose}
        divider={true}
        sx={{ paddingLeft: '5px' }}
      >
        {shouldUseHighContrast ? <CheckIcon style={{ marginRight: '5px' }} /> : <div style={{ width: '29px' }} />} High contrast
      </MenuItem>


      {/* <MenuItem data-identifier="myAccount" onClick={handleMenuClose}>My account</MenuItem> */}

      <MenuItem
        data-identifier="signOut"
        onClick={handleMenuClose}
        sx={{ paddingLeft: '5px' }}
      >
        <div style={{ width: '29px' }} />
        Sign Out
      </MenuItem>
    </Menu>
  );


  return (
    <div className="appNavBarWrapper" style={{ height: '100%' }}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          elevation={0}
          color="transparent"
          sx={{
            paddingLeft: '40px',
            paddingRight: '40px',
            borderBottom: `1px solid ${theme.palette.primary['800']}`
          }}
        >
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="dashboard"
              sx={{ mr: 2 }}
              onClick={() => { navigate(`/${uiVersion}/dashboard`); }}
            >
              <HomeIcon sx={{ color: theme.palette.gray.medium }} />
            </IconButton>

            <NewMenuButton
              wrapperStyle={{ marginRight: '30px'}}
              onSelect={handleNewMenuButtonOnSelect}
            />

            <ViewMenuButton
              wrapperStyle={{ marginRight: '30px'}}
              onSelect={handleViewMenuButtonOnSelect}
            />

            {/* <Button
              color="primary"
              id="view-button"
              aria-controls="view"
              variant="outlined"
              disableElevation
              onClick={() => {navigate(`/${uiVersion}/viewResources`);}}
              // endIcon={<KeyboardArrowDownIcon />}
              // endIcon={<AddIcon />}
              sx={{
                width: '160px',
                borderRadius: '18px',
                '& .MuiSvgIcon-root': {
                  paddingBottom: '2px'
                },
                textTransform: 'none'
              }}
            >
              View Resources
            </Button> */}



            <Box sx={{ flexGrow: 1 }}>
              {/* { authService.getAuthorizedBendableInstances() } */}
            </Box>

            <Stack
              direction="row"
              alignItems="center"
              sx={{
                marginRight: '20px',
              }}
            >
              <Typography sx={{ color: theme.palette.primary.main }}>V1</Typography>
              <Switch checked={shouldUseApiV2}
                onChange={handleApiVersionChange}
                inputProps={{ 'aria-label': 'api version' }}
              />
              <Typography sx={{ color: theme.palette.primary.main }}>V2</Typography>
            </Stack>

            <InstanceMenuButton
              activeBendableInstances={activeBendableInstances}
              wrapperStyle={{ marginRight: '30px'}}
              onChange={handleInstanceMenuButtonChange}
            />

            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              {/* <div style={{
                display: configService.isDevelopment() ? 'block' : 'none',
                marginLeft: '10px',
                marginRight: '10px',
                marginTop: '20px',
                fontSize: '0.6rem'
              }}>{authService.getUserId() || 'none'}</div> */}

              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle
                  sx={{ color: authService.isAuthenticated ? theme.palette.primary.main : theme.palette.gray.medium }}
                />
              </IconButton>
            </Box>
            <Box sx={{
              display: { xs: authService.isAdmin() ? 'block' : 'none' },
              marginLeft: '10px'
            }}>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="dashboard"
                sx={{ mr: 2 }}
                onClick={() => { navigate(`/${uiVersion}/admin/users`); }}
              >
                <SettingsIcon sx={{ color: theme.palette.gray.medium }} />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {/* {renderMobileMenu} */}
        {renderMenu}
      </Box>
      {children}
    </div>
  );
}
