import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash-es';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import configService from '../../app/configService';
import resourceBackground from '../../assets/images/resource_container_card_bg.png';
import communityBackground from '../../assets/images/community_container_card_bg.png';
import careerBackground from '../../assets/images/career_container_card_bg.png';
import spotlightBackground from '../../assets/images/spotlight_container_card_bg.png';
import highContrastBackground from '../../assets/images/high_contrast_card_bg.png';

import {
  selectUserConfigCache,
} from '../../app/redux/configSlice';

import BinIcon from './BinIcon';



const getType = (value) => {
  if (value === 'learning_objects') return 'Resource';
  if (value === 'community_collections') return 'Community Collection';
  if (value === 'career_collections') return 'Career Collection';
  if (value === 'spotlights') return 'Spotlight';
  if (value === 'v_spotlight_collections') return 'Spotlight Containers';
  if (value === 'providers') return 'Provider';
  if (value === 'tag') return 'Tag';
  if (value === 'topic') return 'Topics';
  if (value === 'category') return 'Category';
  if (value === 'tenant') return 'Tenant';
};



const DbObjectContainerCard = (props) => {
  const { 
    item, 
    disabled = false, 
    onCardAction,             // handle click on card or on the context menu
  } = props;
  // console.log('DbObjectContainerCard item: ', item);

  const currentUserConfigCache = useSelector(selectUserConfigCache);

  const [cardTitle, setCardTitle] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('#ffffff');
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);

  const controllingInstance = configService.getBendableInstanceDisplayName(item?.controlling_instance);

  const isDraft = (item?.status === 'draft');
  const isSubmitted = (item?.status === 'submitted');
  const isBin = (item?.object_type === 'db_object_bin');


  useEffect(() => {    
    const shouldUseHighContrast = _.get(currentUserConfigCache, 'ui.high_contrast') || false;

    if (isBin) {
      // console.log('DbObjectContainerCard useEffect() isBin; item: ', item);
      if (item?.table_name === 'learning_objects') {
        setCardTitle(item?.title);
        setBackgroundColor(shouldUseHighContrast ? '#000000' : '#00B7E1');
        setBackgroundImage(shouldUseHighContrast ? highContrastBackground : resourceBackground);
      }
      if (item?.table_name === 'community_collections') {
        setCardTitle(item?.title);
        setBackgroundColor(shouldUseHighContrast ? '#000000' : '#E9AD21');
        setBackgroundImage(shouldUseHighContrast ? highContrastBackground : communityBackground);
      }
      if (item?.table_name === 'career_collections') {
        setCardTitle(item?.title);
        setBackgroundColor(shouldUseHighContrast ? '#000000' : '#E88160');
        setBackgroundImage(shouldUseHighContrast ? highContrastBackground : careerBackground);
      }
      if (item?.table_name === 'spotlights') {
        setCardTitle(item?.title);
        setBackgroundColor(shouldUseHighContrast ? '#000000' : '#E88160');
        setBackgroundImage(shouldUseHighContrast ? highContrastBackground : spotlightBackground);
      }
      if (item?.table_name === 'v_spotlight_collections') {
        setCardTitle(item?.title);
        setBackgroundColor(shouldUseHighContrast ? '#000000' : '#E88160');
        setBackgroundImage(shouldUseHighContrast ? highContrastBackground : spotlightBackground);
      }
      if (item?.table_name === 'providers') {
        setCardTitle(item?.title);
        setBackgroundColor(shouldUseHighContrast ? '#000000' : '#E88160');
        setBackgroundImage(shouldUseHighContrast ? highContrastBackground : spotlightBackground);
      }
      if (item?.table_name === 'tags') {
        setCardTitle(item?.title);
        setBackgroundColor(shouldUseHighContrast ? '#000000' : '#E88160');
        setBackgroundImage(shouldUseHighContrast ? highContrastBackground : spotlightBackground);
      }
      if (item?.table_name === 'topics') {
        setCardTitle(item?.title);
        setBackgroundColor(shouldUseHighContrast ? '#000000' : '#E88160');
        setBackgroundImage(shouldUseHighContrast ? highContrastBackground : spotlightBackground);
      }
      if (item?.table_name === 'categories') {
        setCardTitle(item?.title);
        setBackgroundColor(shouldUseHighContrast ? '#000000' : '#E88160');
        setBackgroundImage(shouldUseHighContrast ? highContrastBackground : spotlightBackground);
      }
      if (item?.target_object_type === 'tenant') {
        setCardTitle(item?.title);
        setBackgroundColor(shouldUseHighContrast ? '#000000' : '#E88160');
        setBackgroundImage(shouldUseHighContrast ? highContrastBackground : spotlightBackground);
      }

    } else {
      if (item?.table_name === 'learning_objects') {
        setCardTitle(item?.raw_data?.resourceCard?.title);
        setBackgroundColor(shouldUseHighContrast ? '#000000' : '#00B7E1');
        setBackgroundImage(shouldUseHighContrast ? highContrastBackground : resourceBackground);
      }
      if (item?.table_name === 'community_collections') {
        setCardTitle(item?.raw_data?.collectionCard?.title);
        setBackgroundColor(shouldUseHighContrast ? '#000000' : '#E9AD21');
        setBackgroundImage(shouldUseHighContrast ? highContrastBackground : communityBackground);
      }
      if (item?.table_name === 'career_collections') {
        setCardTitle(item?.raw_data?.collectionCard?.title);
        setBackgroundColor(shouldUseHighContrast ? '#000000' : '#E88160');
        setBackgroundImage(shouldUseHighContrast ? highContrastBackground : careerBackground);
      }
      if (item?.table_name === 'spotlights') {
        setCardTitle(item?.raw_data?.collectionCard?.headline);
        setBackgroundColor(shouldUseHighContrast ? '#000000' : '#E88160');
        setBackgroundImage(shouldUseHighContrast ? highContrastBackground : spotlightBackground);
      }
      if (item?.table_name === 'v_spotlight_collections') {
        setCardTitle(item?.raw_data?.collectionCard?.title);
        setBackgroundColor(shouldUseHighContrast ? '#000000' : '#E88160');
        setBackgroundImage(shouldUseHighContrast ? highContrastBackground : spotlightBackground);
      }
      if (item?.table_name === 'providers') {
        setCardTitle(item?.raw_data?.title);
        setBackgroundColor(shouldUseHighContrast ? '#000000' : '#E88160');
        setBackgroundImage(shouldUseHighContrast ? highContrastBackground : spotlightBackground);
      }
      if (item?.table_name === 'tags') {
        setCardTitle(item?.raw_data?.name);
        setBackgroundColor(shouldUseHighContrast ? '#000000' : '#E88160');
        setBackgroundImage(shouldUseHighContrast ? highContrastBackground : spotlightBackground);
      }
      if (item?.table_name === 'topics') {
        setCardTitle(item?.raw_data?.name);
        setBackgroundColor(shouldUseHighContrast ? '#000000' : '#E88160');
        setBackgroundImage(shouldUseHighContrast ? highContrastBackground : spotlightBackground);
      }
      if (item?.table_name === 'categories') {
        setCardTitle(item?.raw_data?.name);
        setBackgroundColor(shouldUseHighContrast ? '#000000' : '#E88160');
        setBackgroundImage(shouldUseHighContrast ? highContrastBackground : spotlightBackground);
      }
      if (item?.target_object_type === 'tenant') {
        setCardTitle(item?.raw_data?.tenant);
        setBackgroundColor(shouldUseHighContrast ? '#000000' : '#E88160');
        setBackgroundImage(shouldUseHighContrast ? highContrastBackground : spotlightBackground);
      }

    }
  }, [item, isBin, currentUserConfigCache]);


  const handleClick = async (event) => {
    console.log('DbObjectContainerCard.handleClick() called...');
    event.stopPropagation();
    if (disabled) return;

    onCardAction({
      action: 'click',
      item: item,
    });
  };


  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null,
    );
  };

  const handleContextMenuClose = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setContextMenu(null);
    console.log('DbObjectContainerCard.handleContextMenuClose() event.target.id: ', event.target.id);
    if (event.target.id === 'delete') {
      onCardAction({ action: 'delete', target: item._id, objectType: item.object_type, title: cardTitle });
    }
    if (event.target.id === 'resetToDraft') {
      onCardAction({ action: 'resetToDraft', target: item._id, objectType: item.object_type, title: cardTitle });
    }
  };

  return (
    <Card
      onClick={handleClick}
      onContextMenu={handleContextMenu}
      sx={{
        position: 'relative',
        width: '215px',
        maxWidth: '215px',
        height: '127px',
        maxHeight: '127px',
        padding: '8px',
        borderRadius: '11px',
        // backgroundColor: backgroundColor,
        color: '#ffffff',
        cursor: disabled ? 'inherit' : 'pointer',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat', 
        backgroundPosition: 'center', 
        backgroundImage: backgroundImage ? `url(${backgroundImage})` : ''
      }}
    >
      <Box display="flex" flexDirection="row">

        <Box flexGrow={1} sx={{ marginBottom: '5px' }}>
          <Typography color="#ffffff" fontSize={'0.8rem'} fontWeight="bold">
            {getType(item?.target_object_type || item?.table_name )}
          </Typography>
        </Box>
        <Box>
          <Typography color="#ffffff" fontSize={'0.8rem'}>
            { isDraft ? 'Draft' : ''}
            { isSubmitted ? 'In progress' : ''}
          </Typography>          
        </Box>
      </Box>

      <Box display="flex" flexDirection="row" sx={{ marginBottom: '5px' }}>
        <Box>
          <div
            style={{
              color: '#ffffff',
              fontWeight:"bold",
              fontSize: "1.2rem",
              lineHeight: "1.2rem",
              maxHeight: "58px",
              maxWidth: '195px',
              wordBreak: 'break-word',
            }}
          >
            <Box element="div" sx={{
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 3
            }}>
              {cardTitle}
            </Box>
          </div>
        </Box>
        <Box>
        </Box>
      </Box>

      {/* <Box display="flex" flexDirection="row" sx={{ display: configService.isDevelopment() ? 'block' : 'none' }}>
        <Box>
          <Typography color="#ffffff" fontWeight="normal" fontSize="0.6rem">
            ID: {item?._id}
          </Typography>
        </Box>
        <Box>
        </Box>
      </Box>

      <Box display="flex" flexDirection="row" sx={{ display: configService.isDevelopment() ? 'block' : 'none' }}>
        <Box>
          <Typography color="#ffffff" fontWeight="normal" fontSize="0.6rem">
            Bin ID: {item?.bin_id}
          </Typography>
        </Box>
        <Box>
        </Box>
      </Box> */}

      <Box sx={{ position: 'absolute', bottom: '8px', left: '8px' }}>
        <Typography color="#ffffff" fontWeight="normal" fontSize="0.8rem">
          {controllingInstance}
        </Typography>
      </Box>

      { isBin && (item?.document_count !== 1) &&
        <BinIcon iconWidth="36px" textColor={backgroundColor} itemCount={item?.document_count} sx={{ position: 'absolute', bottom: '2px', right: '8px' }} />
      }

      <Menu
        open={contextMenu !== null}
        onClose={handleContextMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem id="resetToDraft" onClick={handleContextMenuClose} sx={{ opacity: '1.0' }}>Reset to draft</MenuItem>
        <MenuItem id="delete" onClick={handleContextMenuClose}>Delete</MenuItem>
        {/* <MenuItem id="test" onClick={handleContextMenuClose}>Test</MenuItem> */}
      </Menu>

    </Card>
  );
};

export default DbObjectContainerCard;