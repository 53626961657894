import React, { useState, useEffect } from "react";
import Modal from 'react-modal';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import Stack from '@mui/material/Stack';

const GenericModal = (props) => {
  const {
    children,
    isOpen,
    cancelLabel,
    confirmLabel,
    secondaryConfirmLabel,
    onClose,
    width='50%',
    height='50%',
    noPadding=false,
    shouldCloseOnOverlayClick=false,
    options = {},
  } = props;

  const [modalIsOpen, setModalIsOpen] = useState(props.isOpen);
  // console.log('GenericModal props.isOpen', props.isOpen);

  const modalStyles = {
    content: {
      width: width,
      height: height,
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
    overlay: {
      zIndex: 2000
    }
  };
  

  useEffect(() => {
    setModalIsOpen(isOpen);
  }, [isOpen]);

  const afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  };

  const modalCloseHandler = (data) => {
    setModalIsOpen(false);
    console.log('genericModal.modalCloseHandler() data: ', data);
    if (onClose) onClose(data);
  };

  return (
    <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={modalCloseHandler}
        style={modalStyles}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      >
        <Box
          component="div"
          sx={{
            display: noPadding ? 'none' : 'block',
            width: '100%',
            height: '20px',
          }}
        />

        {children}

        <Box
          component="div"
          sx={{
            position: 'absolute',
            bottom: 20,
            right: 20,
          }}
        >
          <Stack
            direction="row"
          >
            <Button
              color="primary"
              id="cancel-button"
              variant={options.cancelLabelVariant ? options.cancelLabelVariant : "outlined"}
              disableElevation
              onClick={() => modalCloseHandler('cancel')}
              sx={{
                display: cancelLabel ? 'block' : 'none',
                borderRadius: '18px', 
                textTransform: 'none',
                minWidth: '100px',
                marginLeft: '20px'
              }}
            >
              {cancelLabel}
            </Button>

            <Button
              color="primary"
              id="confirm-button"
              variant={options.confirmLabelVariant ? options.confirmLabelVariant : "contained"}
              disableElevation
              onClick={() => modalCloseHandler('confirm')}
              sx={{
                display: confirmLabel ? 'block' : 'none',
                borderRadius: '18px', 
                textTransform: 'none',
                minWidth: '100px',
                marginLeft: '20px'
              }}
            >
              {confirmLabel}
            </Button>

            <Button
              color="primary"
              id="secondary-confirm-button"
              variant={options.secondaryConfirmLabelVariant ? options.secondaryConfirmLabelVariant : "contained"}
              disableElevation
              onClick={() => modalCloseHandler('secondary-confirm')}
              sx={{
                display: secondaryConfirmLabel ? 'block' : 'none',
                borderRadius: '18px', 
                textTransform: 'none',
                minWidth: '100px',
                marginLeft: '20px'
              }}
            >
              {secondaryConfirmLabel}
            </Button>

          </Stack>
        </Box>

        <Box
          component="div"
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            display: cancelLabel ? 'none' : 'block',
          }}
        >
          <IconButton aria-label="close" onClick={() => modalCloseHandler('close')}>
            <CancelIcon />
          </IconButton>
        </Box>

      </Modal>
  );
};

export default GenericModal;