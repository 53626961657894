import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash-es';
import { AgGridReact } from "ag-grid-react";
// import 'ag-grid-enterprise';

import ResourceShellsSummaryNavBar from "../components/resourceShells/ResourceShellsSummaryNavBar";

import DbObjectChangeSetStatusRenderer from '../components/agGridRenderers/DbObjectChangeSetStatusRenderer';
import TimestampRenderer from "../components/agGridRenderers/TimestampRenderer";

import { 
  getDbObjectShells,
  selectCurrentLearningObjectShells,
} from "../app/createPageSlice";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import { useAuth } from '../app/AuthProvider';

const ResourceShellsSummaryPage = () => {
  const auth = useAuth();
  const dispatch = useDispatch();

  const learningObjectShells = useSelector(selectCurrentLearningObjectShells);
  console.log('ResourceShellsSummaryPage learningObjectShells: ', learningObjectShells);

  const [hasDoneInitialLoad, setHasDoneInitialLoad] = useState(false);
  const [transformedLearningObjectShells, setTransformedLearningObjectShells] = useState([]);
  const [gridApi, setGridApi] = useState(null);

  /**
   * if !hasDoneInitialLoad, attempt to fetch learning object (resource) shells
   */
  useEffect(() => {
    if (!hasDoneInitialLoad) {
      setHasDoneInitialLoad(true);
      dispatch(getDbObjectShells({ tableName: 'learning_objects', submitted_by_id: auth.getUserId() }));
    }
  }, [hasDoneInitialLoad, dispatch, auth]);


  /**
   * if !hasDoneInitialLoad, attempt to fetch learning object (resource) shells
   */
  useEffect(() => {
    let transformedShells = [];
    if (learningObjectShells) {
      transformedShells = _.map(learningObjectShells, (shellObj) => {
        return {
          _id: shellObj._id,
          bendable_instance: shellObj.bendable_instance,
          title: shellObj?.raw_data?.resourceCard?.title || '',
          url: shellObj?.raw_data?.resourceCard?.url || '',
          updated_at: shellObj.updated_at,
          status: shellObj.status
        }
      });
    }
    setTransformedLearningObjectShells(transformedShells);
  }, [learningObjectShells]);

  const frameworkComponents = {
    dbObjectChangeSetStatusRenderer: DbObjectChangeSetStatusRenderer,
    timestampRenderer: TimestampRenderer,
  };

  let columnDefs = [
    // TODO: bendable_instance should be optional if the user only has access to a single instance
    {
      field: 'bendable_instance',
      headerName: 'Instance',
      width: 200,
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 400,
    },
    {
      field: 'url',
      headerName: 'URL',
      width: 400,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 200,
    },
    {
      field: 'updated_at',
      headerName: 'Last Update',
      cellRenderer: 'timestampRenderer',
      cellRendererParams: { format: 'M/d/yyyy h:mma' },
      width: 200,
    },

  ];


  const selectionChangedHandler = () => {
    const selectedRows = gridApi.getSelectedRows();
    console.log('ResourceShellsSummaryPage.selectionChangedHandler() selectedRows: ', selectedRows);
    if (_.isArray(selectedRows) && selectedRows.length) {
      // dispatch(setActiveTemplate({ template: selectedRows[0] }));
      // navigate('/admin/templates/d');
    }
  };


  return (
    <div className="lazy-page-wrapper">
      <ResourceShellsSummaryNavBar 
        saveDisabled={false} 
        submitDisabled={true}
      >
        <div className="ag-theme-alpine" style={{ height: "calc(100% - 65px)", width: "100%", padding: '25px 20px' }}>

          <AgGridReact
            columnDefs={columnDefs}
            frameworkComponents={frameworkComponents}
            // immutableData={true}             // for use with Redux
            getRowNodeId={data => data._id}
            rowData={transformedLearningObjectShells}
            rowSelection="single"
            onSelectionChanged={selectionChangedHandler}
            // enableRangeSelection={true}      // enterprise
            // enableFillHandle={true}          // enterprise
            undoRedoCellEditing={true}
            undoRedoCellEditingLimit={20}
            enableCellChangeFlash={true}
            onGridReady={(params) => {
              //console.log("params: ", params);
              //console.log("params.api: ", params.api);
              //console.log('Has getSelectedNodes in onGridReady: ', !!params.api.getSelectedNodes);
              setGridApi(params.api);
              params.api.showNoRowsOverlay();
            }}
          />

        </div>
      </ResourceShellsSummaryNavBar>
    </div>
  );
};

export default ResourceShellsSummaryPage;

