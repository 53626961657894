import React from 'react';
import clsx from 'clsx';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';


import styles from './default.module.css';
import { FileDropTarget } from './FileDropTarget';

const FileUpload = (props) => {
  const { disabled, onFileChange } = props;
  const cssOverride = props.cssModule || {};

  const fileChangeHandler = (file) => {
    console.log('FileUpload.fileChangeHandler() file: ', file);
    // setFileToUpload(file);
    onFileChange(file);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div 
        className={clsx(styles.wrapper, cssOverride.wrapper)}
      >
        <FileDropTarget
          cssModule={cssOverride}
          disabled={disabled}
          onFileChange={fileChangeHandler}
        />
      </div>
    </DndProvider>
  );
};

export default FileUpload;