// ConfigService

import _ from 'lodash-es';

import messageBus from './messageBus';

let isInited = false;
let configCache = {};

const configService = {
  
  getIsInited: () => (isInited),

  // init: async () => {
  //   console.log('configService.init() called...');
  //   if (isInited) return;

  //   try {
  //     const frontendConfigResponse = await callApi('getFrontendConfig', null, { params: {} });
  //     console.log('configService.init() frontendConfigResponse: ', frontendConfigResponse);
  //     if (frontendConfigResponse?.data?.payload) {
  //       configCache = _.merge(configCache, frontendConfigResponse?.data?.payload);
  //       messageBus.publish(messageBus.CONFIG_SERVICE_FRONTEND_CONFIG_CHANGED_EVENT);
  //     }

  //     const userConfiguationResponse = await callApi('getConfigurationForUser', null, { params: {} });
  //     console.log('configService.init() userConfiguationResponse: ', userConfiguationResponse);
  //     if (userConfiguationResponse?.data?.payload) {
  //       configCache = _.merge(configCache, userConfiguationResponse?.data?.payload);
  //       messageBus.publish(messageBus.CONFIG_SERVICE_USER_CONFIG_CHANGED_EVENT);
  //     }

  //     isInited = true;
  //     console.log('configService.init() complete; configCache: ', configCache);
  //   } catch (err) {
      
  //   }
  // },

  setFrontendConfig: (frontendConfig) => {
    configCache = _.merge(configCache, frontendConfig);
    console.log('configService.setFrontendConfig() configCache: ', configCache);
    messageBus.publish(messageBus.CONFIG_SERVICE_FRONTEND_CONFIG_CHANGED_EVENT);
  },

  setUserConfig: (userConfig) => {
    configCache = _.merge(configCache, userConfig);
    messageBus.publish(messageBus.CONFIG_SERVICE_USER_CONFIG_CHANGED_EVENT);
  },

  isDevelopment: () => {
    return configCache.is_development;
  },

  getAlgoliaPrefix: () => {
    console.log('configService.getAlgoliaPrefix() configCache.algolia_index_prefix: ', configCache.algolia_index_prefix);
    return configCache.algolia_index_prefix;
  },

  getAlgoliaPrimaryUserSearchIndexName: () => {
    return configCache.algolia_index_prefix + 'primary_user_search';
  },

  getBendableInstanceDisplayName: (instanceKey) => {
    // console.log('configService.getBendableInstanceDisplayName() instanceKey: ', instanceKey);
    // console.log('configService.getBendableInstanceDisplayName() configCache: ', configCache);
    const target = _.find(configCache?.bendable_instances, (instanceData) => {
      return (instanceData.bendable_instance === instanceKey);
    });
    return target?.display_name;
  },

  getConfigValue: (path) => {
    return _.get(configCache, path);
  },

  setConfigValue: (path, value) => {
    _.set(configCache, path, value);
    // TODO: persist this
  },

};

// configService.init();

export default configService;
