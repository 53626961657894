import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import authService from '../../app/authService';
import callApi from '../../app/apiService';

const useStyles = makeStyles({
  pill: {
    width: 40,
    height: 24,
    
    textAlign: 'center',
    
    borderRadius: 12,
    padding: 0,
    marginTop: 7
  },
  needsReview: {
    backgroundColor: '#A21212',
  },
  doesNotNeedReview: {
    backgroundColor: '#5AA212'
  },
  pillContents: {
    fontSize: '1rem',
    lineHeight: '1.5em',
    color: '#ffffff',
    fontWeight: 'bold',
  }
});

const DbObjectChangeSetStatusRenderer = (props) => {
  // console.log('DbObjectChangeSetStatusRenderer props: ', props);
  const classes = useStyles();
  const [documentsNeedingReviewCount, setDocumentsNeedingReviewCount] = useState(null);

  useEffect(() => {
    const fetchSummary = async () => {
      // fetch status for changeSetId
      const options = {
        params: {
          roles: authService.getRoles(),
          changeSetId: props.value
        }
      };
      console.log('DbObjectChangeRequestSummary.getDbChangesToModerate() options: ', options);
      const response = await callApi('getDbObjectChangesSummary', null, options);
      console.log('DbObjectChangeRequestSummary.getDbChangesToModerate() response: ', response);
      if (response && response.data && response.data.status === 'success') {
        const payload = response.data.payload;
        const count = payload && payload.dbObjectChangesSummary && payload.dbObjectChangesSummary.documentsNeedingReviewCount;
        setDocumentsNeedingReviewCount(count);
      }
    };
    fetchSummary();
  }, [props.value]);

  return (
    <div className={clsx(classes.pill, { [classes.needsReview]: documentsNeedingReviewCount, [classes.doesNotNeedReview]: !documentsNeedingReviewCount })}>
      <div className={classes.pillContents}>{documentsNeedingReviewCount}</div>
    </div>
  );
};

export default DbObjectChangeSetStatusRenderer;