/**
 * Redux Store slice to hold Dashboard data
 */

import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
// import _ from 'lodash-es';
import callApi from './apiService';

const initialState = {
  currentSearch: '',
  status: 'idle',
};


export const bulkResourcesSlice = createSlice({
  name: 'bulkResources',
  initialState,
  reducers: {
    setCurrentSearch: (state, action) => {
      console.log('bulkResourcesSlice setCurrentSearch; state, action: ', current(state), action);
      state.currentSearch = action.payload;
      console.log('bulkResourcesSlice setCurrentSearch; state, action: ', current(state), action);
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //   .addCase(getBulkResourcesDbObjectBins.pending, (state) => {
  //     console.log('bulkResourcesSlice getBulkResourcesDbObjectBins.pending state: ', current(state));
  //     state.status = 'loading';
  //   })
  //   .addCase(getBulkResourcesDbObjectBins.fulfilled, (state, action) => {
  //     state.status = 'idle';
  //     console.log('bulkResourcesSlice getBulkResourcesDbObjectBins.fulfilled action: ', action);
  //     if (action.payload.dbObjectBins && (action.payload.dbObjectBins.length === 1)) {
  //       state.currentDbObjectBin = action.payload.dbObjectBins[0];
  //       state.currentDbObjectShells = action.payload.dbObjectShells;
  //     }
  //     console.log('bulkResourcesSlice getBulkResourcesDbObjectBins.fulfilled state: ', current(state));
  //   });
  // },

});

// export const { 
//   mergeCreateWizardChanges,
// } = bulkResourcesSlice.actions;

// for useSelector; state is complete store, not slice
export const selectCurrentSearch = (state) => state.bulkResources.currentSearch;

export default bulkResourcesSlice.reducer;
