/**
 * Redux Store slice to hold instance-specific data items such as "providers" where
 * the _pg__id values are specific to the target instance. When using useSelector,
 * all data for a given type (ie. providers) will be returned. Use the top-level
 * object field equal to the Bendable Instance value to get only the objects specific 
 * to a given instance.
 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import _ from 'lodash-es';
import callApi from './apiService';

const initialState = {
  'bendable-staging-1': {},         // example of instance data subdoc
  status: 'idle',
};


const fetchPostgresTable = async (options) => {
  console.log('instanceDataSlice.fetchPostgresTable() options: ', options);
  options.limit = options.limit || 'all';

  try {
    if (!options.targetInstance) throw new Error('Missing targetInstance');
    const response = await callApi('postgresTableRowsForInstance', null, { params: options });
    console.log('instanceDataSlice.fetchPostgresTable() response: ', response);
    return response.data;   // axios attribute
  } catch (error) {
    console.error('instanceDataSlice.fetchPostgresTable() error: ', error.message);
  }
};

export const getInstanceData = createAsyncThunk(
  'instanceData/getInstanceData',
  async (options) => {
    const response = await fetchPostgresTable(options);
    return { 
      tableName: options.tableName, 
      tableNameSuffix: options.tableNameSuffix, 
      rows: response.payload,
      targetInstance: options.targetInstance
    };
  }
);

export const instanceDataSlice = createSlice({
  name: 'instanceData',
  initialState,
  reducers: {
    // mergeCreateWizardChanges: (state, action) => {
    //   console.log('instanceDataSlice mergeCreateWizardChanges; state, action: ', current(state), action);
    //   state.currentCreateWizardPage = _.merge(state.currentCreateWizardPage, action.payload);
    //   console.log('instanceDataSlice mergeCreateWizardChanges; state, action: ', current(state), action);
    // },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getInstanceData.pending, (state) => {
      console.log('instanceDataSlice getInstanceData.pending state: ', state);
      state.status = 'loading';
    })
    .addCase(getInstanceData.fulfilled, (state, action) => {
      state.status = 'idle';
      console.log('instanceDataSlice getInstanceData.fulfilled action: ', action);
      state[action.payload.targetInstance] = state[action.payload.targetInstance] || {};
      let fullTableKey = action.payload.tableName;    // store rows under combined key of tableName and tableNameSuffix to handle tables like 'categories' and 'tags' that have subtypes
      if (action.payload.tableNameSuffix) fullTableKey = fullTableKey + action.payload.tableNameSuffix;
      state[action.payload.targetInstance][fullTableKey] = action.payload.rows;
    });

},
});

// export const { 
//   mergeCreateWizardChanges,
// } = instanceDataSlice.actions;

// for useSelector; state is complete store, not slice
export const selectAllInstanceData = (state) => state.instanceData;
// export const selectProviders = (state) => state.instanceData.providers;
// export const selectTags = (state) => state.instanceData.tags;
// export const selectCategories = (state) => state.instanceData.categories;

// export const selectProvidersByInstance = instanceName => state => {
//   return state.instanceData[instanceName];
// };

export default instanceDataSlice.reducer;
