import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {useWindowSize} from 'react-use';

import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Slide from '@mui/material/Slide';

import RefreshIcon from '@mui/icons-material/Refresh';
// import GridIcon from '@mui/icons-material/GridView';
import TableIcon from '@mui/icons-material/TableRows';


import authService from '../../app/authService';
import configService from '../../app/configService';
import messageBus from '../../app/messageBus';
import DbObjectContainerCard from './DbObjectContainerCard';
import DbObjectContainerCardSmall from './DbObjectContainerCardSmall';

import { 
  getDbObjectBins,
  selectOwnDraftDbObjectContainers,
  selectCanApproveSubmittedDbObjectContainers,
  selectOwnAssignedDbObjectContainers,
} from '../../app/dashboardSlice';

import {
  selectActiveAuthorizedBendableInstances,
} from '../../app/redux/authSlice';


const largeCardParams = {
  widgetHeight: 200,
  firstItemPos: 70,
  containerCardWidth: 215,
  containerCardHeight: 127,
  cardGutter: 24,
  leftChevronTop: 22,
  rightChevronTop: 62
};

const smallCardParams = {
  widgetHeight: 130,
  firstItemPos: 70,
  containerCardWidth: 215,
  containerCardHeight: 70,
  cardGutter: 24,
  leftChevronTop: -15,
  rightChevronTop: 22
};


const DashboardDbObjectContainersRow = (props) => {
  const {
    config,
    onCardAction,
    animate = false
  } = props;

  const { 
    ownDrafts, 
    canApproveSubmitted, 
    ownAssigned,
    cardType = 'large',
    // sort = 'none',
    // sortDirection = 'asc'
  } = config;

  const { width: windowWidth } = useWindowSize();
  const containerRef = useRef();

  const dispatch = useDispatch();
  const currentActiveAuthorizedBendableInstances = useSelector(selectActiveAuthorizedBendableInstances);

  const c = (cardType === 'large') ? largeCardParams : smallCardParams;     // set up constants
  const cardsPerScreen = Math.floor(windowWidth / (c.containerCardWidth + c.cardGutter)) -1;   // add gutter to card width

  const ownDraftDbObjectContainers = useSelector(selectOwnDraftDbObjectContainers);
  const canApproveSubmittedDbObjectContainers = useSelector(selectCanApproveSubmittedDbObjectContainers);
  const ownAssignedDbObjectContainers = useSelector(selectOwnAssignedDbObjectContainers);

  const [items, setItems] = useState([]);
  const [firstItemIndex, setFirstItemIndex] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [shouldAnimate, setShouldAnimate] = useState(animate);
  const [slideDirection, setSlideDirection] = useState('left');
  const [shouldSlideIn, setShouldSlideIn] = useState(true);
  const [mouseInCard, setMouseInCard] = useState(false);    
  const [refresh, setRefresh] = useState(0);      // used to trigger a refresh of the component
  const [storeKey, setStoreKey] = useState(null);

  const triggerRefresh = useCallback(() => {
    // console.log('DashboardDbObjectContainersRow.triggerRefresh() called...');
    setRefresh(Date.now());
  }, []);

  // set up subscriptions
  useEffect(() => {
    // console.log('DashboardDbObjectContainersRow useEffect messageBus subscribe...');
    messageBus.subscribe(messageBus.DASHBOARD_DB_OBJECT_CONTAINERS_CHANGED_EVENT, triggerRefresh);

    return () => {
      messageBus.unsubscribe(messageBus.DASHBOARD_DB_OBJECT_CONTAINERS_CHANGED_EVENT, triggerRefresh);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // fire only on load and unload


  // useEffect(() => {
  //   if (ownDrafts) {
  //     // console.log('DashboardDbObjectContainersRow config.ownDrafts called; ownDrafts : ', ownDrafts);
  //     setStoreKey('ownDraftDbObjectShells');
  //     dispatch(getDbObjectShells({
  //       submittedById: authService.getUserId(),
  //       status: 'draft',
  //       storeKey: 'ownDraftDbObjectShells'
  //     }));
  //   }
  // }, [refresh, dispatch, ownDrafts]);

  useEffect(() => {
    if (ownDrafts) {
      // console.log('DashboardDbObjectContainersRow config.ownDrafts called; ownDrafts : ', ownDrafts);
      setStoreKey('ownDraftDbObjectContainers');
      dispatch(getDbObjectBins({
        submittedById: authService.getUserId(),
        status: 'draft',
        storeKey: 'ownDraftDbObjectContainers'
      }));
    }
  }, [refresh, dispatch, ownDrafts]);


  useEffect(() => {
    if (canApproveSubmitted) {
      // console.log('DashboardDbObjectContainersRow config.canApproveSubmitted called; canApproveSubmitted : ', canApproveSubmitted);
      setStoreKey('canApproveSubmittedDbObjectContainers');
      dispatch(getDbObjectBins({
        filterByNeedsApproval: true,                    // only show items that need approval
        hasApproverUserId: false,                       // only objects that don't already have an approver user ID
        status: 'submitted',
        storeKey: 'canApproveSubmittedDbObjectContainers'
      }));
    }
  }, [refresh, dispatch, canApproveSubmitted]);


  useEffect(() => {
    if (ownAssigned) {                       // TODO: add in Resources with Bins
      // console.log('DashboardDbObjectContainersRow config.ownAssigned called; refresh : ', refresh);
      setStoreKey('ownAssignedDbObjectContainers');
      dispatch(getDbObjectBins({
        assignedToId: authService.getUserId(),
        status: 'submitted',
        storeKey: 'ownAssignedDbObjectContainers'
      }));
    }
  }, [refresh, dispatch, ownAssigned]);


  // const sortShellItems = useCallback((items) => {
  //   let newItems = _.map(items, (shellObj, index) => {
  //     let newShellObj = _.cloneDeep(shellObj);
  //     newShellObj.sort = index;     // use original order from Mongo
  //     if (sort === 'createdAt') newShellObj.sort = newShellObj.created_at || 0;
  //     if (sort === 'updatedAt') newShellObj.sort = newShellObj.updated_at || 0;
  //     if (sort === 'editorUpdatedAt') newShellObj.sort = newShellObj.editorial_approver_last_update_at || 0;
  //     if (sort === 'operatorUpdatedAt') newShellObj.sort = newShellObj.operational_approver_last_update_at || 0;
  //     return newShellObj;
  //   });
  //   const sortedItems = _.orderBy(newItems, ['sort'], [sortDirection]);
  //   // console.log('DashboardDbObjectContainersRow useEffect() sortedItems: ', sortedItems);
  //   return sortedItems;
  // }, [sort, sortDirection]);


  // useEffect(() => {
  //   if (ownDrafts) {
  //     // console.log('DashboardDbObjectContainersRow useEffect() ownDraftDbObjectShells: ', ownDraftDbObjectShells);
  //     const sortedItems = sortShellItems(ownDraftDbObjectShells);
  //     setItems(sortedItems);
  //   }
  // }, [ownDraftDbObjectShells, ownDrafts, sortShellItems]);

  useEffect(() => {
    if (ownDrafts) {
      // console.log('DashboardDbObjectContainersRow useEffect() ownDraftDbObjectContainers: ', ownDraftDbObjectContainers);
      setItems(ownDraftDbObjectContainers);
    }
  }, [ownDraftDbObjectContainers, ownDrafts]);

  useEffect(() => {
    if (canApproveSubmitted) {
      // console.log('DashboardDbObjectContainersRow useEffect() canApproveSubmittedDbObjectContainers: ', canApproveSubmittedDbObjectContainers);
      setItems(canApproveSubmittedDbObjectContainers);
    }
  }, [canApproveSubmittedDbObjectContainers, canApproveSubmitted]);

  useEffect(() => {
    if (ownAssigned) {
      // console.log('DashboardDbObjectContainersRow useEffect() ownAssignedDbObjectContainers: ', ownAssignedDbObjectContainers);
      setItems(ownAssignedDbObjectContainers);
    }
  }, [ownAssignedDbObjectContainers, ownAssigned]);



  const onMouseOverHandler = () => {
    // // console.log('DashboardDbObjectContainersRow.onMouseOverHandler() called...');
    setMouseInCard(true);
  };
  const onMouseOutHandler = () => {
    setMouseInCard(false);
  };

  const handleToggleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    props.onToggle({ show: 'table' });
  };

  const refreshData = (event) => {   // force getOwnDbObjectShells() to fire
    event.preventDefault();
    event.stopPropagation();
    setRefresh(Date.now());
  };

  const handleShowPreviousItems = () => {
    if (shouldAnimate) {    // TODO: animation needs improvment
      setSlideDirection(() => ('left'));
      setShouldSlideIn(() => (false));
      setTimeout(() => {
        setSlideDirection(() => ('right'));
        setTimeout(() => {    
          setFirstItemIndex((currentFirstItemIndex) => {
            return Math.max(currentFirstItemIndex - cardsPerScreen, 0);
          });
          setShouldSlideIn(() => (true));
        }, 50); // give time for setSlideDirection('left') to propagate
      }, 250);
    } else {
      setFirstItemIndex((currentFirstItemIndex) => {
        return Math.max(currentFirstItemIndex - cardsPerScreen, 0);
      });
    }
  };
  
  const handleShowNextItems = () => {
    if (shouldAnimate) {    // TODO: animation needs improvment
      setSlideDirection(() => ('right'));
      setShouldSlideIn(() => (false));
      setTimeout(() => {
        setSlideDirection(() => ('left'));
        setTimeout(() => {
          setFirstItemIndex((currentFirstItemIndex) => {
            return Math.min(currentFirstItemIndex + cardsPerScreen, items.length);
          });
          setShouldSlideIn(() => (true));
        }, 50); // give time for setSlideDirection('left') to propagate
      }, 250);
    } else {
      setFirstItemIndex((currentFirstItemIndex) => {
        return Math.min(currentFirstItemIndex + cardsPerScreen, items.length);
      });
    }
  };
  
  /**
   * Intercept action from card so that we can augment the options with the Redux storeKey that needs to be changed
   * @param {} options 
   */
  const handleCardAction = (options) => {
    console.log('DashboardDbObjectContainersRow.handleCardAction() options: ', options);
    options.storeKey = storeKey;              // get key that was set on init
    options.config = config;                  // augment with current config
    onCardAction(options);
  };


  const humanReadableActiveBendableInstances = _.map(currentActiveAuthorizedBendableInstances, (instance) => {
    return configService.getBendableInstanceDisplayName(instance);
  });

  return (
    <Box
      sx={{ 
        width: '100%',
        height: `${c.widgetHeight}px`,
        borderBottom: '1px solid #EADFCE',
        minHeight: 100,
        backgroundColor: '#F9F4EC',
        position: 'relative',
        overflow: 'hidden',
      }}

      // onMouseEnter={onMouseOverHandler}
      onMouseOver={onMouseOverHandler}
      onMouseLeave={onMouseOutHandler}
    >

      <Box display="flex" flexDirection="row" sx={{ padding: `8px ${c.firstItemPos}px 0px ${c.firstItemPos}px` }}>
            
          <Box flexGrow={1}>
            <Typography 
              sx={{ 
                fontSize: '16px', 
                fontWeight: 'bold',
                fontFamily: 'Sharp Sans', 
              }} 
              color="textSecondary" 
              gutterBottom
            >
            { ownDrafts && `Things you're working on from ${humanReadableActiveBendableInstances.join(', ')}` }
            { canApproveSubmitted && 'Available to be picked up by you' }
            { ownAssigned && 'Assigned to you' }

            {/* { items.length - firstItemIndex } ^ { cardsPerScreen } */}

            </Typography>
          </Box>

          <Stack direction="row">
            <IconButton  
              onClick={handleToggleClick}
              sx={{
                padding: 0,
                display: mouseInCard ? 'inherit' : 'none'
              }}
            >
              <TableIcon sx={{ fontSize: '1.2rem' }} />
            </IconButton>

            <IconButton 
              onClick={refreshData} 
              sx={{
                padding: 0,
                marginLeft: '10px',
                display: mouseInCard ? 'inherit' : 'none'
              }}
            >
              <RefreshIcon sx={{ fontSize: '1.2rem' }} />
            </IconButton>
          </Stack>
        </Box>      

      {(firstItemIndex > 0) &&  
        <Box sx={{ position: 'absolute', left: `${c.firstItemPos - c.containerCardWidth - 24}px`, top: '40px' }}>
          {(cardType === 'large') && 
            <DbObjectContainerCard item={items[firstItemIndex-1]} disabled={true} />
          }
          {(cardType === 'small') && 
            <DbObjectContainerCardSmall item={items[firstItemIndex-1]} disabled={true} />
          }
          <Box 
            sx={{ 
              position: 'absolute', 
              display: mouseInCard ? 'inherit' : 'none',
              right: '-8px', 
              top: '-40px', 
              backgroundColor: '#000000',
              width: '60px',
              height: `${c.widgetHeight}px`,
              opacity: 0.2
            }}
          />          
          <IconButton  
            onClick={handleShowPreviousItems}
            sx={{
              position: 'absolute', 
              right: '-18px', 
              top: `${c.leftChevronTop}px`,
              padding: 0,
              display: mouseInCard ? 'inherit' : 'none'
            }}
          >
            <ChevronLeftIcon sx={{  fontSize: '5rem', color: '#ffffff' }} />
          </IconButton>
        </Box>
      }


      <Box sx={{ position: 'absolute', left: `${c.firstItemPos}px`, top: '40px' }} ref={containerRef}>
        <Slide direction={slideDirection} appear={shouldAnimate} in={shouldSlideIn} container={containerRef.current} timeout={250}>
          <Stack 
            direction="row" 
            sx={{ 
              paddingBottom: '16px', 
              // overflowX: 'auto', 
            }}
          >
            { 
              _.reduce(items, (acc, item, index) => {
                if (index >= firstItemIndex) {
                  acc.push(
                    <Box key={item._id} sx={{ marginLeft: index - firstItemIndex > 0 ? `${c.cardGutter}px` : '0px' }}>
                      {(cardType === 'large') &&
                        <DbObjectContainerCard 
                          item={item}
                          // disabled={ownAssigned}              // disable for "Assigned To Me";  TODO: pass parent action so that card can indicate what sort of thing it should do when clicked
                          onCardAction={handleCardAction}
                        />
                      }
                      {(cardType === 'small') &&
                        <DbObjectContainerCardSmall
                          item={item}
                          // disabled={ownAssigned}              // disable for "Assigned To Me";  TODO: pass parent action so that card can indicate what sort of thing it should do when clicked
                          onCardAction={handleCardAction}
                        />
                      }
                    </Box>
                  );
                }
                return acc;
              }, [])
            }
          </Stack>
        </Slide>
      </Box>


      {(items.length - firstItemIndex > cardsPerScreen) &&  
        <React.Fragment>
          <Box 
            sx={{ 
              position: 'absolute',
              display: mouseInCard ? 'inherit' : 'none',
              left: `${windowWidth - 60}px`, 
              top: '0px', 
              backgroundColor: '#000000',
              width: '60px',
              height: `${c.widgetHeight}px`,
              opacity: 0.2
            }}
          />
          <IconButton  
            onClick={handleShowNextItems}
            sx={{
              position: 'absolute', 
              display: mouseInCard ? 'inherit' : 'none',
              left: `${windowWidth - 65}px`,
              top: `${c.rightChevronTop}px`,
              padding: 0,
              opacity: 1.0
            }}
          >
            <ChevronRightIcon sx={{  fontSize: '5rem', color: '#ffffff' }} />
          </IconButton>
        </React.Fragment>
      }

    </Box>
  );
}

export default DashboardDbObjectContainersRow;