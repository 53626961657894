import React,{ useState } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import clsx from 'clsx';

import UploadFileIcon from '@material-ui/icons/CloudUploadOutlined';

import styles from './default.module.css';
import { Button } from '@material-ui/core';

export const FileDropTarget = (props) => {
  const cssOverride = props.cssModule || {};
  const { disabled } = props;
  const [fileToUpload, setFileToUpload] = useState(null);

  const selectFileHandler = () => {
    if (disabled) return;
    document.getElementById('fileDropTargetInputField').click();
  };

  const fileChangeHandler = (event) => {
    console.log('FileDropTarget.fileChangeHandler() event: ', event);
    if (disabled) return;

    const file = event && event.target && event.target.files && event.target.files[0]; 
    console.log('FileDropTarget.fileChangeHandler() file: ', file);
    if (file) {
      setFileToUpload(file);
      props.onFileChange(file);
    }
  };

  const clearFileHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    document.getElementById('fileDropTargetInputField').value = null;
    setFileToUpload(null);
  };

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: NativeTypes.FILE,
    drop: (item, monitor) => {
      console.log('FileDropTarget useDrop() drop() item: ', item);
      if (item && item.files && item.files[0]) {
        setFileToUpload(item.files[0]);
        props.onFileChange(item.files[0]);
      }
      return { name: 'FileDropTarget' };
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const isActive = canDrop && isOver;

  let classes = clsx(styles.fileDropTarget, cssOverride.fileDropTarget);
  if (isActive) {
    classes = clsx(styles.fileDropTarget, cssOverride.fileDropTarget, styles.fileDropTargetActive, cssOverride.fileDropTargetActive);
  }
  else if (canDrop) {
    classes = clsx(styles.fileDropTarget, cssOverride.fileDropTarget, styles.fileDropTargetCanDrop, cssOverride.fileDropTargetCanDrop);
  }

  const dropTargetTopPrompt = (fileToUpload) ? ('FILE: ' + fileToUpload.name) : 'DRAG FILE HERE';
  const dropTargetBottomPrompt = (isActive) ? '' : 'OR CLICK TO SELECT FILE';
  const button = (<Button className={styles.clearFileButton} variant="outlined" size="small" onClick={clearFileHandler} >Clear File</Button>);

  return (
    // eslint-disable-next-line jsx-a11y/aria-role
    <div className={classes} ref={drop} role={'FileDropTarget'} onClick={selectFileHandler} style={{ opacity: disabled ? 0.3 : 1 }} >
      <div>{isActive ? 'RELEASE TO USE' : dropTargetTopPrompt}</div>
      <div className={styles.uploadFileIcon}><UploadFileIcon fontSize="large" /></div>
      <div>{fileToUpload ? button : dropTargetBottomPrompt}</div>
      <input 
        id="fileDropTargetInputField"
        type="file"
        name="name"
        style={{ display: 'none' }} 
        onChange={fileChangeHandler}
      />
    </div>
  );
};
