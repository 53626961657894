/**
 * Redux Toolkit slice to hold collection-style data
 * 
 * Note on data retreival:
 * When data to be used in a wizard has already be retreived, use
 * 'mergeCollectionWizardRootData'. When data needs to be 
 * retreived, use a thunk like 'getSpotlightSlide'
 * 
 */

import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import _ from 'lodash-es';
import callApi from '../apiService';

import objectFactory from '../objectFactory';



const getEmptyContents = (collectionType) => {
  if (collectionType === 'community_collection') {
    return [objectFactory.buildCollectionContentsResourceObject()];
  }
  if (collectionType === 'career_collection') {
    let contents = [];
    contents.push(objectFactory.buildCollectionContentsResourceObject('Workplace Skills'));
    contents.push(objectFactory.buildCollectionContentsResourceObject('Foundational Skills'));
    contents.push(objectFactory.buildCollectionContentsResourceObject('Technical Skills'));
    contents.push(objectFactory.buildCollectionContentsResourceObject('Diving Deeper'));
    return contents;
  }
  if (collectionType === 'spotlight') {
    return [objectFactory.buildCollectionContentsSpotlightObject()];
  }
  return [objectFactory.buildCollectionContentsResourceObject()];   // default state
};

const initialState = {
  doingEdit: false,
  wizardDataObj: {
    table_name: null,             //  'community_collections' | 'career_collections' | 'spotlights'
    status: 'draft',
    raw_data: {
      collectionCard: {},
      authorCard: {},
      categorizeCard: {},
      scheduleCard: {},
      contentsCard: {
        collection_contents: [{}],
      }
    }
  },
  currentDbObjectShells: {
    collections: []
  },
  status: 'idle',
};


const fetchDbObjectShells = async (options) => {
  console.log('collectionsSlice.fetchDbObjectShells() options: ', options);
  options.limit = options.limit || 'all';

  try {
    const response = await callApi('getDbObjectShells', null, { params: options });
    console.log('collectionsSlice.fetchDbObjectShells() response: ', response);
    return response.data;   // axios attribute
  } catch (error) {
    console.error('collectionsSlice.fetchDbObjectShells() error: ', error.message);
  }
};

export const getDbObjectShells = createAsyncThunk(
  'collections/getDbObjectShells',
  async (options) => {
    const response = await fetchDbObjectShells(options);
    return { tableName: options.tableName, rows: response.payload };
  }
);

const fetchSpotlightSlide = async (options) => {
  console.log('collectionsSlice.fetchSpotlightSlide() options: ', options);
  try {
    const response = await callApi(
      'getSpotlightSlide',
      { id: options?.spotlightSlideId },
      {
        params: {
          targetInstance: options?.targetInstance
        },
      }
    );
    console.log('collectionsSlice.fetchSpotlightSlide() response: ', response);
    const spotlightSlide = response?.data?.payload?.spotlightSlide || {};
    return spotlightSlide;
  } catch (error) {
    console.error('collectionsSlice.fetchSpotlightSlide() error: ', error.message);
  }
};

export const getSpotlightSlide = createAsyncThunk(
  'collections/getSpotlightSlide',
  async (options) => {
    console.log('collectionsSlice.getSpotlightSlide() options: ', options);
    const spotlightSlide = await fetchSpotlightSlide(options);
    return { spotlightSlideId: options.spotlightSlideId, spotlightSlide: spotlightSlide };
  }
);

const fetchSpotlightCollection = async (options) => {
  console.log('collectionsSlice.fetchSpotlightCollection() options: ', options);
  try {
    const response = await callApi(
      'getSpotlightCollection',
      { id: options?.spotlightCollectionId },
      {
        params: {
          // targetInstance: options?.targetInstance
        },
      }
    );
    console.log('collectionsSlice.fetchSpotlightCollection() response: ', response);
    const spotlightCollection = response?.data?.payload?.spotlightCollection || {};
    return spotlightCollection;
  } catch (error) {
    console.error('collectionsSlice.fetchSpotlightCollection() error: ', error.message);
  }
};

export const getSpotlightCollection = createAsyncThunk(
  'collections/getSpotlightCollection',
  async (options) => {
    console.log('collectionsSlice.getSpotlightCollection() options: ', options);
    const spotlightCollection = await fetchSpotlightCollection(options);
    return { spotlightCollectionId: options.spotlightCollectionId, spotlightCollection: spotlightCollection };
  }
);

export const collectionsSlice = createSlice({
  name: 'collections',
  initialState,
  reducers: {
    addContentObj: (state, action) => {
      console.log('collectionsSlice addContentObj; action: ', action);
      const { sectionIndex, contentObj} = action.payload;
      if (contentObj) {
        state.wizardDataObj.raw_data.contentsCard.collection_contents[sectionIndex] = state.wizardDataObj.raw_data.contentsCard.collection_contents[sectionIndex] || objectFactory.buildCollectionContentsResourceObject();
        const found = _.find(state.wizardDataObj.raw_data.contentsCard.collection_contents[sectionIndex].items, (item) => {
          return (item.mid === contentObj.mid);
        });
        if (!found) {     // don't add twice
          state.wizardDataObj.raw_data.contentsCard.collection_contents[sectionIndex].items.push(action.payload.contentObj);
        }
      }
      console.log('collectionsSlice addContentObj; state after add: ', current(state));
    },
    removeContentObj: (state, action) => {
      console.log('collectionsSlice removeContentObj; action: ', action);
      const { sectionIndex, contentObj} = action.payload;
      if (contentObj) {
        state.wizardDataObj.raw_data.contentsCard.collection_contents[sectionIndex] = state.wizardDataObj.raw_data.contentsCard.collection_contents[sectionIndex] || objectFactory.buildCollectionContentsResourceObject();
        _.remove(state.wizardDataObj.raw_data.contentsCard.collection_contents[sectionIndex].items, (item) => {
          return (item.mid === contentObj.mid);
        });
      }
      console.log('collectionsSlice removeContentObj; state after remove: ', current(state));
    },
    addNewToCollectionContents: (state, action) => {
      console.log('collectionsSlice addNewToCollectionContents; action.payload: ', action.payload);
      // const { sectionIndex } = action.payload;
      state.wizardDataObj.raw_data.contentsCard.collection_contents.push(objectFactory.buildCollectionContentsResourceObject());
      console.log('collectionsSlice addNewToCollectionContents; state after remove: ', current(state));
    },
    deleteFromCollectionContents: (state, action) => {
      console.log('collectionsSlice deleteFromCollectionContents; action.payload: ', action.payload);
      const { sectionIndex } = action.payload;
      if ((sectionIndex > -1) && (sectionIndex < state.wizardDataObj.raw_data.contentsCard.collection_contents.length)) {
        console.log('collectionsSlice deleteFromCollectionContents; delete obj: ', state.wizardDataObj.raw_data.contentsCard.collection_contents[sectionIndex]);
        state.wizardDataObj.raw_data.contentsCard.collection_contents.splice(sectionIndex, 1);
      }
      console.log('collectionsSlice deleteFromCollectionContents; state after remove: ', current(state));
    },
    // given sections...
    reorderCollectionContents: (state, action) => {
      const { sourceIndex, destinationIndex } = action.payload;
      const newContents = Array.from(state.wizardDataObj.raw_data.contentsCard.collection_contents);
      const [removed] = newContents.splice(sourceIndex, 1);
      newContents.splice(destinationIndex, 0, removed);
      state.wizardDataObj.raw_data.contentsCard.collection_contents = newContents;
    },
    // items in a give section...
    reorderContentObjs: (state, action) => {
      const { sectionIndex, sourceIndex, destinationIndex } = action.payload;
      const newContents = Array.from(state.wizardDataObj.raw_data.contentsCard.collection_contents[sectionIndex].items);
      const [removed] = newContents.splice(sourceIndex, 1);
      newContents.splice(destinationIndex, 0, removed);
      state.wizardDataObj.raw_data.contentsCard.collection_contents[sectionIndex].items = newContents;
    },
    updateContentObjectDataField: (state, action) => {
      const { sectionIndex, dataFieldName, newValue } = action.payload;
      state.wizardDataObj.raw_data.contentsCard.collection_contents[sectionIndex][dataFieldName] = newValue;
      console.log('collectionsSlice removeContentObj; payload, state after update: ', action.payload, current(state));
    },
    mergeCollectionWizardRootData: (state, action) => {
      console.log('collectionsSlice mergeCollectionWizardRootData before merge; state, action: ', current(state), action);
      state.wizardDataObj = _.mergeWith(state.wizardDataObj, action.payload, (objValue, srcValue, key, object, source, stack) => {
        if (_.isArray(srcValue)) {
          return srcValue;              // allows empty arrays to overwrite existing arrays
        } else {
          return undefined;
        }
      });
      state.doingEdit = true;   // must be doing edit because we just merged
      console.log('collectionsSlice mergeCollectionWizardRootData after merge; state, action: ', current(state), action);
    },
    mergeCollectionWizardRawData: (state, action) => {
      console.log('collectionsSlice mergeCollectionWizardRawData before merge; state, action: ', current(state), action);
      state.wizardDataObj.raw_data = _.mergeWith(state.wizardDataObj.raw_data, action.payload, (objValue, srcValue, key, object, source, stack) => {
        // console.log('collectionsSlice mergeCollectionWizardRawData objValue: ', objValue);
        // console.log('collectionsSlice mergeCollectionWizardRawData srcValue: ', srcValue);
        // console.log('collectionsSlice mergeCollectionWizardRawData key: ', key);
        // console.log('collectionsSlice mergeCollectionWizardRawData object: ', object);
        // console.log('collectionsSlice mergeCollectionWizardRawData source: ', source);
        if (_.isArray(srcValue)) {
          return srcValue;              // allows empty arrays to overwrite existing arrays
        } else {
          return undefined;
        }
      });
      console.log('collectionsSlice mergeCollectionWizardRawData after merge; state, action: ', current(state), action);
    },
    mergeCollectionWizardContentData: (state, action) => {
      console.log('collectionsSlice mergeCollectionWizardContentData before merge; state, action: ', current(state), action);
      const { sectionIndex, contentId, fieldName, fieldValue } = action?.payload;
      let items;
      if (state?.wizardDataObj?.raw_data?.contentsCard?.collection_contents && 
          state.wizardDataObj.raw_data.contentsCard.collection_contents[sectionIndex] &&
          state.wizardDataObj.raw_data.contentsCard.collection_contents[sectionIndex].items) {
        items = state.wizardDataObj.raw_data.contentsCard.collection_contents[sectionIndex].items;
      }
      if (items) {
        console.log('collectionsSlice mergeCollectionWizardContentData items: ', items);
        let itemIndex = -1;
        _.forEach(items, (item, i) => {
          if (item.id === contentId) itemIndex = i;
        });
        if (itemIndex > -1) {
          console.log('collectionsSlice mergeCollectionWizardContentData found target and setting fieldName, fieldValue: ', fieldName, fieldValue);
          state.wizardDataObj.raw_data.contentsCard.collection_contents[sectionIndex].items[itemIndex][fieldName] = fieldValue;
        }
      }
      console.log('collectionsSlice mergeCollectionWizardContentData after merge; state: ', current(state));
    },
    resetCollectionWizardData: (state, action) => {
      const { tableName, collectionType, doingEdit } = action.payload;
      console.log('collectionsSlice resetCollectionWizardData; state, action: ', current(state), action);
      let newState = {
        table_name: tableName,             //  'community_collections' | 'career_collections'
        status: 'draft',
        raw_data: {
          collectionCard: { collection_type: collectionType },
          authorCard: {},
          categorizeCard: {},
          scheduleCard: {},
          contentsCard: {
            collection_contents: getEmptyContents(collectionType), //  [getEmptyShape()] 
          }
        }
      };
      newState.raw_data.collectionCard.collection_type = collectionType;
      
      // set defaults...
      if (tableName === 'spotlights') newState.raw_data.collectionCard.headline = 'Spotlight on: ';

      state.wizardDataObj = newState;
      state.doingEdit = doingEdit || false;
      console.log('collectionsSlice resetCollectionWizardData; state after reset: ', current(state));
    },
    setDoingEdit: (state, action) => {
      console.log('collectionsSlice resetCollectionWizardData; state, action: ', current(state), action);
      state.doingEdit = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDbObjectShells.pending, (state) => {
        console.log('collectionsSlice getDbObjectShells.pending state: ', state);
        state.status = 'loading';
      })
      .addCase(getDbObjectShells.fulfilled, (state, action) => {
        state.status = 'idle';
        console.log('collectionsSlice getDbObjectShells.fulfilled action: ', action);
        state.currentDbObjectShells[action.payload.tableName] = action.payload.rows;
        console.log('getDbObjectShells case; state: ', current(state));
      })
      .addCase(getSpotlightSlide.pending, (state) => {
        console.log('collectionsSlice getSpotlightSlide.pending state: ', state);
        state.status = 'loading';
      })
      .addCase(getSpotlightSlide.fulfilled, (state, action) => {
        state.status = 'idle';
        console.log('collectionsSlice getSpotlightSlide.fulfilled action: ', action);
        const dbObjectShell = objectFactory.buildDbObjectShellFromSpotlightSlideDocument(action.payload.spotlightSlide);
        console.log('collectionsSlice getSpotlightSlide.fulfilled dbObjectShell: ', dbObjectShell);
        state.wizardDataObj = dbObjectShell;
        state.doingEdit = false;
        // console.log('getSpotlightSlide case; state: ', current(state));
      })
      .addCase(getSpotlightCollection.pending, (state) => {
        console.log('collectionsSlice getSpotlightCollection.pending state: ', state);
        state.status = 'loading';
      })
      .addCase(getSpotlightCollection.fulfilled, (state, action) => {
        state.status = 'idle';
        console.log('collectionsSlice getSpotlightCollection.fulfilled action: ', action);
        const dbObjectShell = objectFactory.buildDbObjectShellFromSpotlightCollectionDocument(action.payload.spotlightCollection);
        console.log('collectionsSlice getSpotlightCollection.fulfilled dbObjectShell: ', dbObjectShell);
        state.wizardDataObj = dbObjectShell;
        state.doingEdit = false;
        // console.log('getSpotlightCollection case; state: ', current(state));
      });
  },
});

export const { 
  addContentObj,
  removeContentObj,
  reorderContentObjs,
  addNewToCollectionContents,
  deleteFromCollectionContents,
  reorderCollectionContents,
  updateContentObjectDataField,
  mergeCollectionWizardRootData,
  mergeCollectionWizardRawData,
  mergeCollectionWizardContentData,
  resetCollectionWizardData,
  setDoingEdit,
} = collectionsSlice.actions;

// for useSelector; state is complete store, not slice
export const selectCurrentDoingEdit = (state) => state.collections.doingEdit;
export const selectCurrentWizardDataObj = (state) => state.collections.wizardDataObj;
export const selectCurrentCollectionShells = (state) => state.collections.currentDbObjectShells.collections;

console.log('collectionsSlice: ', collectionsSlice);

export default collectionsSlice.reducer;
