import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import _ from 'lodash-es';
import callApi from './apiService';

const initialState = {
  postgresTableDescriptions: {},
  selectedPostgresTableName: null,
  shouldUploadCsvFile: null,        // set timestamp to trigger a unique change to state
  providers: [],
  tags: [],
  categories: [],
  currentCreateWizardPage: {
    resourceCard: {},
    findCard: {},
    handOffCard: {},
    scheduleCard: {}
  },
  currentDbObjectShells: {
    learning_objects: []
  },
  status: 'idle',
};

const fetchPostgresTableDescription = async (options) => {
  console.log('createPageSlice.fetchPostgresTableDescription() options: ', options);
  try {
    const response = await callApi('getPostgressTableDescription', null, { params: options });
    console.log('createPageSlice.fetchPostgresTableDescription() response: ', response);
    return response.data;   // axios attribute
  } catch (error) {
    console.error('createPageSlice.fetchPostgresTableDescription() error: ', error.message);
  }
};

export const getPostgresTableDescription = createAsyncThunk(
  'createPage/fetchPostgresTableDescription',
  async (tableName, bendableInstance) => {
    const response = await fetchPostgresTableDescription(tableName, bendableInstance);
    return response.payload;
  }
);

const fetchPostgresTable = async (options) => {
  console.log('createPageSlice.fetchPostgresTable() options: ', options);
  options.limit = options.limit || 'all';

  try {
    const response = await callApi('getPostgressTableRows', null, { params: options });
    console.log('createPageSlice.fetchPostgresTable() response: ', response);
    return response.data;   // axios attribute
  } catch (error) {
    console.error('createPageSlice.fetchPostgresTable() error: ', error.message);
  }
};

export const getPostgresTable = createAsyncThunk(
  'createPage/getPostgresTable',
  async (options) => {
    const response = await fetchPostgresTable(options);
    return { tableName: options.tableName, rows: response.payload };
  }
);

const fetchDbObjectShells = async (options) => {
  console.log('createPageSlice.fetchDbObjectShells() options: ', options);
  options.limit = options.limit || 'all';

  try {
    const response = await callApi('getDbObjectShells', null, { params: options });
    console.log('createPageSlice.fetchDbObjectShells() response: ', response);
    return response.data;   // axios attribute
  } catch (error) {
    console.error('createPageSlice.fetchDbObjectShells() error: ', error.message);
  }
};

export const getDbObjectShells = createAsyncThunk(
  'createPage/getDbObjectShells',
  async (options) => {
    const response = await fetchDbObjectShells(options);
    return { tableName: options.tableName, rows: response.payload };
  }
);

export const createPageSlice = createSlice({
  name: 'createPage',
  initialState,
  reducers: {
    setSelectedPostgresTableName: (state, action) => {
      state.selectedPostgresTableName = action.payload;
    },
    triggerCsvFileUpload: (state, action) => {
      state.shouldUploadCsvFile = Date.now();
    },
    mergeCreateWizardChanges: (state, action) => {
      console.log('createPageSlice mergeCreateWizardChanges before merge; state, action: ', current(state), action);
      // _.mergeWith so that if the payload is an empty array, the end state has an empty array

      state.currentCreateWizardPage = _.mergeWith(state.currentCreateWizardPage, action.payload, (objValue, srcValue, key, object, source, stack) => {
        // console.log('createPageSlice mergeCreateWizardChanges objValue: ', objValue);
        // console.log('createPageSlice mergeCreateWizardChanges srcValue: ', srcValue);
        // console.log('createPageSlice mergeCreateWizardChanges key: ', key);
        // console.log('createPageSlice mergeCreateWizardChanges object: ', object);
        // console.log('createPageSlice mergeCreateWizardChanges source: ', source);
        if (_.isArray(srcValue)) {
          return srcValue;              // allows empty arrays to overwrite existing arrays
        } else {
          return undefined;
        }
      });

      console.log('createPageSlice mergeCreateWizardChanges after merge; state, action: ', current(state), action);
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getPostgresTableDescription.pending, (state) => {
      console.log('createPageSlice getPostgresTableDescription.pending state: ', state);
      state.status = 'loading';
    })
    .addCase(getPostgresTableDescription.fulfilled, (state, action) => {
      state.status = 'idle';
      console.log('createPageSlice getPostgresTableDescription.fulfilled action: ', action);
      state.postgresTableDescriptions[action.payload.table_name] = action.payload;
    })
    .addCase(getPostgresTable.pending, (state) => {
      console.log('createPageSlice getPostgresTable.pending state: ', state);
      state.status = 'loading';
    })
    .addCase(getPostgresTable.fulfilled, (state, action) => {
      state.status = 'idle';
      console.log('createPageSlice getPostgresTable.fulfilled action: ', action);
      state[action.payload.tableName] = action.payload.rows;
    })
    .addCase(getDbObjectShells.pending, (state) => {
      console.log('createPageSlice getDbObjectShells.pending state: ', state);
      state.status = 'loading';
    })
    .addCase(getDbObjectShells.fulfilled, (state, action) => {
      state.status = 'idle';
      console.log('createPageSlice getDbObjectShells.fulfilled action: ', action);
      state.currentDbObjectShells[action.payload.tableName] = action.payload.rows;
      // console.log('getDbObjectShells case; state: ', current(state));
    });

},
});

export const { 
  setSelectedPostgresTableName,
  triggerCsvFileUpload,
  mergeCreateWizardChanges,
} = createPageSlice.actions;

// for useSelector; state is complete store, not slice
export const selectPostgresTableDescriptions = (state) => state.createPage.postgresTableDescriptions;
export const selectPostgresTableName = (state) => state.createPage.selectedPostgresTableName;
export const selectShouldUploadCsvFile = (state) => state.createPage.shouldUploadCsvFile;
export const selectProviders = (state) => state.createPage.providers;
export const selectTags = (state) => state.createPage.tags;
export const selectCategories = (state) => state.createPage.categories;
export const selectCurrentCreateWizardPage = (state) => state.createPage.currentCreateWizardPage;
export const selectCurrentLearningObjectShells = (state) => state.createPage.currentDbObjectShells.learning_objects;

export default createPageSlice.reducer;
