/**
 * Redux Store slice to hold Dashboard data
 */

import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import _ from 'lodash-es';
import callApi from './apiService';

const initialState = {
  ownDraftDbObjectShells: [],                   // all dbObjectShells in "draft" status and submitted by the currently logged in user
  ownDraftDbObjectContainers: [],               // all dbObjectBins in "draft" status and submitted by the currently logged in user
  canApproveSubmittedDbObjectContainers: [],    // all dbObjectBins in "submitted" status and approvable by the currently logged in user
  ownAssignedDbObjectContainers: [],            // all dbObjectBins in "submitted" status and owned for at least one approver role by the logged in user
  status: 'idle',
};

/* Shells */
const fetchDbObjectShells = async (options) => {
  // console.log('dashboardSlice.fetchDbObjectShells() options: ', options);
  options.limit = options.limit || 'all';

  try {
    const response = await callApi('getDbObjectShells', null, { params: options });
    // console.log('dashboardSlice.fetchDbObjectShells() response: ', response);
    return response.data;   // axios attribute
  } catch (error) {
    console.error('dashboardSlice.fetchDbObjectShells() error: ', error.message);
  }
};

export const getDbObjectShells = createAsyncThunk(
  'dashboardData/getDbObjectShells',
  async (options) => {
    const response = await fetchDbObjectShells(options);
    return {
      status: options.status,
      submittedById: options.submittedById || null, 
      filterByApproverBitmask: options.filterByApproverBitmask || false,
      dbObjectShells: response.payload
    };
  }
);


/* Bins */
const fetchDbObjectBins = async (options) => {
  // console.log('dashboardSlice.fetchDbObjectBins() options: ', options);
  options.limit = options.limit || 'all';

  try {
    const response = await callApi('getDbObjectBins', null, { params: options });
    // console.log('dashboardSlice.fetchDbObjectBins() response: ', response);
    return response.data;   // axios attribute
  } catch (error) {
    console.error('dashboardSlice.fetchDbObjectBins() error: ', error.message);
  }
};

export const getDbObjectBins = createAsyncThunk(
  'dashboardData/getDbObjectBins',
  async (options) => {
    const response = await fetchDbObjectBins(options);
    return {
      status: options.status,
      submittedById: options.submittedById || null, 
      assignedToId: options.assignedToId || null, 
      filterByNeedsApproval: options.filterByNeedsApproval || false,
      dbObjectBins: response.payload
    };
  }
);





export const dashboardSlice = createSlice({
  name: 'dashboardData',
  initialState,
  reducers: {
    deleteDbObjectShell: (state, action) => {
      // console.log('dashboardSlice deleteDbObjectShell; state, action: ', current(state), action);
      const storeKey = action?.payload?.storeKey;
      const targetId = action?.payload?.targetId;
      // console.log('dashboardSlice deleteDbObjectShell; storeKey, targetId: ', storeKey, targetId);
      _.remove(state[storeKey], (obj) => {
        // console.log('dashboardSlice deleteDbObjectShell; obj._id === targetId: ', obj._id === targetId);
        return (obj._id === targetId);
      });
      // console.log('dashboardSlice deleteDbObjectShell; state: ', current(state));
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getDbObjectShells.pending, (state) => {
      // console.log('dashboardSlice getDbObjectShells.pending state: ', current(state));
      state.status = 'loading';
    })
    .addCase(getDbObjectShells.fulfilled, (state, action) => {
      state.status = 'idle';
      // console.log('dashboardSlice getDbObjectShells.fulfilled action: ', action);
      state[action.meta.arg.storeKey] = action.payload.dbObjectShells;
    })
    .addCase(getDbObjectBins.pending, (state) => {
      // console.log('dashboardSlice getDbObjectBins.pending state: ', current(state));
      state.status = 'loading';
    })
    .addCase(getDbObjectBins.fulfilled, (state, action) => {
      state.status = 'idle';
      // console.log('dashboardSlice getDbObjectBins.fulfilled action: ', action);
      state[action.meta.arg.storeKey] = action.payload.dbObjectBins;
    });

},
});

export const { 
  deleteDbObjectShell,
} = dashboardSlice.actions;

// for useSelector; state is complete store, not slice
export const selectOwnDraftDbObjectShells = (state) => state.dashboardData.ownDraftDbObjectShells;
export const selectOwnDraftDbObjectContainers = (state) => state.dashboardData.ownDraftDbObjectContainers;
export const selectCanApproveSubmittedDbObjectContainers = (state) => state.dashboardData.canApproveSubmittedDbObjectContainers;
export const selectOwnAssignedDbObjectContainers = (state) => state.dashboardData.ownAssignedDbObjectContainers;


export default dashboardSlice.reducer;
