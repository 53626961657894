import React, { useState, useEffect } from "react";
import Modal from 'react-modal';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import Stack from '@mui/material/Stack';
import Typography from "@mui/material/Typography";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import authService from "../../app/authService";


const AssignDbObjectBinModal = (props) => {
  const {
    isOpen,
    cancelLabel,
    confirmLabel,
    secondaryConfirmLabel,
    onClose,
    width='50%',
    height='50%',
    noPadding=false,
    shouldCloseOnOverlayClick=false,
    data                                    // currentDbObjectShell
  } = props;

  const [modalIsOpen, setModalIsOpen] = useState(props.isOpen);
  const [editorRoleChecked, setEditorRoleChecked] = React.useState(false);
  const [operatorRoleChecked, setOperatorRoleChecked] = React.useState(false);

  const modalStyles = {
    content: {
      width: width,
      height: height,
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '11px',
      padding: '32px',
      backgroundColor: '#F9F4EC'
    },
    overlay: {
      zIndex: 2000
    }
  };
  

  useEffect(() => {
    setModalIsOpen(isOpen);
  }, [isOpen]);

  const afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  };

  const modalCloseHandler = (modalData) => {
    setModalIsOpen(false);
    // add on to { action: ~~~ } object
    modalData.id = data._id;
    modalData.binId = data.bin_id;
    modalData.assignEditor = editorRoleChecked;
    modalData.assignOperator = operatorRoleChecked;
    console.log('AssignDbObjectBinModal.modalCloseHandler() modalData: ', modalData);
    setEditorRoleChecked(false);
    setOperatorRoleChecked(false);
    if (onClose) onClose(modalData);
  };

  const handleEditorRoleChange = (event) => {
    setEditorRoleChecked(event.target.checked);
  };

  const handleOperatorRoleChange = (event) => {
    setOperatorRoleChecked(event.target.checked);
  };

  return (
    <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={modalCloseHandler}
        style={modalStyles}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      >
        <Box
          component="div"
          sx={{
            display: noPadding ? 'none' : 'block',
            width: '100%',
            height: '20px',
          }}
        />

        
        <Box
          component="div"
          sx={{
            width: '100%',
            height: `calc(100% - ${noPadding ? 0 : 20}px)`,
          }}
        >
          <Typography
            fontFamily="Sharp Sans"
            fontSize="1.4rem"
          >
            Which approver roles do you wish to take?
          </Typography>            

          <FormGroup sx={{ paddingTop: '16px', paddingLeft: '16px' }}>
            <FormControlLabel 
              disabled={!authService.hasRole('editor2', data?.controlling_instance)} 
              control={<Checkbox
                id="editor-checkbox"
                checked={editorRoleChecked}
                onChange={handleEditorRoleChange}
              />} 
              label="Editor"
              id="editor-label"
              sx={{
                '& .MuiTypography-root': {
                  fontFamily: "Sharp Sans",
                },
                '& .MuiCheckbox-root': {
                  padding: '2px 9px 2px 9px !important',
                }
              }}
            />

            <FormControlLabel 
              disabled={!authService.hasRole('operator2', data?.controlling_instance)} 
              control={<Checkbox
                id="opeator-checkbox"
                checked={operatorRoleChecked}
                onChange={handleOperatorRoleChange}
              />} 
              label="Operator" 
              id="operator-label"
              sx={{
                '& .MuiTypography-root': {
                  fontFamily: "Sharp Sans",
                },
                '& .MuiCheckbox-root': {
                  padding: '2px 9px 2px 9px !important',
                }
              }}
            />

          </FormGroup>

        </Box>


        <Box
          component="div"
          sx={{
            position: 'absolute',
            bottom: 20,
            right: 20,
          }}
        >
          <Stack
            direction="row"
          >
            <Button
              color="primary"
              id="cancel-button"
              variant="outlined"
              disableElevation
              onClick={() => modalCloseHandler({ action: 'cancel' })}
              sx={{
                display: cancelLabel ? 'block' : 'none',
                borderRadius: '18px', 
                textTransform: 'none',
                minWidth: '100px',
                marginLeft: '20px'
              }}
            >
              {cancelLabel}
            </Button>

            <Button
              color="primary"
              id="confirm-button"
              variant="contained"
              disabled={!editorRoleChecked && !operatorRoleChecked}
              disableElevation
              onClick={() => modalCloseHandler({ action: 'confirm' })}
              sx={{
                display: confirmLabel ? 'block' : 'none',
                borderRadius: '18px', 
                textTransform: 'none',
                minWidth: '100px',
                marginLeft: '20px',
              }}
            >
              {confirmLabel}
            </Button>

            <Button
              color="primary"
              id="confirm-button"
              variant="contained"
              disableElevation
              onClick={() => modalCloseHandler({ action: 'secondary-confirm' })}
              sx={{
                display: secondaryConfirmLabel ? 'block' : 'none',
                borderRadius: '18px', 
                textTransform: 'none',
                minWidth: '100px',
                marginLeft: '20px'
              }}
            >
              {secondaryConfirmLabel}
            </Button>

          </Stack>
        </Box>

        <Box
          component="div"
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
          }}
        >
          <IconButton aria-label="close" onClick={() => modalCloseHandler({ action: 'close' })}>
            <CancelIcon />
          </IconButton>
        </Box>

      </Modal>
  );
};

export default AssignDbObjectBinModal;