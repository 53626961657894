import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import _ from 'lodash-es';
import callApi from './apiService';

const initialState = {
  postgresTableDescriptions: {},
  selectedPostgresTableName: null,
  shouldUploadCsvFile: null,        // set timestamp to trigger a unique change to state
  providers: [],
  tags: [],
  categories: [],
  wizardDbObjectShell: {
    controlling_instance: null,
    table_name: 'learning_objects',
    status: 'draft',
    raw_data: {
      resourceCard: {},
      findCard: {},
      handOffCard: {},
      scheduleCard: {},
    }
  },
  resourceDbObjectShellBins: [],            // holds bins of DbObjectShells of type 'resource' (learning_objects) (bin members have same 'setId')
  bulkDbObjectUploadData: [],
  status: 'idle',
};

const fetchPostgresTableDescription = async (options) => {
  console.log('resourcesSlice.fetchPostgresTableDescription() options: ', options);
  try {
    const response = await callApi('getPostgressTableDescription', null, { params: options });
    console.log('resourcesSlice.fetchPostgresTableDescription() response: ', response);
    return response.data;   // axios attribute
  } catch (error) {
    console.error('resourcesSlice.fetchPostgresTableDescription() error: ', error.message);
  }
};

export const getPostgresTableDescription = createAsyncThunk(
  'resources/fetchPostgresTableDescription',
  async (tableName, bendableInstance) => {
    const response = await fetchPostgresTableDescription(tableName, bendableInstance);
    return response.payload;
  }
);

const fetchPostgresTable = async (options) => {
  console.log('resourcesSlice.fetchPostgresTable() options: ', options);
  options.limit = options.limit || 'all';

  try {
    const response = await callApi('getPostgressTableRows', null, { params: options });
    console.log('resourcesSlice.fetchPostgresTable() response: ', response);
    return response.data;   // axios attribute
  } catch (error) {
    console.error('resourcesSlice.fetchPostgresTable() error: ', error.message);
  }
};

export const getPostgresTable = createAsyncThunk(
  'resources/getPostgresTable',
  async (options) => {
    const response = await fetchPostgresTable(options);
    return { tableName: options.tableName, rows: response.payload };
  }
);

const fetchDbObjectShells = async (options) => {
  console.log('resourcesSlice.fetchDbObjectShells() options: ', options);
  options.limit = options.limit || 'all';

  try {
    const response = await callApi('getDbObjectShells', null, { params: options });
    console.log('resourcesSlice.fetchDbObjectShells() response: ', response);
    return response.data;   // axios attribute
  } catch (error) {
    console.error('resourcesSlice.fetchDbObjectShells() error: ', error.message);
  }
};

export const getDbObjectShells = createAsyncThunk(
  'resources/getDbObjectShells',
  async (options) => {
    const response = await fetchDbObjectShells(options);
    return { tableName: options.tableName, rows: response.payload };
  }
);

export const resourcesSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    setSelectedPostgresTableName: (state, action) => {
      state.selectedPostgresTableName = action.payload;
    },
    triggerCsvFileUpload: (state, action) => {
      state.shouldUploadCsvFile = Date.now();
    },
    mergeResourceWizardRootData: (state, action) => {
      console.log('resourcesSlice mergeResourceWizardRootData before merge; state, action: ', current(state), action);
      // the .mergeWith() below can generate this error: Unhandled Rejection (Error): [Immer] Immer only supports setting array indices and the 'length' property
      // so let's avoid Immer with cloneDeep...
      // state.wizardDbObjectShell = _.mergeWith(state.wizardDbObjectShell, action.payload, (objValue, srcValue, key, object, source, stack) => {
      const stateCopy = _.cloneDeep(state.wizardDbObjectShell);
      const payloadCopy = _.cloneDeep(action.payload);
      state.wizardDbObjectShell = _.mergeWith(stateCopy, payloadCopy, (objValue, srcValue, key, object, source, stack) => {
        if (_.isArray(srcValue)) {
          return srcValue;              // allows empty arrays to overwrite existing arrays
        } else {
          return undefined;
        }
      });
      console.log('resourcesSlice mergeResourceWizardRootData after merge; state: ', current(state));
    },
    mergeResourceWizardRawData: (state, action) => {
      console.log('resourcesSlice mergeResourceWizardRawData before merge; state, action: ', current(state), action);
      // the .mergeWith() below can generate this error: Unhandled Rejection (Error): [Immer] Immer only supports setting array indices and the 'length' property
      // so let's avoid Immer with cloneDeep...
      // state.wizardDbObjectShell.raw_data = _.mergeWith(state.wizardDbObjectShell.raw_data, action.payload, (objValue, srcValue, key, object, source, stack) => {
      const stateCopy = _.cloneDeep(state.wizardDbObjectShell.raw_data);
      const payloadCopy = _.cloneDeep(action.payload);
      state.wizardDbObjectShell.raw_data = _.mergeWith(stateCopy, payloadCopy, (objValue, srcValue, key, object, source, stack) => {
        // console.log('resourcesSlice mergeResourceWizardRawData objValue: ', objValue);
        // console.log('resourcesSlice mergeResourceWizardRawData srcValue: ', srcValue);
        // console.log('resourcesSlice mergeResourceWizardRawData key: ', key);
        // console.log('resourcesSlice mergeResourceWizardRawData object: ', object);
        // console.log('resourcesSlice mergeResourceWizardRawData source: ', source);
        if (_.isArray(srcValue)) {
          return srcValue;              // allows empty arrays to overwrite existing arrays
        } else {
          return undefined;
        }
      });

      console.log('resourcesSlice mergeResourceWizardRawData after merge; state: ', current(state));
    },
    resetResourceWizardData: (state, action) => {
      console.log('resourcesSlice resetWizardData; state, action: ', current(state), action);
      state.wizardDbObjectShell = {
        controlling_instance: null,
        table_name: 'learning_objects',
        status: 'draft',
        raw_data: {
          resourceCard: {},
          findCard: {},
          handOffCard: {},
          scheduleCard: {}
        }
      };
    },
    resetBulkResourceUpload: (state, action) => {
      state.bulkDbObjectUploadData = [];
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getPostgresTableDescription.pending, (state) => {
      console.log('resourcesSlice getPostgresTableDescription.pending state: ', state);
      state.status = 'loading';
    })
    .addCase(getPostgresTableDescription.fulfilled, (state, action) => {
      state.status = 'idle';
      console.log('resourcesSlice getPostgresTableDescription.fulfilled action: ', action);
      state.postgresTableDescriptions[action.payload.table_name] = action.payload;
    })
    .addCase(getPostgresTable.pending, (state) => {
      console.log('resourcesSlice getPostgresTable.pending state: ', state);
      state.status = 'loading';
    })
    .addCase(getPostgresTable.fulfilled, (state, action) => {
      state.status = 'idle';
      console.log('resourcesSlice getPostgresTable.fulfilled action: ', action);
      state[action.payload.tableName] = action.payload.rows;
    })
    .addCase(getDbObjectShells.pending, (state) => {
      console.log('resourcesSlice getDbObjectShells.pending state: ', state);
      state.status = 'loading';
    })
    .addCase(getDbObjectShells.fulfilled, (state, action) => {
      state.status = 'idle';
      console.log('resourcesSlice getDbObjectShells.fulfilled action: ', action);
      state.currentDbObjectShells[action.payload.tableName] = action.payload.rows;
      // console.log('getDbObjectShells case; state: ', current(state));
    });

},
});

export const { 
  setSelectedPostgresTableName,
  triggerCsvFileUpload,
  mergeResourceWizardRootData,
  mergeResourceWizardRawData,
  resetResourceWizardData,
  resetBulkResourceUpload,
} = resourcesSlice.actions;

// for useSelector; state is complete store, not slice
export const selectPostgresTableDescriptions = (state) => state.resources.postgresTableDescriptions;
export const selectPostgresTableName = (state) => state.resources.selectedPostgresTableName;
export const selectShouldUploadCsvFile = (state) => state.resources.shouldUploadCsvFile;
export const selectProviders = (state) => state.resources.providers;
export const selectTags = (state) => state.resources.tags;
export const selectCategories = (state) => state.resources.categories;
export const selectCurrentResourceWizardDbObjectShell = (state) => state.resources.wizardDbObjectShell;
export const selectCurrentLearningObjectShells = (state) => state.resources.currentDbObjectShells.learning_objects;

export default resourcesSlice.reducer;
