import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
// import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import authService from '../../app/authService';
import configService from '../../app/configService';
import messageBus from '../../app/messageBus';
import _ from 'lodash';

const InstanceMenuButton = (props) => {
  const {
    activeBendableInstances = [],
    wrapperStyle,
    onChange,
  } = props;
  const theme = useTheme();

  const initialAuthorizedInstances = authService.getHumanReadableAuthorizedBendableInstances();
  // console.log('InstanceMenuButton initialAuthorizedInstances: ', initialAuthorizedInstances);

  const [anchorEl, setAnchorEl] = useState(null);
  const [authorizedInstances, setAuthorizedInstances] = useState(initialAuthorizedInstances);
  const [selectedInstances, setSelectedInstances] = useState(activeBendableInstances);

  const open = Boolean(anchorEl);

  useEffect(() => {
    setSelectedInstances(activeBendableInstances);
  }, [activeBendableInstances]);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);    // disable until we need a full menu
    // onSelect('newLearningObject');    // temp until we need a full menu
  };

  const handleClose = (bendableInstance) => {
    console.log('InstanceMenuButton.handleClose() bendableInstance: ', bendableInstance);
    if (bendableInstance) {
      setSelectedInstances((prevValue) => {
        let newValue;
        if (_.includes(prevValue, bendableInstance)) {
          newValue = _.filter(prevValue, (instance) => (instance !== bendableInstance));
        } else {
          newValue = [...prevValue, bendableInstance];
        }
        onChange(newValue);
        return newValue;
      });
    } else {
      setAnchorEl(null);
    }
  };

  const refreshAuthorizedInstances = () => {
    const latestData = authService.getHumanReadableAuthorizedBendableInstances();
    console.log('InstanceMenuButton.refreshAuthorizedInstances() latestData: ', latestData);
    setAuthorizedInstances(latestData);
  };

  // set up subscriptions
  useEffect(() => {
    console.log('InstanceMenuButton useEffect messageBus subscribe...');
    messageBus.subscribe(messageBus.CONFIG_SERVICE_FRONTEND_CONFIG_CHANGED_EVENT, refreshAuthorizedInstances);

    return () => {
      console.log('InstanceMenuButton useEffect messageBus unsubscribe...');
      messageBus.unsubscribe(messageBus.CONFIG_SERVICE_FRONTEND_CONFIG_CHANGED_EVENT, refreshAuthorizedInstances);
    };
  }, []); // fire only on load and unload  


  return (
    <div style={wrapperStyle}>
      <Button
        color="primary"
        id="new-menu-button"
        aria-controls="new-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        // endIcon={<AddIcon />}
        sx={{
          minWidth: '200px',
          borderRadius: '18px', 
          '& .MuiSvgIcon-root': {
            paddingBottom: '2px'
          }, 
          textTransform: 'none'
        }}
      >
        { selectedInstances.length === 1
          ? configService.getBendableInstanceDisplayName(selectedInstances[0])
          : (selectedInstances.length === 0 ? 'Select Instances' : 'Multiple Instances')
        }
      </Button>
      <Menu
        id="new-menu"
        MenuListProps={{
          'aria-labelledby': 'new-menu-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        // {...props}
        sx={{
          '& .MuiPaper-root': {
            border: '0.5px solid #d0d0d0',
            borderRadius: 2,
            marginTop: theme.spacing(1),
            minWidth: 180,
            color:
              theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
            boxShadow:
              'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
              padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
              '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
              },
              '&:hover': {
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.main
              },
              // '&:active': {
              //   backgroundColor: alpha(
              //     theme.palette.primary.main,
              //     theme.palette.action.selectedOpacity,
              //   ),
              // },
            },
          },
        }}
      >
        { !_.isEmpty(authorizedInstances) && 
          _.map(authorizedInstances, (instanceData) => (
            <MenuItem
              key={instanceData.bendableInstance}
              onClick={() => handleClose(instanceData.bendableInstance)}
              disableRipple
            >
              { _.includes(selectedInstances, instanceData.bendableInstance) &&
                <CheckIcon style={{ fill: '#0A6E0A' }} />
              }
              { !_.includes(selectedInstances, instanceData.bendableInstance) &&
                <CloseIcon style={{ fill: '#6E0A0A' }} />
              }
              { instanceData.displayName }
            </MenuItem>
          ))
        }
      </Menu>
    </div>
  );
}

export default InstanceMenuButton;