import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { DateTime } from 'luxon';

import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import RefreshIcon from '@mui/icons-material/Refresh';
import GridIcon from '@mui/icons-material/GridView';

import authService from '../../app/authService';
import configService from '../../app/configService';
import messageBus from '../../app/messageBus';
import resourceDot from '../../assets/images/resource_dot.png';
import communityDot from '../../assets/images/community_dot.png';
import careerDot from '../../assets/images/career_dot.png';


import { 
  getDbObjectBins,
  selectOwnDraftDbObjectContainers,
  selectCanApproveSubmittedDbObjectContainers,
  selectOwnAssignedDbObjectContainers,
} from '../../app/dashboardSlice';

import {
  selectActiveAuthorizedBendableInstances,
} from '../../app/redux/authSlice';




const DashboardDbObjectContainersTable = (props) => {
  const { config, onCardAction } = props;
  const { ownDrafts, canApproveSubmitted, ownAssigned } = config;

  const widgetHeight = 270;
  const offsetFromHeight = 62;
  const firstItemPos = 70;

  const dispatch = useDispatch();

  const currentActiveAuthorizedBendableInstances = useSelector(selectActiveAuthorizedBendableInstances);
  const ownDraftDbObjectContainers = useSelector(selectOwnDraftDbObjectContainers);
  const canApproveSubmittedDbObjectContainers = useSelector(selectCanApproveSubmittedDbObjectContainers);
  const ownAssignedDbObjectContainers = useSelector(selectOwnAssignedDbObjectContainers);

  const [items, setItems] = useState([]);
  const [mouseInCard, setMouseInCard] = useState(false);    
  const [refresh, setRefresh] = useState(0);      // used to trigger a refresh of the component
  const [storeKey, setStoreKey] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);
  const [currentContextMenuItem, setCurrentContextMenuItem] = useState(null);

  const columns = [
    { key: 'title', label: 'Title', minWidth: 300 },
    { key: 'type', label: 'Type', minWidth: 200 },
    { key: 'status', label: 'Status', minWidth: 100 },
    { key: 'document_count', label: 'Quantity', minWidth: 100 },
    { key: 'creation_date', label: 'Created', minWidth: 150 }
  ];


  const triggerRefresh = useCallback(() => {
    // console.log('DashboardDbObjectContainersTable.triggerRefresh() called...');
    setRefresh(() => (Date.now()));
  }, []);

  // set up subscriptions
  useEffect(() => {
    // console.log('DashboardDbObjectContainersTable useEffect messageBus subscribe...');
    messageBus.subscribe(messageBus.DASHBOARD_DB_OBJECT_CONTAINERS_CHANGED_EVENT, triggerRefresh);

    return () => {
      messageBus.unsubscribe(messageBus.DASHBOARD_DB_OBJECT_CONTAINERS_CHANGED_EVENT, triggerRefresh);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // fire only on load and unload

  useEffect(() => {
    if (ownDrafts) {
      // console.log('DashboardDbObjectContainersTable config.ownDrafts called; ownDrafts : ', ownDrafts);
      setStoreKey('ownDraftDbObjectContainers');
      dispatch(getDbObjectBins({
        submittedById: authService.getUserId(),
        status: 'draft',
        storeKey: 'ownDraftDbObjectContainers'
      }));
    }
  }, [refresh, dispatch, ownDrafts]);


  useEffect(() => {
    if (canApproveSubmitted) {
      // console.log('DashboardDbObjectContainersTable config.canApproveSubmitted called; canApproveSubmitted : ', canApproveSubmitted);
      setStoreKey('canApproveSubmittedDbObjectContainers');
      dispatch(getDbObjectBins({
        filterByNeedsApproval: true,                    // only show items that need approval
        hasApproverUserId: false,                       // only objects that don't already have an approver user ID
        status: 'submitted',
        storeKey: 'canApproveSubmittedDbObjectContainers'
      }));
    }
  }, [refresh, dispatch, canApproveSubmitted]);


  useEffect(() => {
    if (ownAssigned) {                       // TODO: add in Resources with Bins
      // console.log('DashboardDbObjectContainersTable config.ownAssigned called; refresh : ', refresh);
      setStoreKey('ownAssignedDbObjectContainers');
      dispatch(getDbObjectBins({
        assignedToId: authService.getUserId(),
        status: 'submitted',
        storeKey: 'ownAssignedDbObjectContainers'
      }));
    }
  }, [refresh, dispatch, ownAssigned]);




  useEffect(() => {
    if (ownDrafts) {
      // console.log('DashboardDbObjectContainersTable useEffect() ownDraftDbObjectContainers: ', ownDraftDbObjectContainers);
      setItems(ownDraftDbObjectContainers);
    }
  }, [ownDraftDbObjectContainers, ownDrafts]);

  useEffect(() => {
    if (canApproveSubmitted) {
      // console.log('DashboardDbObjectContainersTable useEffect() canApproveSubmittedDbObjectContainers: ', canApproveSubmittedDbObjectContainers);
      setItems(canApproveSubmittedDbObjectContainers);
    }
  }, [canApproveSubmittedDbObjectContainers, canApproveSubmitted]);

  useEffect(() => {
    if (ownAssigned) {
      // console.log('DashboardDbObjectContainersTable useEffect() ownAssignedDbObjectContainers: ', ownAssignedDbObjectContainers);
      setItems(ownAssignedDbObjectContainers);
    }
  }, [ownAssignedDbObjectContainers, ownAssigned]);

  const onMouseOverHandler = () => {setMouseInCard(true);};
  const onMouseOutHandler = () => {setMouseInCard(false);};

  const handleToggleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    props.onToggle({ show: 'table' });
  };

  const refreshData = (event) => {   // force getOwnDbObjectShells() to fire
    event.preventDefault();
    event.stopPropagation();
    setRefresh(Date.now());
  };

  const getTitle = (item) => {
    if (item?.table_name === 'learning_objects') {
      let title = item?.raw_data?.resourceCard?.title;    // try DbObjectShell format
      if (!title) title = item?.title;                    // try DbObjectBin format
      return title;
    }
    if (item?.table_name === 'community_collections') {
      let title = item?.raw_data?.collectionCard?.title;
      if (!title) title = item?.title;                    // try DbObjectBin format
      return title;
    }
    if (item?.table_name === 'career_collections') {
      let title = item?.raw_data?.collectionCard?.title;
      if (!title) title = item?.title;                    // try DbObjectBin format
      return title;
    }
    if (item?.target_object_type === 'tag') {
      let title = item?.raw_data?.tenant;
      if (!title) title = item?.name;                    // try DbObjectBin format
      return title;
    }
    if (item?.target_object_type === 'topic') {
      let title = item?.raw_data?.tenant;
      if (!title) title = item?.name;                    // try DbObjectBin format
      return title;
    }
    if (item?.target_object_type === 'category') {
      let title = item?.raw_data?.title;
      if (!title) title = item?.title;                    // try DbObjectBin format
      return title;
    }
    if (item?.target_object_type === 'tenant') {
      let title = item?.raw_data?.tenant;
      if (!title) title = item?.title;                    // try DbObjectBin format
      return title;
    }
  };

  const getType = (tableNameOrType) => {
    if (tableNameOrType === 'learning_objects') return 'Resource';
    if (tableNameOrType === 'community_collections') return 'Community Collection';
    if (tableNameOrType === 'career_collections') return 'Career Collection';
    if (tableNameOrType === 'tag') return 'Tag';
    if (tableNameOrType === 'topic') return 'Topic';
    if (tableNameOrType === 'category') return 'Category';
    if (tableNameOrType === 'tenant') return 'Tenant';

  };

  const getImageIcon = (tableName) => {
    if (tableName === 'learning_objects') return (<img src={resourceDot} style={{ width: '24px' }} alt="icon" />);
    if (tableName === 'community_collections') return (<img src={communityDot} style={{ width: '24px' }} alt="icon" />);
    if (tableName === 'career_collections') return (<img src={careerDot} style={{ width: '24px' }} alt="icon" />);
  };

  

  const getStatus = (status) => {
    if (status === 'draft') return 'Draft';
    if (status === 'submitted') return 'Submitted';
    return '';  
  };

  const handleRowClick = (rowIndex) => {
    const item = items[rowIndex];
    const options = {
      action: 'click',
      item: item,
      storeKey: storeKey,              // get key that was set on init
      config: config,                  // augment with current config
    };             
    console.log('DashboardDbObjectContainersRow.handleCardAction() options: ', options);
    props.onCardAction(options);
  };  
  

  const handleContextMenu = (event) => {
    event.preventDefault();
    let eventTarget = event.target;
    let itemId = eventTarget && eventTarget.getAttribute('data-item-id');
    // if no itemId, use might have clicked a child in the row, so try the parents...
    if (eventTarget && !itemId) {
      do {
        eventTarget = eventTarget.parentElement;
        itemId = eventTarget && eventTarget.getAttribute('data-item-id');
      } while (!itemId && _.hasIn(eventTarget, 'parentElement'));
    }

    console.log('DbObjectContainerCard.handleContextMenu() itemId: ', itemId);
    const item = _.find(items, (item) => {
      return (item?._id === itemId);
    });
    setCurrentContextMenuItem(item);

    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null,
    );
  };

  const handleContextMenuClose = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setContextMenu(null);
    if (event.target.id === 'delete') {
      onCardAction({ action: 'delete', target: currentContextMenuItem?._id, objectType: currentContextMenuItem?.object_type, title: getTitle(currentContextMenuItem) });
    }
    if (event.target.id === 'resetToDraft') {
      onCardAction({ action: 'resetToDraft', target: currentContextMenuItem?._id, objectType: currentContextMenuItem?.object_type, title: getTitle(currentContextMenuItem) });
    }
  };


  const humanReadableActiveBendableInstances = _.map(currentActiveAuthorizedBendableInstances, (instance) => {
    return configService.getBendableInstanceDisplayName(instance);
  });


  return (
    <Box
      sx={{ 
        width: '100%',
        height: `${widgetHeight}px`,
        borderBottom: '1px solid #EADFCE',
        minHeight: 100,
        backgroundColor: '#F9F4EC',
        position: 'relative',
        overflow: 'hidden',
      }}
      onMouseOver={onMouseOverHandler}
      onMouseLeave={onMouseOutHandler}
    >

        <Box display="flex" flexDirection="row" sx={{ padding: `8px ${firstItemPos}px 0px ${firstItemPos}px` }}>
          <Box flexGrow={1}>
          <Typography 
              sx={{ 
                fontSize: '16px', 
                fontWeight: 'bold',
                fontFamily: 'Sharp Sans', 
              }} 
              color="textSecondary" 
              gutterBottom
            >
            { ownDrafts && `Things you're working on from ${humanReadableActiveBendableInstances.join(', ')}` }
            { canApproveSubmitted && 'Available to be picked up by you' }
            { ownAssigned && 'Assigned to you' }

            {/* { items.length - firstItemIndex } ^ { cardsPerScreen } */}

            </Typography>
          </Box>
          <Stack direction="row">
            <IconButton  
              onClick={handleToggleClick}
              sx={{
                padding: 0,
                display: mouseInCard ? 'inherit' : 'none'
              }}
            >
              <GridIcon sx={{ fontSize: '1.2rem' }} />
            </IconButton>

            <IconButton 
              onClick={refreshData} 
              sx={{
                padding: 0,
                marginLeft: '10px',
                display: mouseInCard ? 'inherit' : 'none'
              }}
            >
              <RefreshIcon sx={{ fontSize: '1.2rem' }} />
            </IconButton>
          </Stack>
        </Box>

        <Box sx={{ flexGrow: 1, padding: `8px ${firstItemPos}px 0px ${firstItemPos}px` }}>
          <TableContainer sx={{ maxHeight: `${widgetHeight - offsetFromHeight}px` }}>
            <Table stickyHeader size="small" aria-label="Drafts">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.key}
                      align={column.align}
                      style={{ minWidth: column.minWidth, backgroundColor: '#EADFCE' }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {items
                  .map((row, index) => {
                    const title = getTitle(row);
                    const createdAt = DateTime.fromMillis(row.created_at);

                    return (
                      <TableRow
                        hover 
                        role="checkbox" 
                        tabIndex={-1} 
                        key={index}
                        onClick={(event) => { 
                          event.stopPropagation();
                          handleRowClick(index);
                        }}
                        onContextMenu={handleContextMenu}
                      >
                        <TableCell align={columns[0].align} sx={{ width: '100%' }}>
                          <Stack direction="row" data-item-id={row._id}>
                            <div>{getImageIcon(row.table_name)}</div>
                            <div style={{ marginLeft: '8px', marginTop: '3px' }}>{title}</div>
                          </Stack>
                        </TableCell>
                        <TableCell align={columns[1].align} data-item-id={row._id}>
                          {getType(row.table_name || row.target_object_type)}
                        </TableCell>
                        <TableCell align={columns[2].align} data-item-id={row._id}>
                          {getStatus(row.status)}
                        </TableCell>
                        <TableCell align={columns[2].align} data-item-id={row._id}>
                          {row.document_count}
                        </TableCell>
                        <TableCell align={columns[3].align} data-item-id={row._id}>
                          {createdAt.toLocaleString()}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Menu
          open={contextMenu !== null}
          onClose={handleContextMenuClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <MenuItem id="resetToDraft" onClick={handleContextMenuClose} sx={{ opacity: '1.0' }}>Reset to draft</MenuItem>
          <MenuItem id="delete" onClick={handleContextMenuClose}>Delete</MenuItem>
          {/* <MenuItem id="test" onClick={handleContextMenuClose}>Test</MenuItem> */}
        </Menu>

    </Box>
  );
}

export default DashboardDbObjectContainersTable;