/**
 * Holds data that changes infrequently, such as Postgres table descriptions
 */

import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import callApi from './apiService';

const initialState = {
  postgresTableDescriptions: {},
  status: 'idle',
};

const fetchPostgresTableDescription = async (options) => {
  console.log('longTermCacheSlice.fetchPostgresTableDescription() options: ', options);
  try {
    const response = await callApi('getPostgressTableDescription', null, { params: options });
    console.log('longTermCacheSlice.fetchPostgresTableDescription() response: ', response);
    return response.data;   // axios attribute
  } catch (error) {
    console.error('longTermCacheSlice.fetchPostgresTableDescription() error: ', error.message);
  }
};


export const getPostgresTableDescription = createAsyncThunk(
  'longTermCacheSlice/fetchPostgresTableDescription',
  async (tableName) => {
    console.log('longTermCacheSlice.getPostgresTableDescription() tableName: ', tableName);
    const response = await fetchPostgresTableDescription({ tableName: tableName });
    // The value we return becomes the `fulfilled` action payload
    return response.payload;
  }
);



export const longTermCacheSlice = createSlice({
  name: 'longTermCache',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
    .addCase(getPostgresTableDescription.pending, (state) => {
      // console.log('longTermCacheSlice getPostgresTableDescription.pending state: ', state);
      state.status = 'loading';
    })
    .addCase(getPostgresTableDescription.fulfilled, (state, action) => {
      state.status = 'idle';
      console.log('longTermCacheSlice getPostgresTableDescription.fulfilled action: ', action);
      state.postgresTableDescriptions[action.payload.table_name] = action.payload;
      console.log('longTermCacheSlice getPostgresTableDescription.fulfilled state: ', current(state));
    });
  },
});

// export const { 
// } = longTermCacheSlice.actions;

// for useSelector; state is complete store, not slice
export const selectPostgresTableDescriptions = (state) => state.longTermCache.postgresTableDescriptions;

export default longTermCacheSlice.reducer;
