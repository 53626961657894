import { createSlice, current, createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash-es';
// import eachDeep from 'deepdash-es/eachDeep';

import callApi from '../apiService';
// import { getHash } from './authService';

const initialState = {
  systemStatuses: [],
  templates: [],
  activeTemplateIsDirty: false,
  activeTemplate: {},
  ingestAndBuildSourceInstance: '',
  ingestAction: {},
  ingestActionLookbackMinutes: null,
  transferSourceInstance: '',
  transferAction: {},
  deleteAction: {},
  deleteAfterTimeStamp: null,
  deleteBeforeTimeStamp: null,
  deleteOnlyProviderId: null,
  deleteTimeStampType: 'updated',
  instanceSettingsTargetInstance: '',
  systemEventsLookbackData: {
    index: 1,                                 // 1-indexed so that sx styles work
    lookbackMilliseconds: 1000 * 60 * 60
  },
  systemEventsTimestampFormatData: {
    index: 1,                                 // 1-indexed so that sx styles work
    timestampFormat: 'local'
  },
  currentSystemEventCorrelationId: null,
};


const doFetchSystemStatuses = async (params) => {
  console.log('adminSlice.doFetchSystemStatuses() params: ', params);
  try {
    const apiResponse = await callApi('getSystemStatuses', null, { params: params });
    console.log('adminSlice.doFetchSystemStatuses() apiResponse: ', apiResponse);
    return apiResponse;
  } catch (err) {
    throw err;
  }
};

export const fetchSystemStatuses = createAsyncThunk(
  'admin/fetchSystemStatuses',
  async (options) => {
    console.log('adminSlice.fetchSystemStatuses() options: ', options);
    try {
      const apiResponse = await doFetchSystemStatuses(options);
      // The value we return becomes the `fulfilled` action payload
      console.log('adminSlice.fetchSystemStatuses() apiResponse.data.payload: ', apiResponse.data.payload);
      return apiResponse.data && apiResponse.data.payload;
    } catch (err) {
      throw err;
    }
  }
);


const doFetchTemplates = async (params) => {
  console.log('adminSlice.doFetchTemplates() params: ', params);
  try {
    const apiResponse = await callApi('getTemplates', null, { params: params });
    console.log('adminSlice.doFetchTemplates() apiResponse: ', apiResponse);
    return apiResponse;
  } catch (err) {
    throw err;
  }
};

export const fetchTemplates = createAsyncThunk(
  'admin/fetchTemplates',
  async (options) => {
    console.log('adminSlice.fetchTemplates() options: ', options);
    try {
      const apiResponse = await doFetchTemplates(options);
      // The value we return becomes the `fulfilled` action payload
      console.log('adminSlice.fetchTemplates() apiResponse.data.payload: ', apiResponse.data.payload);
      return apiResponse.data && apiResponse.data.payload;
    } catch (err) {
      throw err;
    }
  }
);

const doSaveActiveTemplate = async (options, thunkApi) => {
  console.log('adminSlice.doSaveActiveTemplate() options: ', options);
  let substitutionParams = { templateId: 'create' };
  const state = thunkApi.getState();
  console.log('adminSlice.doSaveActiveTemplate() state: ', state);

  const activeTemplate = state && state.admin && state.admin.activeTemplate;
  if (activeTemplate && activeTemplate._id) substitutionParams = { templateId: activeTemplate._id };
  let mutableActiveTemplate = _.cloneDeep(activeTemplate);
  // options.templateType is set from a Switch that may never have been touched, which means the activeTemplate.handlebars_type could be missing
  mutableActiveTemplate.handlebars_type = options.templateType;
  delete mutableActiveTemplate.history;                         // don't attempt to send this; no update is needed and it can be large
  const data = { template: mutableActiveTemplate };
  console.log('adminSlice.doSaveActiveTemplate() substitutionParams, data: ', substitutionParams, data);
  try {
    const apiResponse = await callApi('upsertTemplate', substitutionParams, data);
    console.log('adminSlice.doSaveActiveTemplate() apiResponse: ', apiResponse);
    return apiResponse;
  } catch (err) {
    throw err;
  }
};

export const saveActiveTemplate = createAsyncThunk(
  'admin/saveActiveTemplate',
  async (options, thunkApi) => {
    console.log('adminSlice.saveActiveTemplate() options: ', options);
    try {
      const apiResponse = await doSaveActiveTemplate(options, thunkApi);
      // The value we return becomes the `fulfilled` action payload
      console.log('adminSlice.saveActiveTemplate() apiResponse.data.payload: ', apiResponse.data.payload);
      return apiResponse.data && apiResponse.data.payload;
    } catch (err) {
      throw err;
    }
  }
);


const doDeleteTemplate = async (params) => {
  console.log('adminSlice.doDeleteTemplate() params: ', params);
  try {
    const apiResponse = await callApi('deleteTemplate', { templateId: params.templateId}, {});
    console.log('adminSlice.doDeleteTemplate() apiResponse: ', apiResponse);
    return apiResponse;
  } catch (err) {
    throw err;
  }
};

export const deleteTemplate = createAsyncThunk(
  'admin/deleteTemplate',
  async (options) => {
    console.log('adminSlice.deleteTemplate() options: ', options);
    try {
      const apiResponse = await doDeleteTemplate(options);
      // The value we return becomes the `fulfilled` action payload
      console.log('adminSlice.deleteTemplate() apiResponse.data.payload: ', apiResponse.data);
      const fullResponse = {
        options: options,
        data: apiResponse.data
      }
      return fullResponse;
    } catch (err) {
      throw err;
    }
  }
);



export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setActiveTemplate: (state, action) => {
      state.activeTemplate = (action && action.payload && action.payload.template) || {};
      state.activeTemplateIsDirty = false;
    },
    updateActiveTemplate: (state, action) => {
      _.merge(state.activeTemplate, action.payload);
      state.activeTemplateIsDirty = true;
      // debug
      console.log('adminSlice updateActiveTemplate() state: ', current(state));
    },
    clearActiveTemplate: (state, action) => {
      state.activeTemplate = {};
      state.activeTemplateIsDirty = false;
    },
    setIngestAndBuildSourceInstance: (state, action) => {
      state.ingestAndBuildSourceInstance = (action?.payload?.ingestAndBuildSourceInstance) || null;
    },
    setIngestAction: (state, action) => {
      console.log('adminSlice setIngestAction() before set; state: ', current(state));
      console.log('adminSlice setIngestAction() action.payload: ', action?.payload);
      state.ingestAction = (action?.payload?.ingestAction) || null;
      console.log('adminSlice setIngestAction() after set; state: ', current(state));
    },
    setIngestActionLookbackMinutes: (state, action) => {
      console.log('adminSlice setIngestActionLookbackMinutes() before set; state: ', current(state));
      console.log('adminSlice setIngestActionLookbackMinutes() action.payload: ', action?.payload);
      state.ingestActionLookbackMinutes = (action?.payload?.ingestActionLookbackMinutes) || null;
      console.log('adminSlice setIngestActionLookbackMinutes() after set; state: ', current(state));
    },
    setTransferSourceInstance: (state, action) => {
      state.transferSourceInstance = (action?.payload?.transferSourceInstance) || null;
    },
    setTransferAction: (state, action) => {
      console.log('adminSlice setTransferAction() before set; state: ', current(state));
      console.log('adminSlice setTransferAction() action.payload: ', action?.payload);
      state.transferAction = (action?.payload?.transferAction) || null;
      console.log('adminSlice setTransferAction() after set; state: ', current(state));
    },
    setDeleteAction: (state, action) => {
      console.log('adminSlice setDeleteAction() before set; state: ', current(state));
      console.log('adminSlice setDeleteAction() action.payload: ', action?.payload);
      state.deleteAction = (action?.payload?.deleteAction) || null;
      console.log('adminSlice setDeleteAction() after set; state: ', current(state));
    },
    setDeleteTimeStampType: (state, action) => {
      console.log('adminSlice setDeleteTimeStampType() before set; state: ', current(state));
      console.log('adminSlice setDeleteTimeStampType() action.payload: ', action?.payload);
      state.deleteTimeStampType = (action?.payload) || null;
      console.log('adminSlice setDeleteTimeStampType() after set; state: ', current(state));
    },
    setDeleteAfterTimeStamp: (state, action) => {
      state.deleteAfterTimeStamp = (action?.payload) || null;
    },
    setDeleteBeforeTimeStamp: (state, action) => {
      state.deleteBeforeTimeStamp = (action?.payload) || null;
    },
    setDeleteOnlyProviderId: (state, action) => {
      state.deleteOnlyProviderId = (action?.payload) || null;
    },
    setInstanceSettingsTargetInstance: (state, action) => {
      state.instanceSettingsTargetInstance = (action?.payload) || null;
    },
    setSystemEventsLookbackData: (state, action) => {
      console.log('adminSlice setSystemEventsLookbackData() state: ', current(state));
      console.log('adminSlice setSystemEventsLookbackData() action.payload: ', action?.payload);
      state.systemEventsLookbackData = action?.payload || null;
    },
    setSystemEventsTimestampFormatData: (state, action) => {
      console.log('adminSlice setSystemEventsTimestampFormatData() state: ', current(state));
      console.log('adminSlice setSystemEventsTimestampFormatData() action.payload: ', action?.payload);
      state.systemEventsTimestampFormatData = action?.payload || null;
    },
    setCurrentSystemEventCorrelationId: (state, action) => {
      console.log('adminSlice setCurrentSystemEventCorrelationId() state: ', current(state));
      console.log('adminSlice setCurrentSystemEventCorrelationId() action.payload: ', action?.payload);
      state.currentSystemEventCorrelationId = action?.payload || null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSystemStatuses.pending, (state) => {
        console.log('adminSlice fetchSystemStatuses.pending state: ', state);
        state.status = 'loading';
      })
      .addCase(fetchSystemStatuses.fulfilled, (state, action) => {
        state.status = 'idle';
        console.log('adminSlice fetchSystemStatuses.fulfilled action: ', action);
        state.systemStatuses = action.payload && action.payload;
      })
      .addCase(saveActiveTemplate.pending, (state) => {
        console.log('adminSlice saveActiveTemplate.pending state: ', state);
        state.status = 'loading';
      })
      .addCase(saveActiveTemplate.fulfilled, (state, action) => {
        state.status = 'idle';
        console.log('adminSlice saveActiveTemplate.fulfilled action: ', action);
        state.activeTemplateIsDirty = false;
        // update the 'templates' cache in this store
        const activeTemplateId = state.activeTemplate._id;
        if (activeTemplateId) { // doing an edit
          const targetIndex = _.findIndex(state.templates, (template) => {
            return (template._id === activeTemplateId);
          });
          if (targetIndex > -1) {
            state.templates[targetIndex] = state.activeTemplate;
          }
        } else {    // newly created template
          const newId = action.payload && action.payload.document && action.payload.document._id;
          state.activeTemplate._id = newId;
          state.templates.push(state.activeTemplate);
        }
      })
      .addCase(fetchTemplates.pending, (state) => {
        //console.log('adminSlice fetchTemplates.pending state: ', state);
        state.status = 'loading';
      })
      .addCase(fetchTemplates.fulfilled, (state, action) => {
        state.status = 'idle';
        //console.log('adminSlice fetchTemplates.fulfilled action: ', action);
        state.templates = action.payload && action.payload.documents;
      })
      .addCase(deleteTemplate.pending, (state) => {
        //console.log('adminSlice deleteTemplate.pending state: ', state);
        state.status = 'loading';
      })
      .addCase(deleteTemplate.fulfilled, (state, action) => {
        state.status = 'idle';
        console.log('adminSlice deleteTemplate.fulfilled action: ', action);
        const status = action.payload.data.status;
        const templateId = action.payload.options.templateId;
        console.log('adminSlice deleteTemplate.fulfilled status, templateId: ', status, templateId);
        if ((status === 'success') && templateId) {
          _.remove(state.templates, (template) => {
            return (template._id === templateId);
          });
          state.activeTemplate = {};
        }
      });

  },
});

export const {
  setActiveTemplate,
  updateActiveTemplate,
  clearActiveTemplate,
  setIngestAndBuildSourceInstance,
  setIngestAction,
  setIngestActionLookbackMinutes,
  setTransferSourceInstance,
  setTransferAction,
  setDeleteAction,
  setDeleteTimeStampType,
  setDeleteAfterTimeStamp,
  setDeleteBeforeTimeStamp,
  setDeleteOnlyProviderId,
  setInstanceSettingsTargetInstance,
  setSystemEventsLookbackData,
  setSystemEventsTimestampFormatData,
  setCurrentSystemEventCorrelationId
} = adminSlice.actions;

// for useSelector; state is complete store, not slice
export const selectAllSystemStatuses = (state) => state.admin.systemStatuses;
export const selectAllTemplates = (state) => state.admin.templates;
export const selectActiveTemplateIsDirty = (state) => state.admin.activeTemplateIsDirty;
export const selectActiveTemplate = (state) => state.admin.activeTemplate;
export const selectIngestAndBuildSourceInstance = (state) => state.admin.ingestAndBuildSourceInstance;
export const selectIngestAction = (state) => state.admin.ingestAction;
export const selectIngestActionLookbackMinutes = (state) => state.admin.ingestActionLookbackMinutes;
export const selectTransferSourceInstance = (state) => state.admin.transferSourceInstance;
export const selectTransferAction = (state) => state.admin.transferAction;
export const selectDeleteAction = (state) => state.admin.deleteAction;
export const selectDeleteTimeStampType = (state) => state.admin.deleteTimeStampType;
export const selectDeleteAfterTimeStamp = (state) => state.admin.deleteAfterTimeStamp;
export const selectDeleteBeforeTimeStamp = (state) => state.admin.deleteBeforeTimeStamp;
export const selectDeleteOnlyProviderId = (state) => state.admin.deleteOnlyProviderId;
export const selectInstanceSettingsTargetInstance = (state) => state.admin.instanceSettingsTargetInstance;
export const selectSystemEventsLookbackData = (state) => state.admin.systemEventsLookbackData;
export const selectSystemEventsTimestampFormatData = (state) => state.admin.systemEventsTimestampFormatData;
export const selectCurrentSystemEventCorrelationId = (state) => state.admin.currentSystemEventCorrelationId;


export default adminSlice.reducer;
