import React from 'react';
import { Navigate } from "react-router-dom";

const Redirect = (props) => {
  const {to, replace = true } = props;

  return (
    <Navigate to={to} replace={replace} />
  );
};

export default Redirect;