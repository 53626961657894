import * as React from 'react';

import authService from '../../app/authService';

import { ROUTES } from '../../constants';

import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const ViewMenuButton = (props) => {
  const {
    wrapperStyle,
    onSelect,
  } = props;

  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const isSuperUser = authService.isSuperUser();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);    // disable until we need a full menu
    // onSelect('newLearningObject');    // temp until we need a full menu
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    const innerText = event.target.innerText;
    console.log('ViewMenuButton.handleClose; innerText: ', innerText);
    if (innerText === 'Resources') onSelect('viewResources');
    if (innerText === 'Providers') onSelect('viewProviders');
    if (innerText === 'Tags') onSelect('viewTags');
    if (innerText === 'Topics') onSelect('viewTopics');
    if (innerText === 'Categories') onSelect('viewCategories');
    if (innerText === 'Tenants') onSelect('viewTenants');
    if (innerText === 'Spotlight Slides') onSelect('viewSpotlightSlides');
    if (innerText === 'Spotlight Containers') onSelect('viewSpotlightCollections');
    if (innerText === 'User Insights') onSelect(ROUTES.USER_INSIGHTS_DASHBOARD);
  };

  return (
    <div style={wrapperStyle}>
      <Button
        color="primary"
        id="new-menu-button"
        aria-controls="new-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        // endIcon={<AddIcon />}
        sx={{
          width: '120px',
          borderRadius: '18px',
          '& .MuiSvgIcon-root': {
            paddingBottom: '2px'
          },
          textTransform: 'none'
        }}
      >
        View
      </Button>
      <Menu
        id="new-menu"
        MenuListProps={{
          'aria-labelledby': 'new-menu-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        // {...props}
        sx={{
          '& .MuiPaper-root': {
            border: '0.5px solid #d0d0d0',
            borderRadius: 2,
            marginTop: theme.spacing(1),
            minWidth: 180,
            color:
              theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
            boxShadow:
              'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
              padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
              '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
              },
              '&:hover': {
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.main
              },
              // '&:active': {
              //   backgroundColor: alpha(
              //     theme.palette.primary.main,
              //     theme.palette.action.selectedOpacity,
              //   ),
              // },
            },
          },
        }}
      >

        { isSuperUser &&
        <MenuItem onClick={handleClose} disableRipple>
          Resources
        </MenuItem>
        }

        { isSuperUser &&
        <MenuItem onClick={handleClose} disableRipple>
          Providers
        </MenuItem>
        }
        { isSuperUser &&
        <MenuItem onClick={handleClose} disableRipple>
          Tags
        </MenuItem>
        }
        { isSuperUser &&
        <MenuItem onClick={handleClose} disableRipple>
          Topics
        </MenuItem>
        }
        { isSuperUser &&
        <MenuItem onClick={handleClose} disableRipple>
          Categories
        </MenuItem>
        }
        { isSuperUser &&
        <MenuItem onClick={handleClose} disableRipple>
          Tenants
        </MenuItem>
        }

        { isSuperUser &&
        <Divider sx={{ my: 0.5 }} />
        }

        <MenuItem onClick={handleClose} disableRipple>
          Spotlight Slides
        </MenuItem>

        <MenuItem onClick={handleClose} disableRipple>
          Spotlight Containers
        </MenuItem>

        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={handleClose} disableRipple>
          User Insights
        </MenuItem>

      </Menu>
    </div>
  );
}

export default ViewMenuButton;
