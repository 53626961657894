/**
 * Holds authService data
 */

import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
// import callApi from './apiService';

const initialState = {
  activeAuthorizedBendableInstances: [],
  status: 'idle',
};

// const fetchPostgresTableDescription = async (options) => {
//   console.log('authSlice.fetchPostgresTableDescription() options: ', options);
//   try {
//     const response = await callApi('getPostgressTableDescription', null, { params: options });
//     console.log('authSlice.fetchPostgresTableDescription() response: ', response);
//     return response.data;   // axios attribute
//   } catch (error) {
//     console.error('authSlice.fetchPostgresTableDescription() error: ', error.message);
//   }
// };


// export const getPostgresTableDescription = createAsyncThunk(
//   'authSlice/fetchPostgresTableDescription',
//   async (tableName) => {
//     console.log('authSlice.getPostgresTableDescription() tableName: ', tableName);
//     const response = await fetchPostgresTableDescription({ tableName: tableName });
//     // The value we return becomes the `fulfilled` action payload
//     return response.payload;
//   }
// );



export const authSlice = createSlice({
  name: 'authData',
  initialState,
  reducers: {
    setActiveAuthorizedBendableInstances: (state, action) => {
      console.log('authSlice setActiveAuthorizedBendableInstances; action.payload: ', action.payload);
      state.activeAuthorizedBendableInstances = action.payload;
      console.log('authSlice setActiveAuthorizedBendableInstances; state after set: ', current(state));
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //   .addCase(getPostgresTableDescription.pending, (state) => {
  //     // console.log('authSlice getPostgresTableDescription.pending state: ', state);
  //     state.status = 'loading';
  //   })
  //   .addCase(getPostgresTableDescription.fulfilled, (state, action) => {
  //     state.status = 'idle';
  //     console.log('authSlice getPostgresTableDescription.fulfilled action: ', action);
  //     state.postgresTableDescriptions[action.payload.table_name] = action.payload;
  //     console.log('authSlice getPostgresTableDescription.fulfilled state: ', current(state));
  //   });
  // },
});

export const { 
  setActiveAuthorizedBendableInstances,
} = authSlice.actions;

// for useSelector; state is complete store, not slice
export const selectActiveAuthorizedBendableInstances = (state) => state.authData.activeAuthorizedBendableInstances;

export default authSlice.reducer;
