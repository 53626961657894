import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash-es';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useTheme } from '@mui/styles';

import {
  selectAllInstanceData,
  getInstanceData
} from '../../app/instanceDataSlice';

import selectOptionsService from '../../app/selectOptionsService';

const ResourceWizardSelectInput = (props) => {
  const { stepGroup, stepGroupDisplayName, stepNumber, question, required, prompt, fieldName, label, 
    optionLabel, isStatic, targetInstance, tableName, queryOptions, disabled, noBreadcrumb, noPadding } = props;

  const initialValue = useMemo(() => {
    return props.initialValue || '';
  }, [props.initialValue]);
  // console.log('ResourceWizardSelectInput initialValue: ', initialValue);

  const dispatch = useDispatch();
  const allInstanceData = useSelector(selectAllInstanceData);
  // console.log('ResourceWizardSelectInput allInstanceData: ', allInstanceData);

  const instanceData = useMemo(() => {
    return (allInstanceData && allInstanceData[targetInstance]) || {};
  }, [allInstanceData, targetInstance]);
  // console.log('ResourceWizardSelectInput instanceData: ', instanceData);

  const initialOptionsData = isStatic ? selectOptionsService.getStaticSelectOptions(fieldName) : (instanceData[tableName] || []);
  console.log('ResourceWizardSelectInput fieldName, initialOptionsData: ', fieldName, initialOptionsData);
  const [needToRequestOptionsData, setNeedToRequestOptionsData] = useState(_.isEmpty(initialOptionsData));
  const [optionsData, setOptionsData] = useState(initialOptionsData);
  // console.log('ResourceWizardSelectInput optionsData: ', optionsData);

  // request optionsData as needed
  useEffect(() => {
    setNeedToRequestOptionsData((prevValue) => {
      console.log('ResourceWizardSelectInput setNeedToRequestOptionsData prevValue: ', prevValue);
      if (prevValue && targetInstance && tableName) {
        console.log('ResourceWizardSelectInput setNeedToRequestOptionsData about to dispatch: ', targetInstance, tableName);
        dispatch(getInstanceData({
          targetInstance: targetInstance,
          tableName: tableName,
          queryOptions: queryOptions,
          limit: 'all'
        }));
      }
      return false;
    });
  }, [needToRequestOptionsData, dispatch, targetInstance, tableName, queryOptions]);

  const theme = useTheme();
  const [selectedValue, setSelectedValue] = useState(null);

  const star = (<span style={{ color: 'red' }}>*</span>);

  const handleAutocompleteOnChange = (event, value) => {
    // console.log('ResourceWizardSelectInput.handleAutocompleteOnChange() event, value: ', event, value);
    setSelectedValue(value);
    props.onChange({ fieldName: props.fieldName, fieldValue: value });
  };

  const getOptionLabel = (option) => {
    if (option[optionLabel]) return option[optionLabel];
    return '';
  };

  // reset when 'question' changes because this component is used by parents who are siblings, so we don't always 
  // get a full re-render as if starting from scratch
  useEffect(() => {
    // console.log('ResourceWizardSelectInput useEffect; instanceData, tableName, fieldName, isStatic: ', instanceData, tableName, fieldName, isStatic);
    if (isStatic) {
      setOptionsData((prevValue) => (selectOptionsService.getStaticSelectOptions(fieldName)));
    } else {
      const data = instanceData[tableName] || [];
      setOptionsData((prevValue) => (data));
      setNeedToRequestOptionsData(_.isEmpty(data));
    }
  }, [question, instanceData, tableName, fieldName, isStatic]);

  useEffect(() => {
    // console.log('ResourceWizardSelectInput useEffect question, initialValue; initialValue: ', initialValue);
    setSelectedValue((prevValue) => (initialValue || null));
  }, [question, initialValue]);

  

  return (
    <React.Fragment>
      <Box display="flex" flexDirection="column" style={{ width: '500px' }}>
        <Box style={{ color: theme.palette.secondary.main, fontSize: '0.7rem', textTransform: 'uppercase', display: noBreadcrumb ? 'none' : 'block' }}>
          {stepGroupDisplayName || stepGroup}: STEP {stepNumber}
        </Box>
        <Box style={{ fontSize: '1.2rem', fontWeight: 'bold', padding: noPadding ? '0 0' : '16px 0' }}>
          {question} {required && star}
        </Box>
        <Box style={{ 
          color: theme.palette.gray.medium, 
          fontSize: '1.2rem',
          fontStyle: 'italic'
        }}>
          {prompt}
        </Box>
        <Box sx={{ paddingTop: noPadding ? '0' : '40px' }}>
          <Autocomplete
            disablePortal
            // id="combo-box-demo"
            options={optionsData}
            getOptionLabel={getOptionLabel}
            multiple={false}
            disabled={disabled}
            value={selectedValue}
            // isOptionEqualToValue={(option, value) => (option._id === value._id)}
            onChange={handleAutocompleteOnChange}
            sx={{ width: 500 }}
            renderInput={(params) => <TextField {...params} label={label} />}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default ResourceWizardSelectInput;