import React, { Suspense, lazy } from 'react';
import _ from 'lodash-es';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { ThemeProvider } from '@material-ui/core/styles';
// import { ThemeProvider as ThemeProviderV5 } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from './themes/bendableLight';
import Modal from 'react-modal';

// import Box from '@mui/material/Box';

// import PrivateRoute from './components/privateRoute/PrivateRoute';
import Redirect from './app/Redirect';
// import MiniDrawer from './components/miniDrawer/MiniDrawer';
import LoginPage from './pages/LoginPage';
// import Dashboard from './pages/Dashboard';
// import ManagePage from './pages/ManagePage';
// import ManageSummaryPage from './pages/ManageSummaryPage';
// import ManageDetailPage from './pages/ManageDetailPage';
// import BendableBoardsPage from './pages/BendableBoardsPage';
// import BendableBoardsBoardDetailPage from './pages/BendableBoardsBoardDetailPage';
// import BendableBoardsBoardResponsesPage from './pages/BendableBoardsBoardResponsesPage';
// import styles from './App.module.css';
// import CreatePage from './pages/CreatePage';
// import CreateWizardPage from './pages/CreateWizardPage';
// import AdminTemplatesSummaryPage from './pages/AdminTemplatesSummaryPage';
// import AdminTemplatesDetailPage from './pages/AdminTemplatesDetailPage';
// import AppNavBar from './components/appNavBar/AppNavBar';
// import ResourceWizardPage from './pages/ResourceWizardPage';
// import ResourceShellsSummaryPage from './pages/ResourceShellsSummaryPage';
// import bendableLightV2Theme from './themes/bendableLightV2';

// import ResourceWizardTextInput from './components/resourceWizard/ResourceWizardTextInput';
import AuthProvider, { RequireAuth } from './app/AuthProvider';
// import BulkUploadResourcesPage from './pages/BulkUploadResourcesPage';
import Rehydrator from './app/Rehydrator';
import AppNavBarWithRoutes from './app/AppNavBarWithRoutes';

// code-splitting...
// const CreateWizardPage = lazy(() => import('./pages/CreateWizardPage'));
// const AdminPage = lazy(() => import('./pages/AdminPage'));
// const ResourceWizardPage = lazy(() => import('./pages/ResourceWizardPage'));
// const CollectionWizardPage = lazy(() => import('./pages/CollectionWizardPage'));
// const CareerCollectionWizardPage = lazy(() => import('./pages/CareerCollectionWizardPage'));
// const BulkEditResourcesPage = lazy(() => import('./pages/BulkEditResourcesPage'));
// const BulkViewResourcesPage = lazy(() => import('./pages/BulkViewResourcesPage'));
// const SpotlightSlidesSummaryPage = lazy(() => import('./pages/SpotlightSlidesSummaryPage'));
// const SpotlightSlideWizardPage = lazy(() => import('./pages/SpotlightSlideWizardPage'));
// const SpotlightCollectionsSummaryPage = lazy(() => import('./pages/SpotlightCollectionsSummaryPage'));
// const SpotlightCollectionWizardPage = lazy(() => import('./pages/SpotlightCollectionWizardPage'));


// Bind modal to appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#modal');

// const appNavBarWithRoutes = (
//   <ThemeProviderV5 theme={bendableLightV2Theme}>
//     <AppNavBar>
//       <Suspense fallback={<Box display="flex" justifyContent="center" m={1} p={1}>
//               <span style={{ fontSize: '1.5rem' }}>Loading...</span>
//             </Box>}>
//         <Routes>
//           <Route path="admin/*" element={<RequireAuth><AdminPage /></RequireAuth>} />
//           <Route path="dashboard" element={<RequireAuth><Dashboard /></RequireAuth>} />
//           <Route path="resourceShellsSummary/*" element={<RequireAuth><ResourceShellsSummaryPage /></RequireAuth>} />
//           <Route path="resourceWizard/*" element={<RequireAuth><ResourceWizardPage /></RequireAuth>} />
//           <Route path="bulkResourceUpload/*" element={<RequireAuth><BulkUploadResourcesPage /></RequireAuth>} />
//           <Route path="collections/*" element={<RequireAuth><CollectionWizardPage /></RequireAuth>} />
//           <Route path="careerCollections/*" element={<RequireAuth><CareerCollectionWizardPage /></RequireAuth>} />
//           <Route path="editResources/:binId/*" element={<RequireAuth><BulkEditResourcesPage /></RequireAuth>} />
//           <Route path="viewResources/*" element={<RequireAuth><BulkViewResourcesPage /></RequireAuth>} />
//           <Route path="spotlightSlidesSummary/*" element={<RequireAuth><SpotlightSlidesSummaryPage /></RequireAuth>} />
//           <Route path="spotlightSlides/*" element={<RequireAuth><SpotlightSlideWizardPage /></RequireAuth>} />
//           <Route path="spotlightCollectionsSummary/*" element={<RequireAuth><SpotlightCollectionsSummaryPage /></RequireAuth>} />
//           <Route path="spotlightCollections/*" element={<RequireAuth><SpotlightCollectionWizardPage /></RequireAuth>} />
//         </Routes>
//       </Suspense>
//     </AppNavBar>
//   </ThemeProviderV5>
// );


/**
 * Wrap child of Router separately so that useLocation() is allowed
 * 
 * @returns 
 */
const RouterChild = () => {
  // set the api version being requested on the URL
  let uiVersion = '1.0';
  let location = useLocation();
  const pathname = location.pathname;
  console.log('App RouterChild pathname: ', pathname);
  const secondSlash = pathname.indexOf('/', 1);
  if (secondSlash > -1) {
    const version = pathname.substring(1, secondSlash);
    // console.log('App RouterChild version: ', version);
    try {
      const versionFloat = parseFloat(version);
      if (_.isNumber(versionFloat)) {
        uiVersion = version;
      }      
    } catch (err) {}
  }

  const [dataLoadingComplete, setDataLoadingComplete] = React.useState(false);

  const handleRehydratorComplete = () => {
    console.log('App RouterChild handleRehydratorComplete called...');
    setDataLoadingComplete(true);
  };

  return (
    <AuthProvider uiVersion={uiVersion}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <CssBaseline />
          <Rehydrator onComplete={handleRehydratorComplete}/>
          { dataLoadingComplete &&
            <Routes>
              <Route path="/1.1/login" element={<LoginPage />} />         {/* Version 1.1 Login */}
              <Route path="/" element={<Redirect to="/1.1/login" />} />   {/* Catch-all */}
              <Route path="/1.1/*" element={<AppNavBarWithRoutes />} />   {/* Version 1.1 routes */}
            </Routes>
          }  
        </SnackbarProvider>
      </ThemeProvider>
    </AuthProvider>
  );
};

function App() {
  return (
    <Router>
      <RouterChild />
    </Router>
  );
}

export default App;
