/*
 * Renders a display text string from a date string:
 *  2019-10-15T07:00:00.000Z
 * 
 */

import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import _ from 'lodash-es';

import Box from '@mui/material/Box';

const TimestampRenderer = (props) => {
  // console.log('TimestampRenderer props: ', props);
  const rawCellValue = props.valueFormatted ? props.valueFormatted : props.value;     // ID in Postgres
  //console.log('TimestampRenderer rawCellValue: ', rawCellValue);
  
  const [displayDate, setDisplayDate] = useState('');

  const params = props.colDef.cellRendererParams;

  useEffect(() => {
    let luxonDate;
    if (_.isString(rawCellValue)) luxonDate = DateTime.fromISO(rawCellValue);
    if (_.isNumber(rawCellValue)) luxonDate = DateTime.fromMillis(rawCellValue);
    if (luxonDate && luxonDate.isValid) {
      if (params.format) {
        setDisplayDate(luxonDate.toFormat(params.format));
      } else {
        setDisplayDate(luxonDate.toLocaleString());
      }
    } else {
      setDisplayDate('----');
    }
  }, [rawCellValue, params.format]);

  let textColor = 'black';
  if (props?.colDef?.field === 'publish_at') textColor = props?.data?.publish_at_color;
  if (props?.colDef?.field === 'unpublish_at') textColor = props?.data?.unpublish_at_color;

  return (
    <Box
      sx={{
        color: textColor,
      }}
    >
      {displayDate}
    </Box>
  );
};

export default TimestampRenderer;
