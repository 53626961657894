/*
 * Provide dynamic, instance-specific data to HTML select controls
 */

import callApi from "./apiService";
import { getKeyHash } from "./selectOptionsSlice";


const selectOptionsService = {

  isInited: true,

  // Many cellRenderers in a grid column may simultaneously request options data from Redux, fail to find what they need,
  // and then dispatch a thunk to request what they need from the API. This will lead to many simultaneous 
  // requests for the same data from the API. 'pendingRequests' tracks requests in progress so that the 
  // same request is not sent simultaneously to the API.

  pendingRequests: {},

  getSelectOptions: async (options) => {
    const { objectType, bendableInstance, typeFilter = null } = options;
    try {
      if (!objectType) {
        console.log('ERROR: selectOptionsService missing objectType...');
        return null;
      }
      if (!bendableInstance) {
        console.log('ERROR: selectOptionsService missing bendableInstance...');
        return null;
      }

      const hash = getKeyHash({ objectType, bendableInstance, typeFilter });
      if (selectOptionsService.pendingRequests[hash]) {
        console.log('selectOptionsService.getSelectOptions() abort, request is pending; hash: ', hash);
        return null;   // abort this request; an exact duplicate is already running
      }

      console.log('selectOptionsService.getSelectOptions() options: ', options);
      selectOptionsService.pendingRequests[hash] = true;

      const response = await callApi('getSelectOptions', null, { params: options });
      let selectOptions = (response && response.data && response.data.payload) || [];
      console.log('selectOptionsService.getSelectOptions() selectOptions: ', selectOptions);
      selectOptionsService.pendingRequests[hash] = false;
      
      return selectOptions;

    } catch (error) {
      console.error('selectOptionsService.getSelectOptions() error: ', error.message);
    }
  },

  /**
   * Static Learning Format select options for storing in "character varying" Postgres field
   */
  getStaticSelectOptions: (type, prependType) => {
    let response = [];

    if (type === 'learning_format') {
      response = [
        {
          "displayName": "Online",
          "value": "online",
          "icon": "online.svg",
          "isSelected": false
        }, {
          "displayName": "In-person",
          "value": "in_person",
          "icon": "inperson.svg",
          "isSelected": false
        }, {
          "displayName": "Video",
          "value": "video",
          "icon": "video.svg",
          "isSelected": false
        }, {
          "displayName": "Podcast",
          "value": "podcast",
          "icon": "podcast.svg",
          "isSelected": false
        }, {
          "displayName": "SMS-text",
          "value": "sms_text",
          "icon": "smstext.svg",
          "isSelected": false
        }, {
          "displayName": "Book",
          "value": "book",
          "icon": "book.svg",
          "isSelected": false
        }, {
          "displayName": "Game",
          "value": "game",
          "icon": "game.svg",
          "isSelected": false
        }
      ];
    }
    if (type === 'session_length') {
      response = [
        {
          "displayName": "Short",
          "value": "short"
        }, {
          "displayName": "Long",
          "value": "long"
        }
      ];
    }
    if (type === 'language') {
      response = [
        {
          "displayName": "English",
          "value": "English"
        }, {
          "displayName": "Spanish",
          "value": "Spanish"
        }
      ];
    }
    if (type === 'credit') {
      response = [
        {
          "displayName": "College",
          "value": "college",
          "key": 0
        }, {
          "displayName": "Certification",
          "value": "certification",
          "key": 1
        }
      ];
    }
    if (type === 'subscription_type') {
      response = [
        {
          "displayName": "No subscription",
          "value": "no_subscription",
          "key": 0
        }, {
          "displayName": "Via Bendable",
          "value": "via_bendable",
          "key": 1
        }, {
          "displayName": "Via the library",
          "value": "via_library",
          "key": 2
        }
      ];
    }
    if (type === 'age_ranges') {
      response = [
        {
          "displayName": "Youth",
          "value": "youth",
          "key": 0
        }, {
          "displayName": "Adult",
          "value": "adult",
          "key": 1
        }, {
          "displayName": "Senior",
          "value": "senior",
          "key": 2
        }
      ];
    }
    if (type === 'grade_bands') {
      response = [
        {
          "displayName": "K-2",
          "value": "K-2",
          "key": 0
        }, {
          "displayName": "3-5",
          "value": "3-5",
          "key": 1
        }, {
          "displayName": "6-8",
          "value": "6-8",
          "key": 2
        }, {
          "displayName": "9-12",
          "value": "9-12",
          "key": 3
        }
      ];
    }
    if (type === 'cost_to_learner') {
      response = [
        {
          "displayName": "Yes, there is a fee",
          "value": "has_fee",
          "key": 0
        }, {
          "displayName": "No fee",
          "value": "no_fee",
          "key": 1
        }
      ];
    }
    if (type === 'instructional_style') {     // self-paced, instructor-led
      response = [
        {
          "displayName": "Self-paced",
          "value": "self-paced",
          "key": 0
        }, {
          "displayName": "Instructor-led",
          "value": "instructor-led",
          "key": 1
        }
      ];
    }
    if (type === 'tag_category') {
      response = [
        {
          "displayName": "Keyword",
          "value": "keyword",
          "key": 0
        }, {
          "displayName": "Learning age",
          "value": "recommended learning age",
          "key": 1
        }, {
          "displayName": "Grade band",
          "value": "grade band",
          "key": 2
        }, {
          "displayName": "Credit type",
          "value": "credit",
          "key": 3
        }
      ];
    }
    if (type === 'category_type') {
      response = [
        {
          "displayName": "Browse Category",
          "value": "BrowseCategory",
          "key": 0
        }, {
          "displayName": "Career Category",
          "value": "CareerCategory",
          "key": 1
        }
      ];
    }



    if (prependType === 'no_default') {
      response.unshift({
        "displayName": "No default",
        "value": "no_default",
        "key": -1
      });
    }

    console.log('selectOptionsService.getStaticSelectOptions() response: ', response);
    return response;
  },

};


export default selectOptionsService;


// DEBUG
// setTimeout(async () => {
//   const someResponse = await selectOptionsService.getSelectOptions({
//     objectType: 'provider',
//     bendableInstance: 'bendable-staging-1'
//   });
// }, 10000);