/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import BulkUploadResourcesNavBar from '../components/bulkUploadResources/BulkUploadResourcesNavBar';
import authService from '../app/authService';
import callApi from '../app/apiService';
import GenericModal from '../components/modals/genericModal';
import InstanceSelector from '../components/wizardInputs/InstanceSelector';
import ResourceWizardSelectInput from '../components/resourceWizard/ResourceWizardSelectInput';
import FileUpload from '../components/fileUpload/FileUpload';
import customCss from '../components/fileUpload/custom.module.css';

const BulkUploadResourcesPage = (props) => {
  const navigate = useNavigate();

  const [confirmUserHasSpreadsheetIsOpen, setConfirmUserHasSpreadsheetIsOpen] = useState(false);
  const [downloadTemplateIsOpen, setDownloadTemplateIsOpen] = useState(false);
  const [controllingInstance, setControllingInstance] = useState('');
  const [provider, setProvider] = useState(null);
  const [uploadInProgress, setUploadInProgress] = useState(false);

  const uiVersion = authService.getUiVersion();

  // open the confirmation dialog on load
  useEffect(() => {
    setConfirmUserHasSpreadsheetIsOpen(() => {
      console.log('BulkUploadResourcesPage useEffect on load called...');
      return true;
    });
  }, []);

  const confirmUserHasSpreadsheetHandler = async (action) => {
    setConfirmUserHasSpreadsheetIsOpen(false);
    console.log('BulkUploadResourcesPage.confirmUserHasSpreadsheetHandler() action: ', action);
    if (action === 'confirm') {
      setDownloadTemplateIsOpen(true);
    }
  };

  const downloadSpreadsheetHandler = async (action) => {
    setDownloadTemplateIsOpen(false);
    console.log('BulkUploadResourcesPage.downloadSpreadsheetHandler() action: ', action);
    if (action === 'confirm') {
      const element = document.getElementById('spreadsheetDownload');
      if (element) element.click();
    }
  };

  const handleInstanceSelectorChange = (data) => {
    // Ex: {fieldName: 'controlling_instance', fieldValue: 'bendable-staging-2'}
    console.log('BulkUploadResourcesPage.handleInstanceSelectorChange() data: ', data);
    setControllingInstance(data.fieldValue);
  };

  const handleProviderChange = (data) => {
    console.log('BulkUploadResourcesPage.handleProviderChange() data: ', data);
    setProvider(data.fieldValue);
  };

  const handleFileChange = async (file) => {
    setUploadInProgress(true);
    console.log('BulkUploadResourcesPage.handleDropzoneChange() file: ', file);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("controllingInstance", controllingInstance);
    formData.append("providerId", provider?._id);
    
    const axiosOptions = {
      headers: {
        "content-type": "multipart/form-data"
      },
      data: formData,
      // onUploadProgress: handleUploadProgress
    };

    try {
      const uploadResponse = await callApi('uploadResources', null, axiosOptions);
      console.log('WizardFiBulkUploadResourcesPageleInput.handleDropzoneChange() uploadResponse: ', uploadResponse);
      if (uploadResponse?.data?.status === 'success') {
        navigate(`/${uiVersion}/dashboard`);
      }

    } catch (err) {
      
    } finally {
      setUploadInProgress(false);
    }
  };


  return (
    <div style={{
      width: '100%',
      height: 'calc(100% - 65px)'
    }}>
      <BulkUploadResourcesNavBar
        saveDisabled={false}
        submitDisabled={true}
      >
        <a
          id="spreadsheetDownload"
          href="https://bendable.s3.us-west-1.amazonaws.com/Bendable+Bulk+Resource+Upload+Template.csv"
          download="BulkResourceUploadTemplate.csv" 
        />
        
        <Box 
          display="flex" 
          flexDirection="column" 
          sx={{ 
            height: 'calc(100% - 65px)',
            width: '500px',
            margin: '40px auto'
          }}
        >
          <InstanceSelector
            fieldName="controlling_instance"
            initialValue=""
            multiline={false}
            onChange={handleInstanceSelectorChange}
            onNext={null}
            prompt="Select the Bendable instance where you want to add resources."
            options={{ selectPaddingTop: '10px' }}
          />

          <Typography
            sx={{ margin: '20px 0 10px 0'}}
          >
            Select the provider of the resources you are adding.
          </Typography>

          <ResourceWizardSelectInput
            noBreadcrumb={true}
            noPadding={true}
            question="" 
            prompt="" 
            label="Provider" 
            fieldName="provider" 
            optionLabel="_pg__slug" 
            tableName="providers" 
            targetInstance={controllingInstance} 
            initialValue={provider} 
            required={false} 
            disabled={!controllingInstance} 
            onChange={handleProviderChange} 
          />


          <Box sx={{ marginTop: '30px', width: '500px' }}>
            <FileUpload
              cssModule={customCss}
              disabled={!controllingInstance || !provider}
              onFileChange={handleFileChange}
            />
          </Box>

          { 
            uploadInProgress 
            ? <CircularProgress color="secondary" sx={{ margin: '0 auto'}} />
            : <React.Fragment />
          }          

        </Box>



      </BulkUploadResourcesNavBar>

      <GenericModal
        width="30%"
        height="350px"
        isOpen={confirmUserHasSpreadsheetIsOpen}
        onClose={confirmUserHasSpreadsheetHandler}
        // cancelLabel="Cancel"
        confirmLabel="No"
        secondaryConfirmLabel="Yes"
        options={{
          confirmLabelVariant: 'outlined',
        }}
      >
        <Box
          sx={{
            paddingBottom: '1rem'
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Sharp Sans',
              fontSize: '1.2rem',
              fontWeight: 'bold'
            }}
          >
            Do you have a spreadsheet ready to upload?
          </Typography>
          <Typography
            sx={{
              marginTop: '20px',
              fontFamily: 'Sharp Sans',
              fontWeight: 'normal',
              fontStyle: 'italic'
            }}
          >
            Don’t worry if you don’t have one, we’ll help you make one.
          </Typography>
        </Box>
      </GenericModal>

      <GenericModal
        width="30%"
        height="350px"
        isOpen={downloadTemplateIsOpen}
        onClose={downloadSpreadsheetHandler}
        // cancelLabel="Cancel"
        confirmLabel="Download"
        // secondaryConfirmLabel="Yes"
        options={{
          // confirmLabelVariant: 'outlined',
        }}
      >
        <Box
          sx={{
            paddingBottom: '1rem'
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Sharp Sans',
              fontSize: '1.2rem',
              fontWeight: 'bold'
            }}
          >
            It sounds like you need to prepare a spreadsheet. We’ve got you covered.<br/>
            <br/>
            Are you ready to download a template?
          </Typography>
          <Typography
            sx={{
              marginTop: '20px',
              fontFamily: 'Sharp Sans',
              fontWeight: 'normal',
              fontStyle: 'italic'
            }}
          >
            Working with a Bendable template will insure that the data you’re providing matches what we need to create these resources. There are instructions in the spreadsheet to help you out.
          </Typography>
        </Box>
      </GenericModal>

    </div>
  );
};

export default BulkUploadResourcesPage;