import EventEmitter2 from 'eventemitter2';

var emitter = new EventEmitter2({
  wildcard: true,             // set this to `true` to use wildcards
  delimiter: '.',             // the delimiter used to segment namespaces
  newListener: false,         // set this to `true` if you want to emit the newListener event
  removeListener: true,       // set this to `true` if you want to emit the removeListener event
  maxListeners: 10,           // the maximum amount of listeners that can be assigned to an event
  verboseMemoryLeak: true,    // show event name in memory leak message when more than maximum amount of listeners is assigned
  ignoreErrors: false         // disable throwing uncaughtException if an error event is emitted and it has no listeners
});


const messageBus = {

    CONFIG_SERVICE_USER_CONFIG_CHANGED_EVENT: 'configService.userConfigChanged',
    CONFIG_SERVICE_FRONTEND_CONFIG_CHANGED_EVENT: 'configService.frontendConfigChanged',
    ACTIVE_AUTHORIZED_BENDABLE_INSTANCES_CHANGED_EVENT: 'authService.activeAuthorizedBendableInstancesChanged',
    CREATE_PAGE_UPLOAD_FILE_EVENT: 'createPage.uploadFile',
    CREATE_WIZARD_PAGE_SUBMIT_EVENT: 'createWizardPage.submit',
    UPDATE_DB_OBJECT_CHANGES_EVENT: 'managePage.approveChanges',
    GRID_DATA_SET_EVENT: 'agGrid.gridDataSet',                                              // triggered when data is loaded into an AgGrid
    DASHBOARD_DB_OBJECT_CONTAINERS_CHANGED_EVENT: 'dashboard.dbObjectContainersChanged',    // triggered on update of a container on the Dashboard

    /* Simple UI Click Events */
    CREATE_WIZARD_PAGE_SET_ACTIVE_STEP_EVENT: 'createWizardPage.setActiveStep',
    ATTEMPT_DB_OBJECT_CONTAINER_ASSIGNMENT_EVENT: 'dbObjectShellCard.primaryClick',
    SYSTEM_EVENTS_LOOKBACK_BUTTON_EVENT: 'systemEventLookbackButtons.primaryClick',
    BULK_EDIT_RESOURCES_SAVE_CHANGES_EVENT: 'bulkEditResourcesSaveChangesEvent',
    BULK_EDIT_RESOURCES_APPROVE_CHANGES_EVENT: 'bulkEditResourcesApproveChangesEvent',


    subscribe: (eventName, callback) => {
      // console.log('messageBus.subscribe() eventName, args', eventName, callback);
      return emitter.on(eventName, callback);       // returned object has .off() call to remove the listner
    },

    unsubscribe: (eventName, callback) => {
      // console.log('messageBus.unsubscribe() eventName, args', eventName, callback);
      emitter.off(eventName, callback);
    },

    publish: (eventName, ...args) => {
      console.log('messageBus.publish() eventName, args', eventName, args);
      emitter.emit(eventName, ...args);
    }

};

export default messageBus;