import React, { useState } from "react";

import { ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import TextField from "@material-ui/core/TextField";
import Button from '@material-ui/core/Button';
import grey from "@material-ui/core/colors/grey";

import simpleTheme from '../../themes/bendableSimple';

import { center } from './Login.module.css';
import {ReactComponent as BendableLogo} from '../../assets/images/Bendable_Logo.svg';


const grey300 = grey["300"];

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    backgroundColor: grey300
  },
  buttonText: {
    color: "black"
  },
  logo: {
    width: 100
  }
});

const CodeInput = (props) => {
  const {
    type  
  } = props;

  const classes = useStyles();
  const [code, setCode] = useState('');

  const handleClick = () => {
    props.onSignIn(code);
  };

  let label = 'Security code from email';
  if (type === 'sms') label = 'SMS security code';
  if (type === 'authenticator') label = 'OTC app security code';

  return (
    <ThemeProvider theme={simpleTheme}>
      <div className={center}>
        <Card className={classes.root}>
          <CardContent>
            <Box display="flex" justifyContent="center" m={2} p={2}>
              <BendableLogo className={classes.logo} />
            </Box>

            <Box display="flex" justifyContent="center" m={2} p={2}>
              <TextField
                autoFocus={true}
                label={label}
                value={code}
                color="primary"
                onChange={(e) => setCode(e.target.value)}
              />
            </Box>

            <Box display="flex" justifyContent="center" m={2} p={2}>
              <Button variant="contained" color="secondary" className={classes.buttonText} 
                onClick={handleClick}>Sign In</Button>
            </Box>
          </CardContent>
        </Card>
      </div>
    </ThemeProvider>
  )
};

export default CodeInput;