import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useLocation, useNavigate } from "react-router";

import { useAuth } from '../app/AuthProvider';
import callApi from '../app/apiService';
import Login from '../components/login/Login';
// import {ReactComponent as BendableLogo} from '../assets/images/Bendable_Logo.svg';

import { wrapper } from './LoginPage.module.css';
import CodeInput from '../components/login/CodeInput';

const LoginPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();
  let from = state?.from?.pathname || "/";

  const [okToRender, setOkToRender] = useState(false);      // stop rendering before useEffect JWT check to avoid flicker
  const [loginChallengeType, setLoginChallengeType] = useState(null);
  const [smsKey, setSmsKey] = useState();
  const [emailKey, setEmailKey] = useState();

  useEffect(() => {
    const checkJwt = async () => {
      const apiResponse = await callApi('loginViaJwt', null, { });
      if (apiResponse && apiResponse.data && apiResponse.data.user) {
        console.log('LoginPage useEffect; signing in via JWT with user: ', apiResponse.data.user);
        auth.signin(apiResponse.data.user, () => {
          let destination = from;
          if (destination === '/') destination = '/1.1/dashboard';
          navigate(destination, { replace: true });
        });    
      } else {
        setOkToRender(true);
      }
    };
    checkJwt();
  }, [auth, from, navigate]);


  const getLoginChallengeType = async (email) => {
    try {
      console.log('LoginPage.getLoginChallengeType() email: ', email);
      const apiResponse = await callApi('getLoginChallengeType', null, { params: { email: email }});
      console.log('LoginPage.getLoginChallengeType() apiResponse: ', apiResponse);
      const type = apiResponse?.data?.payload?.loginChallengeType;
      setLoginChallengeType(type || null);
      if (type === 'sms') {
        triggerSmsChallenge(email);
      } else if (type === 'authenticator') {
        // triggerAuthenticatorChallenge(email);
      } else {
        triggerEmailChallenge(email);
      }

    } catch (err) {
      
    }
  };



  const triggerSmsChallenge = async (email) => {
    try {
      console.log('LoginPage.triggerSmsChallenge() email: ', email);
      const apiResponse = await callApi('requestSmsAuth', null, { email: email });
      console.log('LoginPage.triggerSmsChallenge() apiResponse: ', apiResponse);
      if (apiResponse && apiResponse.data && apiResponse.data.key) {
        setSmsKey(apiResponse.data.key);
      }

    } catch (err) {
      
    }
  };


  const triggerEmailChallenge = async (email) => {
    try {
      console.log('LoginPage.triggerEmailChallenge() email: ', email);
      const apiResponse = await callApi('requestEmailAuth', null, { email: email });
      console.log('LoginPage.triggerEmailChallenge() apiResponse: ', apiResponse);
      if (apiResponse && apiResponse.data && apiResponse.data.key) {
        setEmailKey(apiResponse.data.key);
      }

    } catch (err) {
      
    }
  };


  const signIn = async (code) => {
    const key = smsKey || emailKey || null;
    try {
      const apiResponse = await callApi(
        'loginViaSmsAuth',
        null,
        { 
          key: key,
          timeLimitedCode: code,
          loginChallengeType: loginChallengeType
        }
      );
      console.log('LoginPage.signIn() apiResponse: ', apiResponse);
      if (apiResponse && apiResponse.data && apiResponse.data.user) {
        console.log('LoginPage useEffect; signing in via SMS with user: ', apiResponse.data.user);
        auth.signin(apiResponse.data.user, () => {
          let destination = from;
          if (destination === '/') destination = '/1.1/dashboard';
          navigate(destination, { replace: true });
        });    
      }
    } catch (err) {
      
    }
  };


  return (
    <React.Fragment>
      { 
        okToRender 
        ? <div className={wrapper}>
          <Box display="flex" justifyContent="center" m={1} p={1}>
          { 
            (smsKey || emailKey)
            ? <CodeInput onSignIn={signIn} type={loginChallengeType} />
            : <Login onNext={getLoginChallengeType} />
          }
          </Box>
        </div>
        : <div className={wrapper}>
            <Box display="flex" justifyContent="center" m={1} p={1}>
              {/* <CircularProgress /> */}
              <span style={{ fontSize: '1.5rem' }}>Loading...</span>
            </Box>
          </div>
      }
    </React.Fragment>
  );
};

export default LoginPage;
