import crypto from 'crypto';
import _ from 'lodash-es';

import configService from './configService';
import messageBus from './messageBus';
// import callApi from './apiService';

// let roleData = {};
// let activeAuthorizedBendableInstances = [];                       // instances the user is currently working with

// const init = async () => {
//   try {
//     const response = await callApi('getAllRoles', null, { });
//     console.log('authService.init() response: ', response);
//     roleData = response?.data?.allRoles || {};
//     console.log('authService.init() roleData: ', roleData);
//   } catch (err) {
//     console.log('authService.init() err: ', err);
//   }
// };

const SUPERUSER = 1;    // role bit for superuser

const authService = {

  apiVersion: 'v1',         // default API version
  uiVersion: '1.1',         // default UI version
  isAuthenticated: false,
  user: null,
  // roleData: {},
  activeAuthorizedBendableInstances: [],

  // async init() {
  //   // try {
  //   //   const response = await callApi('getAllRoles', null, { });
  //   //   console.log('authService.init() response: ', response);
  //   //   this.roleData = response?.data?.allRoles || {};
  //   //   console.log('authService.init() this.roleData: ', this.roleData);
  //   // } catch (err) {
  //   //   console.log('authService.init() err: ', err);
  //   // }
  // },

  getUserId() {
    if (!this.user) return null;
    return this.user.user_id;
  },

  authenticate(userObj, callback) {
    console.log('authService.authenticate() userObj: ', userObj);
    this.isAuthenticated = true;
    this.user = userObj;
    // TODO: emit signin event
    setTimeout(callback, 100) // fake async
  },

  signout(callback) {
    this.isAuthenticated = false;
    this.user = null;
    // TODO: emit signout event
    setTimeout(callback, 100) // fake async
  },

  getSessionToken() {
    if (this.user) return this.user.session_token;
    return null;
  },

  setRoleData(data) {
    console.log('authService.setRoleData() data: ', data);
    this.roleData = data;
  },

  hasRole(roleName, bendableInstance) {
    const roleData = configService.getConfigValue('allRoles');
    if (!roleData) {
      // console.error(`authService.hasRole() Missing roleData...`);
      return false;
    }
    if (!roleName) {
      // console.error(`authService.hasRole() Missing roleName...`);
      return false;
    }
    if (!bendableInstance) {
      // console.error(`authService.hasRole() Missing bendableInstance...`);
      return false;
    }

    // console.log(`authService.hasRole() checking roleName ${roleName} for bendableInstance ${bendableInstance}...`);
    const bitmask = (this.user.roles && this.user.roles[bendableInstance]) || 0;
    // console.log('authService.hasRole() bitmask: ', bitmask);
    if (bitmask === 0) return false;
    if ((bitmask & SUPERUSER) > 0) return true;   // user is superuser

    // console.log('authService.hasRole() roleData: ', roleData);
    const targetBit = (roleData[roleName] && roleData[roleName].bit) || 0;
    // console.log('authService.hasRole() targetBit: ', targetBit);
    return ((targetBit & bitmask) > 0);
  },

  getRoles() {
    return this.user?.roles;
  },

  getDefaultBendableInstance() {
    // return 'bendable-staging-1';
    const instances = this.getAuthorizedBendableInstances();
    if (instances && instances[0]) return instances[0];
    return '';
  },

  getAuthorizedBendableInstances() {
    if (!authService.user || !authService.user.roles) return [];
    return _.keys(authService.user.roles);
  },

  getAdminAuthorizedBendableInstances() {
    console.log('authService.getAdminAuthorizedBendableInstances() authService.user.roles: ', authService.user.roles);
    if (!authService.user || !authService.user.roles) return [];
    const authorizedInstances = _.keys(authService.user.roles);
    let adminAuthorizedInstances = [];
    _.forEach(authorizedInstances, (authorizedInstance) => {
      if (authService.hasRole('admin', authorizedInstance) 
          || authService.hasRole('superuser', authorizedInstance)) {
        adminAuthorizedInstances.push(authorizedInstance);
      }
    });
    return adminAuthorizedInstances;
  },

  getHumanReadableAuthorizedBendableInstances() {
    if (!this.user || !this.user.roles) return [];
    const keys = _.keys(this.user.roles);
    return _.map(keys, (key) => {
      return {
        bendableInstance: key,
        displayName: configService.getBendableInstanceDisplayName(key),
      };
    });
  },

  getActiveAuthorizedBendableInstances() {
    return this.activeAuthorizedBendableInstances;
  },

  setActiveAuthorizedBendableInstances(newValue) {
    console.log('authService.setActiveAuthorizedBendableInstances() newValue: ', newValue);
    this.activeAuthorizedBendableInstances = newValue;
    messageBus.publish(messageBus.ACTIVE_AUTHORIZED_BENDABLE_INSTANCES_CHANGED_EVENT);
    window.localStorage.setItem('bas_active_authorized_bendable_instances', JSON.stringify(newValue));
  },

  getFirstName() {
    if (this.user && this.user.first_name) return this.user.first_name;
    return '';
  },

  getLastName() {
    if (this.user && this.user.last_name) return this.user.last_name;
    return '';
  },

  setUiVersion(uiVersion) {
    this.uiVersion = uiVersion;
  },

  getUiVersion() {
    return this.uiVersion;
  },

  setApiVersion(apiVersion) {
    this.apiVersion = apiVersion;
  },

  getApiVersion() {
    return this.apiVersion;
  },

  isSuperUser() {
    let hasSuperUserRole = false;
    const instances = this.getAuthorizedBendableInstances();
    for (const instance of instances) {
      if (this.hasRole('superuser', instance)) hasSuperUserRole = true;
    }
    return hasSuperUserRole;
  },

  isAdmin() {
    let hasAdminRole = false;
    const instances = this.getAuthorizedBendableInstances();
    for (const instance of instances) {
      if (this.hasRole('admin', instance)) hasAdminRole = true;
    }
    return hasAdminRole;
  },

};


export const getHash = (buffer, algo = "SHA-256") => {
  return crypto.createHash('sha1').update(buffer).digest('hex');

  // return crypto.subtle.digest(algo, buffer)
  //   .then(hash => {
  //     // hash is an arrayBuffer, so convert it to hex
  //     let result = '';
  //     const view = new DataView(hash);
  //     for (let i = 0; i < hash.byteLength; i += 4) {
  //       result += ('00000000' + view.getUint32(i).toString(16)).slice(-8);
  //     }
  //     return result;
  //   });
};

// console.log('authService DEBUG getHash("bendable-staging-1bendable-staging-2"): ', getHash("bendable-staging-1bendable-staging-2"));

// authService.init();

export default authService;