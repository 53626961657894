import React, { useState, useEffect } from 'react';
import { useLocation, Navigate } from "react-router-dom";
import authService from './authService';

let AuthContext = React.createContext(null);

const AuthProvider = ({ children, uiVersion }) => {
  // console.log('AuthProvider uiVersion: ', uiVersion);
  const [user, setUser] = useState(null);

  const getUiVersion = () => {
    return uiVersion;
  };

  const setUiVersion = (uiVersion) => {
    authService.setUiVersion(uiVersion);
  };

  const getApiVersion = () => {
    return authService.apiVersion;
  };

  const setApiVersion = (apiVersion) => {
    authService.setApiVersion(apiVersion);
  };

  const signin = (userObj, callback) => {
    return authService.authenticate(userObj, () => {
      setUser(userObj);
      if (callback) return callback();
    });
  };

  const signout = (callback) => {
    return authService.signout(() => {
      setUser(null);
      if (callback) return callback();
    });
  };

  const getUserId = () => {
    return authService.getUserId();
  };

  const getAuthorizedBendableInstances = () => {
    return authService.getAuthorizedBendableInstances();
  };

  const hasMultipleAuthorizedBendableInstances = () => {
    const instances = authService.getAuthorizedBendableInstances();
    return (instances.length > 1);
  };

  /**
   * Ensure that the uiVersion is set into the backing authService
   */
  useEffect(() => {
    setUiVersion(uiVersion);
  }, [uiVersion]);



  const value = { 
    user,
    getUiVersion,
    signin,
    signout,
    getUserId,
    getAuthorizedBendableInstances,
    hasMultipleAuthorizedBendableInstances
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};


export const useAuth = () => {
  return React.useContext(AuthContext);
};


export const RequireAuth = ({ children }) => {
  let auth = useAuth();
  let location = useLocation();
  const uiVersion = authService.getUiVersion();
  const to = `/${uiVersion}/login`;
  // console.log('AuthProvider RequireAuth to: ', to);

  if (!auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={to} replace={true} state={{ from: location }} />;     // <Navigate to='/login' replace={true} state={{ from: location.pathname }} />
  }

  return children;
};

export default AuthProvider;