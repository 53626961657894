import React, { Suspense, lazy, useState, useEffect } from 'react';
import {
  Routes,
  Route,
} from "react-router-dom";
import { useSelector } from 'react-redux';
import _ from 'lodash-es';

import { ROUTES } from '../constants';

import { ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';

import {
  selectUserConfigCache,
} from '../app/redux/configSlice';

import AppNavBar from '../components/appNavBar/AppNavBar';
import { RequireAuth } from '../app/AuthProvider';

import bendableLightV2Theme from '../themes/bendableLightV2';
import bendableHighContrastV2Theme from '../themes/bendableHighContrastV2';

import Dashboard from '../pages/Dashboard';
import BulkUploadResourcesPage from '../pages/BulkUploadResourcesPage';
import ResourceShellsSummaryPage from '../pages/ResourceShellsSummaryPage';

const AdminPage = lazy(() => import('../pages/AdminPage'));
const ResourceWizardPage = lazy(() => import('../pages/ResourceWizardPage'));
const ProviderWizardPage = lazy(() => import('../pages/ProviderWizardPage'));
const CollectionWizardPage = lazy(() => import('../pages/CollectionWizardPage'));
const CareerCollectionWizardPage = lazy(() => import('../pages/CareerCollectionWizardPage'));
const BulkEditResourcesPage = lazy(() => import('../pages/BulkEditResourcesPage'));
const BulkViewResourcesPage = lazy(() => import('../pages/BulkViewResourcesPage'));
const SpotlightSlidesSummaryPage = lazy(() => import('../pages/SpotlightSlidesSummaryPage'));
const SpotlightSlideWizardPage = lazy(() => import('../pages/SpotlightSlideWizardPage'));
const SpotlightCollectionsSummaryPage = lazy(() => import('../pages/SpotlightCollectionsSummaryPage'));
const SpotlightCollectionWizardPage = lazy(() => import('../pages/SpotlightCollectionWizardPage'));
const UserInsightsDashboardPage = lazy(() => import('../pages/UserInsightsDashboardPage'));
const ProvidersSummaryPage = lazy(() => import('../pages/ProvidersSummaryPage'));
const TagsSummaryPage = lazy(() => import('../pages/TagsSummaryPage'));
const TagWizardPage = lazy(() => import('../pages/TagWizardPage'));
const TopicsSummaryPage = lazy(() => import('../pages/TopicsSummaryPage'));
const TopicWizardPage = lazy(() => import('../pages/TopicWizardPage'));
const CategoriesSummaryPage = lazy(() => import('../pages/CategoriesSummaryPage'));
const CategoryWizardPage = lazy(() => import('../pages/CategoryWizardPage'));
const TenantsSummaryPage = lazy(() => import('../pages/TenantsSummaryPage'));
const TenantWizardPage = lazy(() => import('../pages/TenantWizardPage'));
const ProviderDeploymentsPage = lazy(() => import('../pages/ProviderDeploymentsPage'));


const AppNavBarWithRoutes = (props) => {
  const currentUserConfigCache = useSelector(selectUserConfigCache);
  const initialTheme = _.get(currentUserConfigCache, 'ui.high_contrast') ? bendableHighContrastV2Theme : bendableLightV2Theme;
  const [currentTheme, setCurrentTheme] = useState(initialTheme);

  useEffect(() => {
    const newTheme = _.get(currentUserConfigCache, 'ui.high_contrast') ? bendableHighContrastV2Theme : bendableLightV2Theme;
    setCurrentTheme(newTheme);
  }, [currentUserConfigCache]);

  return (
    <ThemeProvider theme={currentTheme}>
    <AppNavBar>
      <Suspense fallback={<Box display="flex" justifyContent="center" m={1} p={1}>
              <span style={{ fontSize: '1.5rem' }}>Loading...</span>
            </Box>}>
        <Routes>
          <Route path="admin/*" element={<RequireAuth><AdminPage /></RequireAuth>} />
          <Route path="dashboard" element={<RequireAuth><Dashboard /></RequireAuth>} />
          <Route path="resourceShellsSummary/*" element={<RequireAuth><ResourceShellsSummaryPage /></RequireAuth>} />
          <Route path="resourceWizard/*" element={<RequireAuth><ResourceWizardPage /></RequireAuth>} />
          <Route path="providerWizard/*" element={<RequireAuth><ProviderWizardPage /></RequireAuth>} />
          <Route path="bulkResourceUpload/*" element={<RequireAuth><BulkUploadResourcesPage /></RequireAuth>} />
          <Route path="collections/*" element={<RequireAuth><CollectionWizardPage /></RequireAuth>} />
          <Route path="careerCollections/*" element={<RequireAuth><CareerCollectionWizardPage /></RequireAuth>} />
          <Route path="editResources/:binId/*" element={<RequireAuth><BulkEditResourcesPage /></RequireAuth>} />
          <Route path="viewResources/*" element={<RequireAuth><BulkViewResourcesPage /></RequireAuth>} />
          <Route path="spotlightSlidesSummary/*" element={<RequireAuth><SpotlightSlidesSummaryPage /></RequireAuth>} />
          <Route path="spotlightSlides/*" element={<RequireAuth><SpotlightSlideWizardPage /></RequireAuth>} />
          <Route path="spotlightCollectionsSummary/*" element={<RequireAuth><SpotlightCollectionsSummaryPage /></RequireAuth>} />
          <Route path="spotlightCollections/*" element={<RequireAuth><SpotlightCollectionWizardPage /></RequireAuth>} />
          <Route path="providersSummary/*" element={<RequireAuth><ProvidersSummaryPage /></RequireAuth>} />
          <Route path="tagsSummary/*" element={<RequireAuth><TagsSummaryPage /></RequireAuth>} />
          <Route path="tagWizard/*" element={<RequireAuth><TagWizardPage /></RequireAuth>} />
          <Route path="topicsSummary/*" element={<RequireAuth><TopicsSummaryPage /></RequireAuth>} />
          <Route path="topicWizard/*" element={<RequireAuth><TopicWizardPage /></RequireAuth>} />
          <Route path="categoriesSummary/*" element={<RequireAuth><CategoriesSummaryPage /></RequireAuth>} />
          <Route path="categoryWizard/*" element={<RequireAuth><CategoryWizardPage /></RequireAuth>} />
          <Route path="tenantsSummary/*" element={<RequireAuth><TenantsSummaryPage /></RequireAuth>} />
          <Route path="tenantWizard/*" element={<RequireAuth><TenantWizardPage /></RequireAuth>} />
          <Route path="providerDeployments/*" element={<RequireAuth><ProviderDeploymentsPage /></RequireAuth>} />
          <Route path={`${ROUTES.USER_INSIGHTS_DASHBOARD}/*`} element={<RequireAuth><UserInsightsDashboardPage /></RequireAuth>} />
        </Routes>
      </Suspense>
    </AppNavBar>
  </ThemeProvider>
  );
};

export default AppNavBarWithRoutes;
