import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import resourceDot from '../../assets/images/resource_dot.png';
import communityDot from '../../assets/images/community_dot.png';
import careerDot from '../../assets/images/career_dot.png';
import systemDot from '../../assets/images/system_dot.png';

const DbObjectContainerCardSmall = (props) => {
  const { 
    item, 
    disabled = false, 
    onCardAction,             // handle click on card or on the context menu
  } = props;
  // console.log('DbObjectContainerCardSmall props: ', props);

  const [cardTitle, setCardTitle] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('#F9F4EC');
  const [imageIcon, setImageIcon] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);


  const isBin = (item?.object_type === 'db_object_bin');

  useEffect(() => {
    if (isBin) {
      setCardTitle(item?.title);
      if (item?.table_name === 'learning_objects') {
        setImageIcon(resourceDot);
      }
      if (item?.table_name === 'community_collections') {
        setImageIcon(communityDot);
      }
      if (item?.table_name === 'career_collections') {
        setImageIcon(careerDot);
      }
      if (item?.target_object_type === 'tag') {
        setImageIcon(systemDot);
      }
      if (item?.target_object_type === 'topic') {
        setImageIcon(systemDot);
      }
      if (item?.target_object_type === 'category') {
        setImageIcon(systemDot);
      }
      if (item?.target_object_type === 'tenant') {
        setImageIcon(systemDot);
      }

    } else {
      if (item?.table_name === 'learning_objects') {
        setCardTitle(item?.raw_data?.resourceCard?.title);
        setImageIcon(resourceDot);
      }
      if (item?.table_name === 'community_collections') {
        setCardTitle(item?.raw_data?.collectionCard?.title);
        setImageIcon(communityDot);
      }
      if (item?.table_name === 'career_collections') {
        setCardTitle(item?.raw_data?.collectionCard?.title);
        setImageIcon(careerDot);
      }
      if (item?.target_object_type === 'tag') {
        setCardTitle(item?.raw_data?.tenant);
        setImageIcon(systemDot);
      }
      if (item?.target_object_type === 'topic') {
        setCardTitle(item?.raw_data?.tenant);
        setImageIcon(systemDot);
      }
      if (item?.target_object_type === 'category') {
        setCardTitle(item?.raw_data?.tenant);
        setImageIcon(systemDot);
      }
      if (item?.target_object_type === 'tenant') {
        setCardTitle(item?.raw_data?.tenant);
        setImageIcon(systemDot);
      }
    }
  }, [item, isBin]);


  const handleClick = (event) => {
    event.stopPropagation();
    console.log('DbObjectContainerCardSmall.handleClick() disablbed: ', disabled);
    if (disabled) return;

    onCardAction({
      action: 'click',
      item: item,
    });
  };


  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null,
    );
  };

  const handleContextMenuClose = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setContextMenu(null);
    console.log('DbObjectContainerCardSmall.handleContextMenuClose() event.target.id: ', event.target.id);
    if (event.target.id === 'delete') {
      onCardAction({ action: 'delete', target: item._id, objectType: item.object_type, title: cardTitle });
    }
    if (event.target.id === 'resetToDraft') {
      onCardAction({ action: 'resetToDraft', target: item._id, objectType: item.object_type, title: cardTitle });
    }
  };

  const onMouseOverHandler = () => {
    // console.log('DbObjectContainerCardSmall.onMouseOverHandler() called...');
    setBackgroundColor('#EADFCE');
  };

  const onMouseOutHandler = () => {
    setBackgroundColor('#F9F4EC');
  };

  return (
    <Card
      elevation={0}
      onClick={handleClick}
      onContextMenu={handleContextMenu}
      sx={{
        position: 'relative',
        width: '215px',
        maxWidth: '215px',
        height: '70px',
        maxHeight: '70px',
        padding: '8px',
        borderRadius: '11px',
        backgroundColor: backgroundColor,
        color: '#ffffff',
        cursor: disabled ? 'inherit' : 'pointer',
      }}
      onMouseOver={onMouseOverHandler}
      onMouseLeave={onMouseOutHandler}
    >

      <Box display="flex" flexDirection="row" >

          <Box display="flex" flexDirection="column"  sx={{ marginBottom: '5px', marginRight: '8px', }}>
            <Box>
              <img src={imageIcon} style={{ width: '32px' }} alt="" />
            </Box>
            <Box 
              sx={{ 
                textAlign: 'center', 
                display: (item?.document_count > 1) ? 'block' : 'none',
              }}
            >
              <Typography color="#000000" fontSize={'0.8rem'}>
                {item?.document_count || ''}
              </Typography>
            </Box>
          </Box>

            <Box display="flex" flexDirection="column" sx={{  }}>
              <Box>
                <div
                  style={{
                    color: '#000000',
                    fontWeight:"bold",
                    fontSize: "0.8rem",
                    lineHeight: "0.8rem",
                    maxHeight: "58px",
                    overflow: 'hidden'
                  }}
                >
                  <Box element="div" sx={{
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 4
                  }}>
                    {cardTitle}
                  </Box>
                </div>
              </Box>
            </Box>

      </Box>


      <Menu
        open={contextMenu !== null}
        onClose={handleContextMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem id="resetToDraft" onClick={handleContextMenuClose} sx={{ opacity: '1.0' }}>Reset to draft</MenuItem>
        <MenuItem id="delete" onClick={handleContextMenuClose}>Delete</MenuItem>
        {/* <MenuItem id="test" onClick={handleContextMenuClose}>Test</MenuItem> */}
      </Menu>

    </Card>
  );
};

export default DbObjectContainerCardSmall;