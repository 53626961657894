/**
 * Hydrator
 * 
 * Pulls data from LocalStorage and/or SessionStorage and
 * populates Redux on app load
 * 
 * Loads config data needed to render initial UI
 * 
 * Prevents rendering of UI until data load is complete
 */

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash-es';

import authService from './authService';
// import configService from './configService';
import callApi from './apiService';

import {
  setActiveAuthorizedBendableInstances,
} from './redux/authSlice';

import {
  getFrontendConfig,
  getUserConfig,
  selectFrontendConfigCache,
  selectUserConfigCache,
} from './redux/configSlice';


const Rehydrator = (props) => {
  const { onComplete } = props;

  const dispatch = useDispatch();
  const currentFrontendConfigCache = useSelector(selectFrontendConfigCache);
  const currentUserConfigCache = useSelector(selectUserConfigCache);

  const [isComplete, setIsComplete] = useState(false);                        // ensures onComplete is called only once
  const [requiredStartupActions, setRequiredStartupActions] = useState([
    'setActiveAuthorizedBendableInstances',
    'setRoleData',
    'setFrontendConfig',
    'setUserConfig',
  ]);

  // restore active Bendable Instances for the select/button in the AppNavBar and the authService
  useEffect(() => {
    try {    
      const activeInstancesString = window.localStorage.getItem('bas_active_authorized_bendable_instances');
      console.log('Rehydrator useEffect() activeInstancesString: ', activeInstancesString);
      if (_.isString(activeInstancesString)) {
        const activeInstances = JSON.parse(activeInstancesString);
        authService.setActiveAuthorizedBendableInstances(activeInstances);
        dispatch(setActiveAuthorizedBendableInstances(activeInstances));
      }
    } catch (err) {
      console.error('Rehydrator useEffect() activeInstancesString; err: ', err);
    } finally {
      setRequiredStartupActions((prevValue) => _.xor(prevValue, ['setActiveAuthorizedBendableInstances']));
    };
  }, [dispatch]);

  // load role data for authService
  useEffect(() => {
    const loadRoleData = async () => {
      try {
        const response = await callApi('getAllRoles', null, { });
        console.log('Rehydrator getAllRoles; response: ', response);
        const roleData = response?.data?.allRoles || {};
        console.log('Rehydrator getAllRoles roleData: ', roleData);
        authService.setRoleData(roleData);
        setRequiredStartupActions((prevValue) => _.xor(prevValue, ['setRoleData']));
      } catch (err) {
        console.log('Rehydrator getAllRoles err: ', err);
      }
    };
    loadRoleData();
  }, []);



  useEffect(() => {
    dispatch(getFrontendConfig());
    dispatch(getUserConfig());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (!_.isEmpty(currentFrontendConfigCache)) {
      console.log('Rehydrator currentFrontendConfigCache not empty; remove setFrontendConfig constraint...');
      setRequiredStartupActions((prevValue) => _.xor(prevValue, ['setFrontendConfig']));
    }
  }, [currentFrontendConfigCache]);

  useEffect(() => {
    if (!_.isEmpty(currentUserConfigCache)) {
      console.log('Rehydrator currentUserConfigCache not empty; remove setUserConfig constraint...');
      setRequiredStartupActions((prevValue) => _.xor(prevValue, ['setUserConfig']));
    }
  }, [currentUserConfigCache]);


  // // load frontend config data
  // useEffect(() => {
  //   const loadFrontendConfig = async () => {
  //     try {
  //       const frontendConfigResponse = await callApi('getFrontendConfig', null, { params: {} });
  //       console.log('Rehydrator frontendConfigResponse: ', frontendConfigResponse);
  //       if (frontendConfigResponse?.data?.payload) {
  //         configService.setFrontendConfig(frontendConfigResponse.data.payload);
  //       }
  //       setRequiredStartupActions((prevValue) => _.xor(prevValue, ['setFrontendConfig']));
  //     } catch (err) {
  //       console.log('Rehydrator getAllRoles err: ', err);
  //     }
  //   };
  //   loadFrontendConfig();
  // }, []);




  // // load user config (settings) data
  // useEffect(() => {
  //   const loadUserConfig = async () => {
  //     try {
  //       const userConfigResponse = await callApi('getUserConfig', null, { params: {} });
  //       console.log('Rehydrator userConfigResponse: ', userConfigResponse);
  //       if (userConfigResponse?.data?.payload) {
  //         configService.setUserConfig(userConfigResponse.data.payload);
  //       }
  //       setRequiredStartupActions((prevValue) => _.xor(prevValue, ['setUserConfig']));
  //     } catch (err) {
  //       console.log('Rehydrator getAllRoles err: ', err);
  //     }
  //   };
  //   loadUserConfig();
  // }, []);






  // check to see if requiredStartupActions have been taken
  useEffect(() => {
    console.log('Rehydrator requiredStartupActions: ', requiredStartupActions);
    if (_.isEmpty(requiredStartupActions)) {
      setIsComplete((prevValue) => (true));       // state will change exactly once
    }
  }, [requiredStartupActions]);

  // fire 'onComplete' exactly once
  useEffect(() => {
    console.log('Rehydrator useEffect() isComplete: ', isComplete);
    if (isComplete) onComplete();                 // fire when isComplete changes state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComplete]);   // do not include 'onComplete' as a dependency so that we don't fire more than once; 'onComplete' function never changes


  return (
    <React.Fragment></React.Fragment>
  );
};

export default Rehydrator;