/**
 * Holds User data for the Admin portion of the UI
 */

import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import _ from 'lodash-es';

import callApi from '../apiService';

const initialState = {
  allUsers: {},
  activeUser: {
    _id: null,
    first_name: '',
    last_name: '',
    email: '',
    mobile: '',
    roles: {},
    bendable_instances: [],
    active: true,
  },
  status: 'idle',
};

const fetchUsers = async (options) => {
  console.log('usersSlice.fetchUser() options: ', options);
  try {
    const response = await callApi('getUsers', null, { params: options });
    console.log('usersSlice.fetchUser() response: ', response);
    return response.data;   // axios attribute
  } catch (error) {
    console.error('usersSlice.fetchUser() error: ', error.message);
  }
};


export const getUsers = createAsyncThunk(
  'usersSlice/fetchUsers',
  async (bendableInstances) => {
    console.log('usersSlice.getUsers() bendableInstances: ', bendableInstances);
    const response = await fetchUsers({ bendableInstances: bendableInstances});
    // The value we return becomes the `fulfilled` action payload
    return { bendableInstances: bendableInstances, users: response.payload };
  }
);



export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setActiveUser: (state, action) => {
      console.log('usersSlice setActiveUser; action.payload: ', action.payload);
      state.activeUser = action.payload;
      console.log('usersSlice setActiveUser; state after set: ', current(state));
    },
    mergeActiveUser: (state, action) => {
      console.log('usersSlice mergeActiveUser; action.payload: ', action.payload);
      state.activeUser = _.merge(state.activeUser, action.payload);
      console.log('usersSlice mergeActiveUser; state after set: ', current(state));
    },
    updateActiveUser: (state, action) => {
      console.log('usersSlice setActiveUser; action.payload: ', action.payload);
      state.activeUser = _.set(state.activeUser, action.payload.path, action.payload.value);
      console.log('usersSlice setActiveUser; state after set: ', current(state));
    },
    resetActiveUser: (state, action) => {
      state.activeUser = {
        _id: null,
        first_name: '',
        last_name: '',
        email: '',
        mobile: '',
        roles: {},
        bendable_instances: [],
        active: true,
      };
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(getUsers.pending, (state) => {
      // console.log('usersSlice getUser.pending state: ', state);
      state.status = 'loading';
    })
    .addCase(getUsers.fulfilled, (state, action) => {
      state.status = 'idle';
      console.log('usersSlice getUser.fulfilled action: ', action);
      state.allUsers[action.payload.bendableInstances.join('_')] = action.payload.users;
      console.log('usersSlice getUser.fulfilled state: ', current(state));
    });
  },
});

export const { 
  setActiveUser,
  mergeActiveUser,
  updateActiveUser,
  resetActiveUser,
} = usersSlice.actions;

// for useSelector; state is complete store, not slice
export const selectAllUsers = (state) => state.users.allUsers;
export const selectActiveUser = (state) => state.users.activeUser;

export default usersSlice.reducer;
