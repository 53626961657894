import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import callApi from './apiService';

const initialState = {
  postgresTableDescriptions: {},
  selectedPostgresTableName: null,
  columnVisability: {},
  controllingBendableInstance: null,
  postgresTableSearchTerms: null,
  //changesOnlyForSearch: false,            // should we search for only objects with changes in the active set?
  postgresTableRows: [],
  status: 'idle',
};

const fetchPostgresTableDescription = async (options) => {
  console.log('managePageSlice.fetchPostgresTableDescription() options: ', options);
  try {
    // const response = await axios.get(
    //   'http://localhost:3000/v1/mongodb/postgresTableDescription',
    //   { params: options }
    // );
    const response = await callApi('getPostgressTableDescription', null, { params: options });
    console.log('managePageSlice.fetchPostgresTableDescription() response: ', response);
    return response.data;   // axios attribute
  } catch (error) {
    console.error('managePageSlice.fetchPostgresTableDescription() error: ', error.message);
  }
};

// const fetchPostgresTableRows = async (options) => {
//   console.log('managePageSlice.fetchPostgresTableRows() options: ', options);
//   try {
//     const response = await callApi('getPostgressTableRows', { params: options });
//     // const response = await axios.get(
//     //   'http://localhost:3000/v1/mongodb/postgresTableRows',
//     //   { params: options }
//     // );
//     console.log('managePageSlice.fetchPostgresTableRows() response: ', response);
//     return response.data;   // axios attribute
//   } catch (error) {
//     console.error('managePageSlice.fetchPostgresTableRows() error: ', error.message);
//   }
// };


// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getPostgresTableDescription = createAsyncThunk(
  'managePage/fetchPostgresTableDescription',
  async (tableName, bendableInstance) => {
    const response = await fetchPostgresTableDescription(tableName, bendableInstance);
    // The value we return becomes the `fulfilled` action payload
    return response.payload;
  }
);

// export const getPostgresTableRows = createAsyncThunk(
//   'managePage/fetchPostgresTableRows',
//   async (tableName, bendableInstance) => {
//     const response = await fetchPostgresTableRows(tableName, bendableInstance);
//     // The value we return becomes the `fulfilled` action payload
//     return response.payload;
//   }
// );

export const managePageSlice = createSlice({
  name: 'managePage',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSelectedPostgresTableName: (state, action) => {
      state.selectedPostgresTableName = action.payload;
    },
    setPostgresTableSearchTerms: (state, action) => {
      // console.log('managePageSlice setPostgresTableSearchTerms reducer; state, action: ', state, action);
      state.postgresTableSearchTerms = action.payload;
    },
    setColumnVisability: (state, action) => {
      state.columnVisability = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
    .addCase(getPostgresTableDescription.pending, (state) => {
      // console.log('managePageSlice getPostgresTableDescription.pending state: ', state);
      state.status = 'loading';
    })
    .addCase(getPostgresTableDescription.fulfilled, (state, action) => {
      state.status = 'idle';
      console.log('managePageSlice getPostgresTableDescription.fulfilled action: ', action);
      state.postgresTableDescriptions[action.payload.table_name] = action.payload;
    })
    // .addCase(getPostgresTableRows.pending, (state) => {
    //   console.log('managePageSlice getPostgresTableRows.pending state: ', state);
    //   state.status = 'loading';
    // })
    // .addCase(getPostgresTableRows.fulfilled, (state, action) => {
    //   state.status = 'idle';
    //   console.log('managePageSlice getPostgresTableRows.fulfilled action: ', action);
    //   state.postgresTableRows = action.payload;
    // })
    ;
},
});

export const { 
  setSelectedPostgresTableName, 
  setPostgresTableSearchTerms,
  setChangesOnlyForSearch,
  setColumnVisability
} = managePageSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.managePage.postgresTableDescriptions)`

// for useSelector; state is complete store, not slice
export const selectPostgresTableDescriptions = (state) => state.managePage.postgresTableDescriptions;
export const selectPostgresTableName = (state) => state.managePage.selectedPostgresTableName;
export const selectPostgresTableSearchTerms = (state) => state.managePage.postgresTableSearchTerms;
//export const selectChangesOnlyForSearch = (state) => state.managePage.changesOnlyForSearch;
export const selectPostgresTableRows = (state) => state.managePage.postgresTableRows;
export const selectColumnVisability = (state) => state.managePage.columnVisability;

export default managePageSlice.reducer;
