/**
 * Factory service to build various system objects
 * 
 */

import _ from 'lodash-es';
import { nanoid } from 'nanoid';

import authService from './authService';

const objectFactory = {

  dbObjectShellShape: {
    _id: null,                      // MongoID of this dbObjectShell document
    bin_id: null,                   // MongoID of container for this dbObjectShell document
    object_type: 'db_object_shell',
    target_object_type: null,       // type of object being targeted for creation or update
    status: 'draft',
    controlling_instance: null,     // BENDABLE_INSTANCE value
    table_name: null,               // Postgres Table, if any
    target_mid: null,               // MongoID of existing document of 'target_object_type'; used in edits
    submitted_by_id: null,          // MongoID of user who created this dbObjectShell
    created_at: null,
    updated_at: null,
    approved_by_id: null,           // MongoID of user who approved this dbObjectShell
    publish_at: null,               // timestamp when this dbObjectShell should be used to write to MongoDB documents & Postgres
    unpublish_at: null,             // timestamp when this dbObjectShell should stop being used to write to MongoDB documents & Postgres
    raw_data: {},                   // data for Wizard
    grid_row_data: [],              // data for AG-Grid
    approver_bitmask: 0             // bitmask for roles needed to approve this dbObjectShell
  },

  collectionContentsShape: {
    heading: '',
    text: '',
    numberToComplete: 0,
    items: []                       // LearningObject references in this collection
  },

  collectionContentsItemShape: {
    mid: null,                      // MongoDB ID of LearningObject document
    id: "",                         // Postgres ID of LearningObject row
    object_type: "learning_object",
    bendable_instance: null,
    title: '',
    location: '',
    bendable_description: '',
    learning_format: '',            // "online", etc
    provider: { name: '', logo: '' },
    topic: '',
  },
  
  /**
   * Build a Collection Contents object with optional heading
   */
   buildDbObjectShell: (data) => {
    const {
      _id,
      bin_id,
      target_object_type,
      status,
      controlling_instance,
      target_bendable_instance,
      table_name,
      target_mid,
      submitted_by_id,
      created_at = Date.now(),
      updated_at,
      approved_by_id,
      publish_at,
      unpublish_at,
      raw_data = {},
      grid_row_data = [],
      approver_bitmask = 0
    } = data;

    let shape = _.cloneDeep(objectFactory.dbObjectShellShape);
    if (_id) shape._id = _id;
    if (bin_id) shape.bin_id = bin_id;
    if (target_object_type) shape.target_object_type = target_object_type;
    if (status) shape.status = status;
    if (controlling_instance) shape.controlling_instance = controlling_instance;
    if (target_bendable_instance) shape.target_bendable_instance = target_bendable_instance;
    if (table_name) shape.table_name = table_name;
    if (target_mid) shape.target_mid = target_mid;
    if (submitted_by_id) shape.submitted_by_id = submitted_by_id;
    if (created_at) shape.created_at = created_at;
    if (updated_at) shape.updated_at = updated_at;
    if (approved_by_id) shape.approved_by_id = approved_by_id;
    if (publish_at) shape.publish_at = publish_at;
    if (unpublish_at) shape.unpublish_at = unpublish_at;
    if (raw_data) shape.raw_data = raw_data;
    if (grid_row_data) shape.grid_row_data = grid_row_data;
    if (approver_bitmask) shape.approver_bitmask = approver_bitmask;
    return shape;
  },

  buildEmptyCollectionContentsObject: () => {
    let shape = _.cloneDeep(objectFactory.collectionContentsShape);
    shape.key = nanoid();
    return shape;
  },

  /**
   * Build a Collection Contents object for a Resource (LearningObject) with optional heading
   */
   buildCollectionContentsResourceObject: (heading, items) => {
    let shape = _.cloneDeep(objectFactory.collectionContentsShape);
    shape.key = nanoid();
    if (heading) shape.heading = heading;
    if (_.isArray(items)) {
      _.forEach(items, (item) => {
        const transformedItem = objectFactory.buildCollectionContentsItem({
          mid: item._id,
          id: item._pg__id,
          bendable_instance: item.controlling_instance,
          title: item._pg__title,
          location: item._pg__location,
          bendable_description: item._pg__bendable_description,
          learning_format: item._pg__learning_format,
          topic: item._pg__v_topic?._pg__name,
          provider_name: item._pg__v_provider?._pg__name,
          provider_logo: item._pg__v_provider?._pg__logo_url,
          hidden: (item._pg__hidden === true),
          errors: item?.errors,
        });
        shape.items.push(transformedItem);
      });
    }
    return shape;
  },

  /**
   * Build a Collection Contents object for a Spotlight (SpotlightSlide) with optional heading
   */
   buildCollectionContentsSpotlightObject: (heading, items) => {
    let shape = _.cloneDeep(objectFactory.collectionContentsShape);
    shape.key = nanoid();
    if (heading) shape.heading = heading;
    if (_.isArray(items)) {
      _.forEach(items, (item) => {
        console.log('objectFactory.buildCollectionContentsSpotlightObject() item: ', item);
        const transformedItem = objectFactory.buildCollectionContentsItem({
          mid: item._id,
          id: item._pg__id,
          bendable_instance: item.controlling_instance,
          title: item.title || item._pg__headline,
          headline: item._pg__headline,
          subhead: item._pg__subhead,
          active: item._pg__active,
          learning_objects: item._pg__v_learning_objects,
          // order: item._pg__order,    // not applicable any more since slide may be used in more than one collection
          hidden: (item._pg__hidden === true),
          errors: item?.errors,
        });
        shape.items.push(transformedItem);
      });
    }
    return shape;
  },

  buildCollectionContentsItem: (data) => {
    let item = {};
    const {
      mid,
      id,
      bendable_instance,
      title,
      location,
      bendable_description,
      learning_format,
      topic,
      provider_name,
      provider_logo,
      headline,
      subhead,
      active,
      learning_objects,
      hidden,
      errors,
    } = data;

    if (mid) item.mid = mid;
    if (id) item.id = id;
    if (bendable_instance) item.bendable_instance = bendable_instance;
    if (title) item.title = title;
    if (location) item.location = location;
    if (bendable_description) item.bendable_description = bendable_description;
    if (learning_format) item.learning_format = learning_format;
    if (topic) item.topic = topic;

    if (provider_name || provider_logo) item.provider = {};
    if (provider_name) item.provider.name = provider_name;
    if (provider_logo) item.provider.logo = provider_logo;

    if (headline) item.headline = headline;
    if (subhead) item.subhead = subhead;
    if (active !== undefined) item.active = active;
    if (learning_objects) item.learning_objects = learning_objects;
    if (errors) item.errors = errors;
    item.hidden = (hidden === true);

    return item;
  },

  /**
   * AG-Grid cell change to DbObjectChange document
   */
  buildDbObjectChangeFromAgGridCellChange: (options) => {
    console.log('buildDbObjectChangeFromAgGridCellChange options: ', options);
    const {
      data,                         // all data for row in grid
      newValue,                     // new value for the column in colDef
      oldValue,                     // old value for the column in colDef
      colDef,                       // the colDef for the column/field being changed
      status,                       // none | pending | approved | rejected
      postgresTableDescriptions,    // all cached postgres table descriptions
    } = options;

    const sourceObj = data?.sourceObj || {};

    const tableDescription = postgresTableDescriptions[sourceObj.table_name] || {};
    console.log('buildDbObjectChangeFromAgGridCellChange tableDescription: ', tableDescription);
    //console.log('buildDbObjectChangeFromAgGridCellChange colDef.field: ', colDef.field);

    const getColumnMetadata = (columnName) => {
      const metadata = _.find(tableDescription.columns, (columnData) => {
        //console.log('buildDbObjectChangeFromAgGridCellChange columnData: ', columnData);
        return columnData.column_name === columnName;
      });
      return _.pick(metadata, ['object_type', 'type_filter', 'label_field', 'data_type', 'cell_renderer', 'cell_editor']);
    };

    const tempObj = {
      row_id: data?.id || null,
      table_name: tableDescription.table_name,
      table_display_name: tableDescription.display_name,
      field: colDef.columnName,
      // data: data,
      new_value: newValue,
      old_value: oldValue,
      column_metadata: getColumnMetadata(colDef.columnName),
      actor: authService.getUserId(),
      timestamp: Date.now(),
      status: status || 'pending'
    };

    let dbObjectChange = _.cloneDeep(tempObj);    // don't directly reference colDef, etc.

    console.log('objectFactory.buildDbObjectChangeFromAgGridCellChange() dbObjectChange: ', dbObjectChange);
    return dbObjectChange;
  },

  /**
   * MongoDb Provider to dbShellObject
   */
    buildDbObjectShellFromProviderDocument: (document, bendableInstance) => {
      let dbObjectShell = objectFactory.buildDbObjectShell({
        target_object_type: 'provider',
        target_mid: document?._id || null,
        status: 'draft',
        controlling_instance: document?.controlling_instance,
        target_bendable_instance: bendableInstance,
        table_name: 'providers',
      });
      console.log('objectFactory.buildDbObjectShellFromProviderDocument() dbObjectShell: ', dbObjectShell);
      dbObjectShell.raw_data = objectFactory.buildProviderWizardRawDataFromProviderDocument(document, bendableInstance);
      return dbObjectShell;
    },
    
  /**
   * MongoDb SpotlightSlide to dbShellObject
   */
  buildDbObjectShellFromSpotlightSlideDocument: (document) => {
    let dbObjectShell = objectFactory.buildDbObjectShell({
      target_object_type: 'spotlight',
      target_mid: document?._id || null,
      status: 'draft',
      controlling_instance: document?.controlling_instance,
      table_name: 'spotlights',
    });
    console.log('objectFactory.buildDbObjectShellFromSpotlightSlideDocument() dbObjectShell: ', dbObjectShell);
    dbObjectShell.raw_data = objectFactory.buildSpotlightSlideWizardRawDataFromSpotlightSlideDocument(document);
    return dbObjectShell;
  },

  /**
   * MongoDb Provider to Wizard raw_data
   */
  buildProviderWizardRawDataFromProviderDocument: (document, bendableInstance) => {
    console.log('objectFactory.buildProviderWizardRawDataFromProviderDocument document: ', document);
    console.log('objectFactory.buildProviderWizardRawDataFromProviderDocument bendableInstance: ', bendableInstance);
    const rawData = {
      bendable_instance: bendableInstance,
      display_name: _.get(document, `name`),
      slug: _.get(document, `slug`),
      is_partner: _.get(document, `is_partner`),

      learning_format: _.get(document, `instance_data.${bendableInstance}.learning_format`),
      cost_to_learner: _.get(document, `instance_data.${bendableInstance}.premium`),
      instructional_style: _.get(document, `instance_data.${bendableInstance}.instructional_style`),
      session_length: _.get(document, `instance_data.${bendableInstance}.session_length`),
      language: _.get(document, `instance_data.${bendableInstance}.language`),
      age_ranges: _.get(document, `instance_data.${bendableInstance}.age_ranges`),
      grade_bands: _.get(document, `instance_data.${bendableInstance}.grade_bands`),
      filtered_search: _.get(document, `instance_data.${bendableInstance}.filtered_search`),
      subscription_type: _.get(document, `instance_data.${bendableInstance}.subscription_type`),
      sign_up_url: _.get(document, `instance_data.${bendableInstance}.sign_up_url`),
      logo_image_url: _.get(document, `instance_data.${bendableInstance}.logo`),
      tenant_handoff_key_1: _.get(document, `instance_data.${bendableInstance}.tenant_handoff_key_1`),
      tenant_handoff_pairs_1: _.get(document, `instance_data.${bendableInstance}.tenant_handoff_pairs_1`),

      is_community_learning_partner: _.get(document, `instance_data.${bendableInstance}.is_community_learning_partner`),
      clp_description: _.get(document, `instance_data.${bendableInstance}.clp_description`),
      clp_handoff_text: _.get(document, `instance_data.${bendableInstance}.clp_handoff_text`),
      clp_tags: _.get(document, `instance_data.${bendableInstance}.clp_tags`),
      clp_title: _.get(document, `instance_data.${bendableInstance}.clp_title`),
      clp_url: _.get(document, `instance_data.${bendableInstance}.clp_url`),

    };
    console.log('objectFactory.buildProviderWizardRawDataFromProviderDocument rawData: ', rawData);
    return rawData;
  },


  /**
   * MongoDb SpotlightSlide to Wizard raw_data
   */
  buildSpotlightSlideWizardRawDataFromSpotlightSlideDocument: (document) => {
    console.log('objectFactory.buildSpotlightSlideWizardRawDataFromSpotlightSlideDocument document: ', document);
    const rawData = {
      collectionCard: {
        collection_type: 'spotlight',
        controlling_instance: document?.controlling_instance,
        title: document?.title,
        headline: document?._pg__headline,
        subhead: document?._pg__subhead,
        order: document?._pg__order,
        active: document?._pg__active,
      },
      contentsCard: {
        collection_contents: [objectFactory.buildCollectionContentsResourceObject('Spotlight slide', document?._pg__v_learning_objects)],
      },
    };
    console.log('objectFactory.buildSpotlightSlideWizardRawDataFromSpotlightSlideDocument rawData: ', rawData);
    return rawData;
  },

  /**
   * MongoDb SpotlightCollection to dbShellObject
   */
   buildDbObjectShellFromSpotlightCollectionDocument: (document) => {
    let dbObjectShell = objectFactory.buildDbObjectShell({
      target_object_type: 'spotlight_collection',
      target_mid: document?._id || null,
      status: 'draft',
      controlling_instance: document?.controlling_instance,
      table_name: 'v_spotlight_collections',                          // 'virtual' Postgres table -- this data is not stored in Postgres
    });
    dbObjectShell.raw_data = objectFactory.buildSpotlightCollectionWizardRawDataFromSpotlightCollectionDocument(document);
    return dbObjectShell;
  },

  /**
   * MongoDb SpotlightCollection to Wizard raw_data
   */
  buildSpotlightCollectionWizardRawDataFromSpotlightCollectionDocument: (document) => {
    console.log('objectFactory.buildSpotlightCollectionWizardRawDataFromSpotlightCollectionDocument document: ', document);
    const rawData = {
      collectionCard: {
        collection_type: 'spotlight_collection',
        controlling_instance: document?.controlling_instance,
        title: document?.title,
        active: document?.active,
      },
      scheduleCard: {
        publish_at: document?.publish_at,
        unpublish_at: document?.unpublish_at,
      },
      contentsCard: {
        collection_contents: [objectFactory.buildCollectionContentsSpotlightObject('Spotlight container', document?.spotlight_slides || [])],
      },
    };
    console.log('objectFactory.buildSpotlightCollectionWizardRawDataFromSpotlightCollectionDocument rawData: ', rawData);
    return rawData;
  },


  /**
   * MongoDb Tag to dbShellObject
   */
    buildDbObjectShellFromTagDocument: (document, bendableInstance) => {
      let dbObjectShell = objectFactory.buildDbObjectShell({
        target_object_type: 'tag',
        target_mid: document?._id || null,
        status: 'draft',
        controlling_instance: document?.controlling_instance,
        target_bendable_instance: bendableInstance,
        table_name: 'tags',
      });
      console.log('objectFactory.buildDbObjectShellFromTagDocument() dbObjectShell: ', dbObjectShell);
      dbObjectShell.raw_data = objectFactory.buildTagWizardRawDataFromTagDocument(document, bendableInstance);
      return dbObjectShell;
    },

  /**
   * MongoDb Tag to Wizard raw_data
   */
  buildTagWizardRawDataFromTagDocument: (document, bendableInstance) => {
    console.log('objectFactory.buildTagWizardRawDataFromTagDocument document: ', document);
    console.log('objectFactory.buildTagWizardRawDataFromTagDocument bendableInstance: ', bendableInstance);
    const rawData = {
      bendable_instance: bendableInstance,
      name: _.get(document, `name`),
      tag_category: _.get(document, `tag_category`),
      language:  _.get(document, `language`),
      is_active: _.get(document, `instance_data.${bendableInstance}.is_active`),
    };
    console.log('objectFactory.buildTagWizardRawDataFromTagDocument rawData: ', rawData);
    return rawData;
  },


    /**
   * MongoDb Topic to dbShellObject
   */
    buildDbObjectShellFromTopicDocument: (document, bendableInstance) => {
      let dbObjectShell = objectFactory.buildDbObjectShell({
        target_object_type: 'topic',
        target_mid: document?._id || null,
        status: 'draft',
        controlling_instance: document?.controlling_instance,
        target_bendable_instance: bendableInstance,
        table_name: 'topics',
      });
      console.log('objectFactory.buildDbObjectShellFromTopicDocument() dbObjectShell: ', dbObjectShell);
      dbObjectShell.raw_data = objectFactory.buildTopicWizardRawDataFromTopicDocument(document, bendableInstance);
      return dbObjectShell;
    },

  /**
   * MongoDb Topic to Wizard raw_data
   */
  buildTopicWizardRawDataFromTopicDocument: (document, bendableInstance) => {
    console.log('objectFactory.buildTopicWizardRawDataFromTopicDocument document: ', document);
    console.log('objectFactory.buildTopicWizardRawDataFromTopicDocument bendableInstance: ', bendableInstance);
    const rawData = {
      bendable_instance: bendableInstance,
      name: _.get(document, `name`),
      language:  _.get(document, `language`),
      is_active: _.get(document, `instance_data.${bendableInstance}.is_active`),
    };
    console.log('objectFactory.buildTopicWizardRawDataFromTopicDocument rawData: ', rawData);
    return rawData;
  },


    /**
   * MongoDb Category to dbShellObject
   */
    buildDbObjectShellFromCategoryDocument: (document, bendableInstance) => {
      let dbObjectShell = objectFactory.buildDbObjectShell({
        target_object_type: 'category',
        target_mid: document?._id || null,
        status: 'draft',
        controlling_instance: document?.controlling_instance,
        target_bendable_instance: bendableInstance,
        table_name: 'categories',
      });
      console.log('objectFactory.buildDbObjectShellFromCategoryDocument() dbObjectShell: ', dbObjectShell);
      dbObjectShell.raw_data = objectFactory.buildCategoryWizardRawDataFromCategoryDocument(document, bendableInstance);
      return dbObjectShell;
    },

  /**
   * MongoDb Category to Wizard raw_data
   */
  buildCategoryWizardRawDataFromCategoryDocument: (document, bendableInstance) => {
    console.log('objectFactory.buildCategoryWizardRawDataFromCategoryDocument document: ', document);
    console.log('objectFactory.buildCategoryWizardRawDataFromCategoryDocument bendableInstance: ', bendableInstance);
    const rawData = {
      bendable_instance: bendableInstance,
      title: _.get(document, `title`),
      slug: _.get(document, `slug`),
      category_type: _.get(document, `category_type`),
      language:  _.get(document, `language`),
      is_active: _.get(document, `instance_data.${bendableInstance}.is_active`),
    };
    console.log('objectFactory.buildCategoryWizardRawDataFromCategoryDocument rawData: ', rawData);
    return rawData;
  },


  /**
   * MongoDb Tenant to dbShellObject
   */
  buildDbObjectShellFromTenantDocument: (document, bendableInstance) => {
    let dbObjectShell = objectFactory.buildDbObjectShell({
      target_object_type: 'tenant',
      target_mid: document?._id || null,
      status: 'draft',
      controlling_instance: document?.controlling_instance,
      target_bendable_instance: bendableInstance,
    });
    console.log('objectFactory.buildDbObjectShellFromTenantDocument() dbObjectShell: ', dbObjectShell);
    dbObjectShell.raw_data = objectFactory.buildTenantWizardRawDataFromTenantDocument(document, bendableInstance);
    return dbObjectShell;
  },

  /**
   * MongoDb Tenant to Wizard raw_data
   */
  buildTenantWizardRawDataFromTenantDocument: (document, bendableInstance) => {
    console.log('objectFactory.buildTenantWizardRawDataFromTenantDocument document: ', document);
    console.log('objectFactory.buildTenantWizardRawDataFromTenantDocument bendableInstance: ', bendableInstance);
    const rawData = {
      tenant: _.get(document, `tenant`),
      display_name: _.get(document, `display_name`),
      providers: _.map(_.get(document, `providers`), (provider) => (provider?.slug)),
      // is_active: _.get(document, `instance_data.${bendableInstance}.is_active`),
      is_active: _.get(document, `is_active`),
    };
    console.log('objectFactory.buildTenantWizardRawDataFromTenantDocument rawData: ', rawData);
    return rawData;
  },




};

export default objectFactory;
