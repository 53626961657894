import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import _ from 'lodash-es';

import callApi from '../apiService';
import objectFactory from '../objectFactory';

const initialState = {
  tenants: [],
  wizardDbObjectShell: {
    controlling_instance: null,
    target_bendable_instance: null,
    target_object_type: 'tenant',
    status: 'draft',
    raw_data: {}
  },
  status: 'idle',
};

const fetchDbObjectShells = async (options) => {
  console.log('tenantsSlice.fetchDbObjectShells() options: ', options);
  options.limit = options.limit || 'all';

  try {
    const response = await callApi('getDbObjectShells', null, { params: options });
    console.log('tenantsSlice.fetchDbObjectShells() response: ', response);
    return response.data;   // axios attribute
  } catch (error) {
    console.error('tenantsSlice.fetchDbObjectShells() error: ', error.message);
  }
};

export const getDbObjectShells = createAsyncThunk(
  'tenants/getDbObjectShells',
  async (options) => {
    const response = await fetchDbObjectShells(options);
    return { tableName: options.tableName, rows: response.payload };
  }
);

const fetchTenants = async (options) => {
  console.log('tenantsSlice.fetchTenants() options: ', options);
  options.limit = options.limit || 'all';

  try {
    const response = await callApi('getTenants', { apiVersion: options?.apiVersion }, { params: options });
    console.log('tenantsSlice.fetchTenants() response: ', response);
    return response.data;   // axios attribute
  } catch (error) {
    console.error('tenantsSlice.fetchTenants() error: ', error.message);
  }
};

export const getTenants = createAsyncThunk(
  'tenants/getTenants',
  async (options) => {
    console.log('tenantsSlice.getTenants() options: ', options);
    const response = await fetchTenants(options);
    console.log('tenantsSlice.getTenants() response: ', response);
    return { tenants: response.payload.tenants };
  }
);

export const buildWizardDbObjectShell = createAsyncThunk(
  'tenants/buildWizardDbObjectShell',
  async (options) => {
    console.log('tenantsSlice buildWizardDbObjectShell() options: ', options);
    const response = await fetchTenants(options);
    console.log('tenantsSlice.buildWizardDbObjectShell() response: ', response);
    return { tenant: (response.payload?.tenants && response.payload.tenants[0]) || null };
  }
);



export const tenantsSlice = createSlice({
  name: 'tenants',
  initialState,
  reducers: {
    mergeTenantWizardRootData: (state, action) => {
      console.log('tenantsSlice mergeTenantWizardRootData before merge; state, action: ', current(state), action);
      // the .mergeWith() below can generate this error: Unhandled Rejection (Error): [Immer] Immer only supports setting array indices and the 'length' property
      // so let's avoid Immer with cloneDeep...
      // state.wizardDbObjectShell = _.mergeWith(state.wizardDbObjectShell, action.payload, (objValue, srcValue, key, object, source, stack) => {
      const stateCopy = _.cloneDeep(state.wizardDbObjectShell);
      const payloadCopy = _.cloneDeep(action.payload);
      state.wizardDbObjectShell = _.mergeWith(stateCopy, payloadCopy, (objValue, srcValue, key, object, source, stack) => {
        if (_.isArray(srcValue)) {
          return srcValue;              // allows empty arrays to overwrite existing arrays
        } else {
          return undefined;
        }
      });
      console.log('tenantsSlice mergeTenantWizardRootData after merge; state: ', current(state));
    },
    mergeTenantWizardRawData: (state, action) => {
      console.log('tenantsSlice mergeTenantWizardRawData before merge; state, action: ', current(state), action);
      // the .mergeWith() below can generate this error: Unhandled Rejection (Error): [Immer] Immer only supports setting array indices and the 'length' property
      // so let's avoid Immer with cloneDeep...
      // state.wizardDbObjectShell.raw_data = _.mergeWith(state.wizardDbObjectShell.raw_data, action.payload, (objValue, srcValue, key, object, source, stack) => {
      const stateCopy = _.cloneDeep(state.wizardDbObjectShell.raw_data);
      console.log('tenantsSlice mergeTenantWizardRootData stateCopy: ', stateCopy);
      const payloadCopy = _.cloneDeep(action.payload);
      console.log('tenantsSlice mergeTenantWizardRootData payloadCopy: ', payloadCopy);
      state.wizardDbObjectShell.raw_data = _.mergeWith(stateCopy, payloadCopy, (objValue, srcValue, key, object, source, stack) => {
        // console.log('tenantsSlice mergeTenantWizardRawData objValue: ', objValue);
        // console.log('tenantsSlice mergeTenantWizardRawData srcValue: ', srcValue);
        // console.log('tenantsSlice mergeTenantWizardRawData key: ', key);
        // console.log('tenantsSlice mergeTenantWizardRawData object: ', object);
        // console.log('tenantsSlice mergeTenantWizardRawData source: ', source);
        if (_.isArray(srcValue)) {
          return srcValue;              // allows empty arrays to overwrite existing arrays
        } else {
          return undefined;
        }
      });

      console.log('tenantsSlice mergeTenantWizardRawData after merge; state: ', current(state));
    },
    resetTenantWizardData: (state, action) => {
      console.log('tenantsSlice resetWizardData; state, action: ', current(state), action);
      state.wizardDbObjectShell = {
        controlling_instance: null,
        target_bendable_instance: null,
        target_object_type: 'tenant',
        status: 'draft',
        raw_data: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
  //   .addCase(getDbObjectShells.pending, (state) => {
  //     console.log('tenantsSlice getDbObjectShells.pending state: ', state);
  //     state.status = 'loading';
  //   })
  //   .addCase(getDbObjectShells.fulfilled, (state, action) => {
  //     state.status = 'idle';
  //     console.log('tenantsSlice getDbObjectShells.fulfilled action: ', action);
  //     state.currentDbObjectShells[action.payload.tableName] = action.payload.rows;
  //     // console.log('getDbObjectShells case; state: ', current(state));
  //   });
    .addCase(getTenants.pending, (state) => {
      console.log('tenantsSlice getTenants.pending state: ', state);
      state.status = 'loading';
    })
    .addCase(getTenants.fulfilled, (state, action) => {
      state.status = 'idle';
      console.log('tenantsSlice getTenants.fulfilled action: ', action);
      state.tenants = action?.payload?.tenants || [];
      // console.log('getTenants case; state: ', current(state));
    })
    .addCase(buildWizardDbObjectShell.pending, (state) => {
      console.log('tenantsSlice buildWizardDbObjectShell.pending state: ', state);
      state.status = 'loading';
    })
    .addCase(buildWizardDbObjectShell.fulfilled, (state, action) => {
      state.status = 'idle';
      console.log('tenantsSlice buildWizardDbObjectShell.fulfilled action: ', action);
      const tenant = action?.payload?.tenant;
      const targetBendableInstance = action?.meta?.arg?.target_bendable_instance || 'bendable-labs';
      const dbObjectShell = objectFactory.buildDbObjectShellFromTenantDocument(tenant, targetBendableInstance);
      console.log('tenantsSlice buildWizardDbObjectShell.fulfilled dbObjectShell: ', dbObjectShell);
      state.wizardDbObjectShell = dbObjectShell;  
      // console.log('buildWizardDbObjectShell case; state: ', current(state));
    });
},
});

export const {
  resetTenantWizardData,
  mergeTenantWizardRootData,
  mergeTenantWizardRawData,
} = tenantsSlice.actions;

// for useSelector; state is complete store, not slice
export const selectCurrentWizardDbObjectShell = (state) => state.tenants.wizardDbObjectShell;
export const selectTenants = (state) => state.tenants.tenants;

export default tenantsSlice.reducer;
